import React from "react";
import { useTranslation } from "react-i18next";
import Input from "@core/inputs/Input";
import "react-datepicker/dist/react-datepicker.css";
import { Plus } from "tabler-icons-react";
import Winch from "@components/Vessel/partials/Winch";
import ApiSelect from "@core/dropdowns/ApiSelect";
import GearActivationMethodService from "@services/GearActivationMethodService";

const VesselDeckMachinery = ({
  disabled = false,
  data,
  onChange,
  winches,
  onWinchesChange,
  required = true,
  isClearable = false
}) => {
  const { t } = useTranslation();

  const addWinch = () => {
    const winch = {
      winch_type_id: "",
      quantity: "",
      operated_by_id: "",
      remarks: "",
    };
    onWinchesChange([...winches, winch]);
  };

  const handleDelete = (index) => {
    const newWinch = winches.filter((winch) => winch !== winches[index]);
    onWinchesChange(newWinch);
  };

  const handleUpdate = (index, data) => {
    const newWinch = [...winches];
    newWinch[index] = data;
    onWinchesChange(newWinch);
  };

  const handleChange = (value, name) => {
    onChange({ ...data, [name]: value });
  };

  return (
    <>
      <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
        <div>
          <div className="text-sm font-bold mb-2 flex">
            {t("gearActivationType")}
            {required && <div className="text-red-600 ml-1">*</div>}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={"gearActivation"}
            disabled={disabled}
            service={GearActivationMethodService}
            onSelect={(val) => handleChange(val, "gear_activation_type_id")}
            selected={data?.gear_activation_type_id}
          />
        </div>
        <Input
          disabled={disabled}
          height="h-10"
          label={t("remarks")}
          placeholder={t("remarks")}
          onChange={(val) => handleChange(val, "remarks")}
          value={data?.remarks}
        />
      </div>
      <div>
        <div className="text-sm font-bold mb-7 mt-7">{t("winch")}</div>
        <div className="flex flex-col gap-5">
          {winches?.map((winch) => (
            <Winch
              isClearable={isClearable}
              required={required}
              disabled={disabled}
              data={winch}
              key={winches.indexOf(winch)}
              onDelete={handleDelete}
              onChange={handleUpdate}
              index={winches.indexOf(winch)}
            />
          ))}
        </div>
        {!disabled && (
          <button onClick={addWinch}>
            <div className="flex text-indigo-600 mt-2">
              <Plus />
              <div className="ml-2 font-semibold">{t("addWinch")}</div>
            </div>
          </button>
        )}
      </div>
    </>
  );
};

export default VesselDeckMachinery;
