import React, {useCallback, useMemo} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {Trash} from 'tabler-icons-react';
import {useTranslation} from "react-i18next";
import DataTable from "react-data-table-component";
import SearchOwner from "@components/Vessel/partials/SearchOwner";
import CreateOwner from "@components/Owner/CreateOwner";

const VesselOwnerships = ({disabled = false, data, onChange, onDelete, required = true, createOwner = true}) => {
  const {t} = useTranslation();

  const addOwner = (owner) => onChange(owner);

  const handleDelete = useCallback((id) => {
    const newOwner = data.filter((owner) => owner.owner_id !== id);
    onDelete(newOwner);
  }, [data, onDelete]);

  const headers = useMemo(
    () => [
      {
        id: "companyName",
        name: t('Company Name'),
        cell: ({owner}) => owner?.company_name,
      },
      {
        id: "companyType",
        name: t('Company Type'),
        cell: ({owner}) => owner?.company_type,
      },
      {
        id: "country",
        name: t('Country'),
        cell: ({owner}) => owner?.country,
      },
      {
        id: "registrationOffice",
        name: t('Registration Office'),
        cell: ({owner}) => owner?.registration_office,
      },
      !disabled && {
        id: "actions",
        name: t('actions'),
        width: '100px',
        show: true,
        cell: (row) => (
          <button
            className="text-red-600 cursor-pointer"
            onClick={() => handleDelete(row.owner_id)}
          >
            <Trash/>
          </button>
        ),
      },
    ],
    [disabled, handleDelete, t]
  );

  return (
    <div>
      <div className="text-sm font-bold mb-7 mt-7 flex items-center">
        {t('ownership')}
        {required && <div className="text-red-600 ml-1">*</div>}
      </div>
      {createOwner && (
        <div className="flex w-1/2 sm:w-1/4 md:w-1/6 my-3 mr-2">
          <CreateOwner label={t('addOwner')}/>
        </div>
      )}
      <DataTable
        columns={headers}
        data={data}
      />
      {!disabled && (
        <div className={'pt-3 mt-5 border-t'}>
          <SearchOwner onAdded={addOwner} owners={data}/>
        </div>
      )}
    </div>
  )
}

export default VesselOwnerships;
