import React from "react";
import Layout from "@hoc/layouts/Layout";
import GroupOfSpeciesIndex from "@components/GroupOfSpecies/GroupOfSpeciesIndex";

const GroupOfSpeciesPage = () => {
  return (
    <Layout>
      <GroupOfSpeciesIndex />
    </Layout>
  );
};

export default GroupOfSpeciesPage;
