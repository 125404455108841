import React from "react";
import Layout from "@hoc/layouts/Layout";
import MarineFisherySubsectorsIndex from "@components/MarineFisherySubsector/MarineFisherySubsectorsIndex";

const MarineFisherySubSectorsPage = () => {
  return (
    <Layout>
      <MarineFisherySubsectorsIndex />
    </Layout>
  );
};

export default MarineFisherySubSectorsPage;
