const al = {
  noData: "Ska të dhëna",
  areYouSure: "A jeni i sigurt?",
  areYouSureYouWantToDeleteThisEntity:
    "A jeni i sigurt që dëshironi të fshini këtë entitet?",
  areYouSureYouWantToActivateThisUser:
    "A jeni i sigurt që dëshironi të aktivizoni këtë përdorues?",
  areYouSureYouWantToDeactivateThisUser:
    "A jeni i sigurt që dëshironi të çaktivizoni këtë përdorues?",
  areYouSureYouWantToSealThisCensus:
    "A jeni i sigurt që dëshironi të vulosni këtë census?",
  areYouSureYouWantToReopenThisCensus:
    "A jeni i sigurt që dëshironi të rihapni këtë census?",
  areYouSureYouWantToRestore:
    "A jeni i sigurt që dëshironi të riktheni këtë census?",
  deletedSuccessfully: "U fshi me sukses",
  entity: "Entitet",
  fleetRegisterTitle: "Regjistri i flotës",
  logout: "Dil",
  activate: "Aktivizo",
  deactivate: "Çaktivizo",
  user: "Përdorues",
  users: "Përdoruesit",
  censuses: "Censuse",
  census: "Census",
  fleet: "Flota",
  role: "Rol",
  roles: "Rolet",
  pleaseWait: "Ju lutem prisni...",
  signInToYourAccount: "Hyni në llogarinë tuaj",
  signIn: "Hyni",
  email: "Email",
  password: "Fjalëkalim",
  name: "Emri",
  description: "Përshkrimi",
  createdAt: "Krijuar më",
  deleteAt: "U fshi më",
  updatedAt: "Përditësuar më",
  actions: "Veprime",
  rolesList: "Lista e Roleve",
  submit: "Ruaj",
  manage: "Menaxho",
  allPermissions: "Të gjitha të drejtat",
  selectedPermissions: "Të drejtat e zgjedhura",
  add: "Shto",
  remove: "Hiq",
  viewRole: "Shih të dhënat e rolit",
  assignARole: "Cakto një rol",
  roleCreatedSuccessfully: "Roli u krijua me sukses!",
  roleEditedSuccessfully: "Roli u modifikua me sukses!",
  edit: "Modifiko",
  view: "Shih",
  permissions: "Të drejtat",
  confirm: "Konfirmo",
  editUserPassword: "Modifikoni fjalëkalimin e përdoruesit",
  passwordUpdatedSuccessfully: "Fjalëkalimi u përditësua me sukses",
  changePassword: "Ndrysho Fjalëkalimin",
  newPassword: "Fjalëkalimi i ri",
  confirmPassword: "Konfirmo fjalëkalimin",
  deactivateUser: "Çaktivizo përdoruesin",
  activateUser: "Aktivizo përdoruesin",
  statusUpdate: "Statusi u përditësua me sukses",
  userCreatedSuccessfully: "Përdoruesi u krijua me sukses!",
  userEditedSuccessfully: "Përdoruesi u përditësua me sukses!",
  userName: "Emri i përdoruesit",
  userEmail: "Email i përdoruesit",
  status: "Statusi",
  userList: "Lista e përdoruesve",
  editUser: "Modifiko përdoruesin",
  editRole: "Modifiko rolin",
  overview: "Pasqyrë e përgjithshme",
  search: "Kerko...",
  close: "Mbyll",
  delete: "Fshi",
  censusList: "Lista e Censuseve",
  censusName: "Emri i censusit",
  censusCreatedSuccessfully: "Censusi u krijua me sukses!",
  censusEditedSuccessfully: "Censusi u përditësua me sukses!",
  seal: "Vulos",
  sealedAt: "Vulosur më",
  reopen: "Rihap",
  closingReason: "Arsyeja e vulosjes",
  clone: "Kopjo",
  censusClonedSuccessfully: "Censusi u kopjua me sukses!",
  closedCensuses: "Censuse të mbyllura",
  closedCensusesList: "Lista e censuseve të mbyllura",
  restoreCensus: "Riktheni Censusin",
  censusSealedSuccessfully: "Censusi u vulos me sukses",
  censusReopenedSuccessfully: "Censusi u rihap me sukses!",
  censusRestoredSuccessfully: "Censusi u rikthye me sukses!",
  cloneCensus: "Kopjo Censusin",
  reopenCensus: "Rihap Censusin",
  sealCensus: "Vulos Censusin",
  editCensus: "Modifiko Censusin",
  configurations: "Konfigurime",
  sealingUser: "Përdorues i cili vulosi",
  creatingUser: "Përdorues i cili krijoi",
  nameEng: "Emri ENG",
  fishingZone: "Zona e peshkimit",
  fishingZones: "Zonat e peshkimit",
  addFishingZone: "Shto zonë peshkimi",
  editFishingZone: "Modifiko zonēn e peshkimit",
  fishingZonesList: "Lista e zonave të peshkimit",
  fishingZoneCreatedSuccessfully: "Zona e peshkimit u krijua me sukses",
  fishingZoneEditedSuccessfully: "Zona e peshkimit u modifikua me sukses",
  portTypes: "Tipet e porteve",
  portTypesList: "Lista e tipeve të porteve",
  addPortType: "Shto tip porti",
  portTypeCreatedSuccessfully: "Tipi i portit u krijua me sukses",
  portTypeEditedSuccessfully: "Tipi i portit u modifikua me sukses",
  editPortType: "Modifiko tipin e portit",
  issuingOfficesList: "Lista e zyrave të lëshimit",
  issuingOffices: "Zyrat e lëshimit",
  addIssuingOffice: "Shto zyrë lëshimi",
  issuingOfficeCreatedSuccessfully: "Zyra e lëshimit u shtua me sukses",
  issuingOfficeEditedSuccessfully: "Zyra e lëshimit u modifikua me sukses",
  editIssuingOffice: "Modifio zyrën e lëshimit",
  authorizationTypesList: "Lista e tipeve të Autorizimeve",
  addAuthorizationType: "Shto tip autorizimi",
  authorizationTypes: "Tipet e autorizimeve",
  authorizationType: "Tip autorizimi",
  authorizationTypeCreatedSuccessfully: "Tipi i autorizimit u shtua me sukses",
  authorizationTypeEditedSuccessfully:
    "Tipi i autorizimit u modifikua me sukses",
  editAuthorizationType: "Modifiko tipin e autorizimit",
  stratas: "Shtresat",
  stratasList: "Lista e Shtresave",
  addStrata: "Shto shtresë",
  strataCreatedSuccessfully: "Shtresa u krijua me sukses",
  editStrata: "Modifiko shtresën",
  strataEditedSuccessfully: "Shtresa u modifikua me sukses",
  strata: "Shtresa",
  chooseStratum: "Zgjidhni shtresën",
  subStratas: "Nënshtresat",
  subStratasList: "Lista e Nënshtresave",
  addSubStrata: "Shto nënshtresë",
  subStrataCreatedSuccessfully: "Nënshtresa u krijua me sukses",
  editSubStrata: "Modifiko nënshtresën",
  subStrataEditedSuccessfully: "Nënshtresa u modifikua me sukses",
  strataName: "Emri i shtresës",
  strataNameEng: "Emri i shtresës ENG",
  strataDescription: "Përshkrimi i shtresës",
  minorStratas: "Shtresa të vogla",
  minorStratasList: "Lista e shtresave të vogla",
  addMinorStratum: "Shto shtresë të vogël",
  minorStrataCreatedSuccessfully: "Shtresa e vogël u krijua me sukses",
  editMinorStratum: "Modifiko shtresën e vogel",
  minorStrataEditedSuccessfully: "Shtresa e vogël u modifikua me sukses",
  subStrataName: "Emri i nënshtreses",
  subStrataNameEng: "Emri i nënshtreses ENG",
  subStrataDescription: "Përshkrimi i nënshtreses",
  chooseSubStratum: "Zgjidhni nënshtresen",
  chooseCensus: "Zgjidhni Censusin",
  inactivityReasons: "Arsyet e mosveprimit",
  inactivityReasonsList: "Lista e arsyeve të mosveprimit",
  addInactivityReason: "Shto arsye mosveprimi",
  inactivityReasonCreatedSuccessfully:
    "Arsyeja e mosveprimit u shtua me sukses",
  editInactivityReason: "Modifiko arsyen e mosveprimit",
  inactivityReasonEditedSuccessfully:
    "Arsyeja e mosveprimit u modifikua me sukses",
  captainciesList: "Lista e kapitenerive",
  captaincies: "Kapiteneritë",
  addCaptaincy: "Shto kapiteneri",
  captaincyCreatedSuccessfully: "Kapiteneria u krijua me sukses",
  editCaptaincy: "Modifiko kapitenerinë",
  captaincyEditedSuccessfully: "Kapiteneria u modifikua me sukses",
  vesselsList: "Lista e anijeve",
  addVessel: "Shto anije",
  vesselCreatedSuccessfully: "Anija u krijua me sukses",
  editVessel: "Modifiko anijen",
  vesselEditedSuccessfully: "Anija u modifikua me sukses",
  characteristics: "Karakteristikat",
  uniqueRegistrationNumber: "Numri unik i regjistrimit",
  vesselName: "Emri i anijes",
  registrationNumber: "Numri i regjistrimit",
  fisheryDepartmentRegistrationNumber:
    "Numri i regjistrimit të departamentit të peshkimit",
  vesselType: "Lloji i anijes",
  chooseVesselType: "Zgjidhni llojin e anijes",
  homePort: "Porti bazë",
  choosePort: "Zgjidhni portin",
  chooseOperationalStatus: "Zgjidhni statusin operacional",
  chooseNonOperationalReason: "Zgjidhni arsyen e mosoperimit",
  chooseRegistrationOffice: "Zgjidhni zyrën e regjistrimit",
  originalCharacteristics: "Karakteristikat origjinale",
  operationalInformation: "Informacioni operacional",
  operationalStatus: "Statusi Operacional",
  structuralCharacteristics: "Karakteristikat strukturore",
  length: "Gjatësia",
  width: "Gjerësia",
  depth: "Thellësia",
  grt: "GRT",
  gts: "GTS",
  constructionCountry: "Vëndi ndërtimit",
  chooseConstructionCountry: "Zgjidhni vëndin e ndërtimit",
  constructionYear: "Viti i ndërtimit",
  hullMaterial: "Materiali i trupit të skafit",
  chooseHullMaterial: "Zgjidhni materialin e trupit të anijes",
  decked: "Me kate",
  authorization: "Autorizimi",
  detarRegistrationNumber: "Numri i regjistrimit DETAR",
  chooseAuthorizationType: "Zgjidhni tipin e autorizimit",
  issuingOffice: "Zyra e lëshimit",
  chooseIssuingOffice: "Zgjidhni zyrën e lëshimit",
  issueDate: "Data e lëshimit",
  expiryDate: "Data e skadimit",
  fisheryEntryDate: "Data e hyrjes në peshkim",
  documentId: "ID Dokumentit",
  licenseIndicator: "Tregues i liçenses",
  gears: "Veglat e peshkimit",
  addGear: "Shto vegël peshkimi",
  gearsList: "Lista e veglave të peshkimit",
  gearCreatedSuccessfully: "Vegla e peshkimit u krijua me sukses",
  editGear: "Modifiko veglën e peshkimit",
  gearEditedSuccessfully: "Vegla e peshkimit u modifikua me sukses",
  matchingCodeGear: "Kodi qe përputhet",
  rank: "Rank",
  gearClass: "Klasa e veglës peshkimit",
  chooseGearClass: "Zgjidhni klasën e veglës peshkimit",
  gearType: "Tipi i veglave të peshkimit",
  chooseGearType: "Zgjidhni tipin e veglës të peshkimit",
  targetGroupOfSpecies: "Grupi i synuar i specieve",
  chooseTargetGroupOfSpecies: "Zgjidhni grupin e synuar të specieve",
  gt: "GT",
  subArea: "Nënzona",
  subAreas: "Nënzonat",
  subAreasList: "Lista e nënzonave",
  addSubArea: "Shto nënzonë",
  subAreaCreatedSuccessfully: "Nënzona u krijua me sukses",
  editSubArea: "Modifiko nënzonën",
  subAreaEditedSuccessfully: "Nënzona u modifikua me sukses",
  power: "Fuqia",
  externalMarking: "Shënim i jashtëm",
  flag: "Flamuri",
  chooseFlag: "Zgjidhni flamurin",
  chooseOriginalFlag: "Zgjidhni flamurin origjinal",
  registrationOffice: "Zyra e regjistrimit",
  registrationDate: "Data e regjistrimit",
  originalName: "Emri origjinal",
  originalRegistrationNumber: "Numri origjinal i regjistrimit",
  originalDeletionNumber: "Numri origjinal i fshirjes",
  originalFlag: "Flamuri origjinal",
  nonOperationalReason: "Arsyeja e mosoperimit",
  remarks: "Shënime",
  engines: "Motorët",
  addEngine: "Shtoni motor",
  model: "Modeli",
  construction: "Ndërtimi",
  chooseConstruction: "Zgjidhni ndërtimin",
  location: "Vendndodhja",
  chooseLocation: "Zgjidhni vendndodhjen",
  energySource: "Burimi energjisë",
  chooseEnergySource: "Zgjidhni burimin e energjisë",
  electronics: "Elektronika",
  equipment: "Pajisje",
  equipments: "Pajisjet",
  equipmentList: "Lista e pajisjeve",
  addEquipment: "Shtoni pajisje",
  equipmentCreatedSuccessfully: "Pajisja u krijua me sukses",
  editEquipment: "Modifiko pajisjen",
  equipmentEditedSuccessfully: "Pajisja u modifikua me sukses",
  equipmentCategory: "Kategoria e pajisjes",
  equipmentCategoryName: "Emri i kategorisë së pajisjes",
  equipmentCategoryNameEng: "Emri ENG i kategorisë së pajisjes",
  equipmentCategoryDescription: "Përshkrimi i kategorisë së pajisjes",
  chooseEquipmentCategory: "Zgjidhni kategorinë e pajisjes",
  equipmentType: "Lloji i pajisjes",
  chooseEquipmentType: "Zgjidhni llojin e pajises",
  purchaseYear: "Viti i blerjes",
  quantity: "Sasia",
  capacity: "Kapaciteti",
  capacityUnit: "Njësia matëse",
  capacityUnits: "Njësite matëse",
  capacityUnitsList: "Lista e njësive matëse",
  addCapacityUnit: "Shto njësi matëse",
  capacityUnitCreatedSuccessfully: "Njësia matëse u shtua me sukses",
  editCapacityUnit: "Modifiko njësinë matëse",
  capacityUnitEditedSuccessfully: "Njësia matëse u modifikua me sukses",
  chooseMeasurementUnit: "Zgjidhni njësinë matëse",
  chooseCapacityUnit: "Zgjidhni njësinë e kapacitetit",
  deckMachinery: "Makineritë në bord",
  gearActivationType: "Tipi i aktivizimit të veglave të peshkimit",
  chooseGearActivationMethod: "Zgjidhni metoden e aktivizimit të pajisjes",
  winch: "Vinç",
  addWinch: "Shtoni vinç",
  winchType: "Tipi i vinçit",
  chooseWinchType: "Zgjidhni tipin e vinçit",
  gearActivationMethodsList:
    "Lista e metodave të aktivizimit të veglave të peshkimit",
  gearActivationMethods: "Metodat e aktivizimit të veglave të peshkimit",
  gearActivationMethod: "Metoda e aktivizimit të veglave të peshkimit",
  addGearActivationMethod: "Shto metodë aktivizimi të veglave të peshkimit",
  gearActivationMethodCreatedSuccessfully:
    "Metoda e aktivizimit të veglave të peshkimit u krijua me sukses",
  editGearActivationMethod:
    "Modifiko metodën e aktivizimit të veglave të peshkimit",
  gearActivationMethodEditedSuccessfully:
    "Metoda e aktivizimit të veglave të peshkimit u modifikua me sukses",
  winches: "Vinçat",
  winchTypes: "Llojet e vinçave",
  winchTypesList: "Lista e llojeve të vinçave",
  addWinchType: "Shto tip vinçi",
  winchTypeCreatedSuccessfully: "Tipi i vinçit u krijua me sukses",
  editWinchType: "Modifiko tipin e vinçit",
  winchTypeEditedSuccessfully: "Tipi i vinçit u modifikua me sukses",
  operatedBy: "Operuar nga",
  chooseWinchOperationType: "Zgjidhni tipin e operimit të vinçit",
  winchOperationsList: "Lista e operimeve te vinçit",
  winchOperations: "Operimet e vinçit",
  winchOperationTypes: "Llojet e operimeve te vinçit",
  addWinchOperation: "Shto operim vinçi",
  winchOperationCreatedSuccessfully: "Operimi i vinçit u krijua me sukses",
  editWinchOperation: "Modifiko operimin e vinçit",
  winchOperationEditedSuccessfully: "Operimi i vinçit u modifikua me sukses",
  ownership: "Pronësia",
  owners: "Pronarët",
  chooseOwner: "Zgjidhni pronar",
  addOwner: "Shtoni pronar",
  crew: "Ekuipazhi",
  minimumNumber: "Numri minimal",
  maximumNumber: "Numri maksimal",
  seasonalOrPartTimeNumber: "Numri sezonal ose me kohë të pjesshme",
  fullTimeNumber: "Numri me kohë te plotë",
  femaleNumber: "Numri i femrave",
  ports: "Portet",
  addPort: "Shtoni port",
  portCreatedSuccessfully: "Porti u krijua me sukses",
  editPort: "Modifiko portin",
  portEditedSuccessfully: "Porti u modifikua me sukses",
  port: "Porti",
  beginMonth: "Muaji i fillimit",
  endMonth: "Muaji i mbarimit",
  chooseMonth: "Zgjidhni muajin",
  operations: "Operimi",
  tripsPerMonth: "Udhëtime në muaj",
  addOperation: "Shtoni operim",
  chooseFishingZone: "Zgjidhni zonën e peshkimit",
  geoSubArea: "Nënzona gjeografike",
  chooseGeoSubArea: "Zgjidhni nënzonën gjeografike",
  pollutionPrevention: "Parandalimi i ndotjes",
  oilResidueDisposal: "Asgjësimi i mbetjeve të vajit",
  otherResidueDisposal: "Asgjësimi i mbetjeve të tjera",
  chooseDisposalType: "Zgjidhni tipin e asgjësimit",
  chooseMaterial: "Zgjidhni materialin",
  chooseCountry: "Zgjidhni vëndin",
  chooseOffice: "Zgjidhni zyrën",
  chooseReason: "Zgjidhni arsyen",
  chooseStatus: "Zgjidhni statusin",
  chooseType: "Zgjidhni tipin",
  chooseClass: "Zgjidhni klasën",
  chooseCategory: "Zgjidhni kategorinë",
  chooseMethod: "Zgjidhni metodën",
  chooseZone: "Zgjidhni zonën",
  chooseTargetGroup: "Zgjidhni grupin e synuar",
  chooseSource: "Zgjidhni burimin",
  class: "Klasa",
  type: "Tipi",
  goBack: "Shko mbrapa",
  back: "Mbrapa",
  next: "Tjetra",
  operationalStatuses: "Statuset Operacional",
  operationalStatusesList: "Lista statuseve operacional",
  addOperationalStatus: "Shto status operacional",
  operationalStatusCreatedSuccessfully:
    "Statusi operacional u krijua me sukses",
  editOperationalStatus: "Modifiko statusin operacional",
  operationalStatusEditedSuccessfully:
    "Statusi operacional u modifikua me sukses",
  seasonalPartTimeNumber: "Seasonal / PartTimeNumber",
  emoNumber: "Numri IMO",
  VMSIndicator: "Tregues VMS",
  VMSId: "Id e VMS",
  ERSIndicator: "Tregues ERS",
  ERSId: "Id e ERS",
  totalVessels: "Anije në total",
  crewMembers: "Pjesëtar ekuipazhi",
  totalPorts: "Porte në total",
  vesselTypes: "Tipe anijesh",
  totalEnginePower: "Fuqia motorike totale",
  grossTonageTotal: "Tonazhi total bruto",
  countries: "Shtetet",
  countriesList: "Lista e shteteve",
  addCountry: "Shto shtet",
  countryCreatedSuccessfully: "Shteti u krijua me sukses",
  editCountry: "Modifiko shtetin",
  countryEditedSuccessfully: "Shteti u modifikua me sukses",
  unitedNation: "Kombet e bashkuara",
  unitedNationDp: "Kombet  e bashkuara projekt zhvillimi",
  iso2: "Iso2",
  iso3: "Iso3",
  europeanUnion: "Bashkimi Europian",
  gfcm: "Komisioni i Përgjithshëm i Peshkimit për Mesdheun",
  nameFrn: "Emri Frengjisht",
  nameEsp: "Emri Spanjisht",
  medFisis: "MedFisis",
  exportExcel: "Shkarko Excel",
  exportOld: "Export Old",
  exportPDF: "Shkarko PDF",
  statisticalDivision: "Ndarje statistikore",
  statisticalDivisions: "Ndarjet statistikore",
  statisticalDivisionsList: "Lista e ndarjeve statistikore",
  addStatisticalDivision: "Shto ndarje statistikore",
  StatisticalDivisionCreatedSuccessfully:
    "Ndarja statistikore u krijua me sukses",
  editStatisticalDivision: "Modifiko ndarjen statistikore",
  statisticalDivisionEditedSuccessfully:
    "Ndarja statistikore u modifikua me sukses",
  subAreaName: "Emri i nënzonës",
  subAreaNameEng: "Emri i nënzonës ENG",
  subAreaDescription: "Përshkrimi i nënzonës",
  engineLocationsList: "Lista e vendodhjeve të motorit",
  numericalStatistics: "Statistika Numerike",
  portsList: "Lista e porteve",
  latitude: "Gjerësia gjeografike",
  longitude: "Gjatësia gjeografike",
  portType: "Tipi i portit",
  portCode: "Kodi i portit",
  portCodeEU: "Kodi i portit EU",
  minorStrataName: "Emri i shtresës së vogël",
  minorStrataNameEng: "Emri i shtresës së vogël ENG",
  minorStrataDescription: "Përshkrimi i shtresës se vogel",
  geoSubAreaName: "Emri i nënzonës gjeografike",
  geoSubAreaNameEng: "Emri i nënzonës gjeografike ENG",
  geoSubAreaDescription: "Pershkrimi i nënzonës gjeografike",
  portTypeName: "Emri i tipit të portit",
  portTypeNameEng: "Emri i tipit të portit ENG",
  portTypeDescription: "Pershkrimi i tipit të portit",
  gear: "Vegël peshkimi",
  gearClasses: "Klasat e veglave të peshkimit",
  gearClassesList: "Lista e klasave të veglave të peshkimit",
  addGearClass: "Shto klasë veglash të peshkimit",
  gearClassAddedSuccessfully: "Klasa e veglave të peshkimit u shtua me sukses",
  editGearClass: "Modifiko klasën e veglave të peshkimit",
  gearClassEditedSuccessfully:
    "Klasa e veglave të peshkimit u modifikua me sukses",
  hullMaterials: "Materialet e trupit të skafit",
  hullMaterialsList: "Lista e materialeve të trupit të skafit",
  addHullMaterial: "Shto material trupi të skafit",
  hullMaterialAdded: "Materiali i trupit të skafit u krijua me sukses",
  editHullMaterial: "Modifiko materialin e trupit të skafit",
  hullMaterialEditedSuccessfully:
    "Materiali i trupit te skafit u modifikua me sukses",
  hullMaterialEUName: "Emri i materialit të trupit të skafit EU",
  hullMaterialEUNameEng: "Emri ENG i materialit të trupit të skafit EU",
  hullMaterialEUDescription: "Përshkrimi i materialit të trupit të skafit EU",
  hullMaterialEU: "Materiali i trupit të skafit EU",
  hullMaterialsEU: "Materialet e trupit të skafit EU",
  hullMaterialsEUList: "Lista e materialeve të trupit të skafit EU",
  addHullMaterialEU: "Shto material trupi të skafit EU",
  hullMaterialEUAdded: "Materiali i trupit të skafit EU u krijua me sukses",
  editHullMaterialEU: "Modifiko materialin e trupit të skafit EU",
  hullMaterialEUEditedSuccessfully:
    "Materiali i trupit të skafit EU u modifikua me sukses",
  energySources: "Burimet e energjisë",
  energySourcesList: "Lista e burimeve të energjisë",
  addEnergySource: "Shto burim energjie",
  energySourceAddedSuccessfully: "Burimi i energjis u krijua me sukses",
  editEnergySource: "Modifiko burimin e energjisë",
  energySourceEditedSuccessfully: "Burimi i energjisë u modifikua me sukses",
  groupOfSpeciesGFCM: "Grup speciesh GFCM",
  groupOfSpeciesGFCMList: "Lista e grupit të specieve GFCM",
  equipmentCategories: "Kategoritë e pajisjeve",
  equipmentCategoriesList: "Lista e kategorive të pajisjeve",
  addEquipmentCategory: "Shto kategori pajisjeje",
  equipmentCategoryAddedSuccessfully: "Kategoria e pajisjeve u shtua me sukses",
  editEquipmentCategory: "Modifiko kategorinë e pajisjeve",
  equipmentCategoryEditedSuccessfully:
    "Kategoria e pajisjeve u modifikua me sukses",
  groupOfSpecies: "Grup speciesh",
  groupOfSpeciesList: "Lista e grupit të specieve",
  addGroupOfSpecies: "Shto grup speciesh",
  editGroupOfSpecies: "Modifiko grupin e specieve",
  groupOfSpeciesEditedSuccessfully: "Grupi i specieve u modifikua me sukses",
  groupOfSpeciesCreatedSuccessfully: "Grupi i specieve u shtua me sukses",
  groupOfSpeciesGFCMName: "Emri i grupit të specieve GFCM",
  groupOfSpeciesGFCMNameEng: "Emri ENG i grupit të specieve GFCM",
  groupOfSpeciesGFCMDescription: "Përshkrimi i grupit të specieve GFCM",
  addGroupOfSpeciesGFCM: "Shto grup speciesh",
  editGroupOfSpeciesGFCM: "Modifiko grupin e specieve",
  groupOfSpeciesGFCMEditedSuccessfully: "Grupi i specieve u modifikua me sukses",
  groupOfSpeciesGFCMECreatedSuccessfully: "Grupi i specieve u shtua me sukses",
  engineLocations: "Vendndodhjet e motorit",
  addEngineLocation: "Shto vendndodhje motori",
  engineLocationAddedSuccessfully: "Vendndodhja e motorit u shtua me sukses",
  editEngineLocation: "Modifiko vendndodhjen e motorit",
  engineLocationEditedSuccessfully:
    "Vendndodhja e motorit u modifikua me sukses",
  vesselTypesFFFAO: "Lloji i anijes FFFAO",
  vesselTypeFFFAOList: "Lista e Llojeve të anijeve FFFAO",
  addVesselTypeFFFAO: "Shto lloj anije FFFAO",
  vesselTypeFFFAOAddedSuccessfully: "Lloji i anijes FFFAO u shtua me sukses",
  editVesselTypeFFFAO: "Modifiko llojin e anijes FFFAO",
  vesselTypeFFFAOEditedSuccessfully:
    "Lloji i anijes FFFAO u modifikua me sukses",
  vesselTypesList: "Lista e Llojeve të anijeve",
  addVesselType: "Shto lloj anije",
  vesselTypeAddedSuccessfully: "Lloji i anijes u shtua me sukses",
  editVesselType: "Modifiko llojin e anijes",
  vesselTypeEditedSuccessfully: "Lloji i anijes u modifikua me sukses",
  vesselTypeFFFAOName: "Emri i llojit të anijes FFFAO",
  stdAbbreviation: "Shkurtim STD",
  disposalTypes: "Llojet e asgjësimit",
  disposalTypesList: "Lista e llojeve të asgjësimit",
  addDisposalType: "Shto lloj asgjësimi",
  disposalTypeCreatedSuccessfully: "Lloji i asgjësimit u krijua me sukses",
  editDisposalType: "Modifiko llojin e asgjësimit",
  disposalTypeEditedSuccessfully: "Lloji i asgjësimit u modifikua me sukses",
  geoSubAreas: "Nënzonat gjeografike",
  geoSubAreasList: "Lista e nënzonave gjeografike",
  addGeoSubArea: "Shto nënzonë gjeografike",
  geoSubAreaCreatedSuccessfully: "Nënzona gjeografike u krijua me sukses",
  editGeoSubArea: "Modifiko nënzonën gjeografike",
  geoSubAreaEditedSuccessfully: "Nënzona gjeografike u modifikua me sukses",
  statisticalDivisionName: "Emri i ndarjes statistikore",
  statisticalDivisionNameEng: "Emri Ang i ndarjes statistikore",
  statisticalDivisionDescription: "Përshkrimi i ndarjes statistikore",
  companyTypes: "Tipet e kompanive",
  companyType: "Tipi i kompanisë",
  companyTypesList: "Lista e tipeve të kompanive",
  addCompanyType: "Shto tip kompanie",
  companyTypeCreatedSuccessfully: "Tipi i kompanis u krijua me sukses",
  editCompanyType: "Modifiko tipin e kompanisë",
  companyTypeEditedSuccessfully: "Tipi i kompanisë u modifikua me sukses",
  ownersList: "Lista e pronarëve",
  ownerCreatedSuccessfully: "Pronari u krijua me sukses",
  editOwner: "Modifiko pronarin",
  ownerEditedSuccessfully: "Pronari u modifikua me sukses",
  companyName: "Emri i kompanisë",
  establishedYear: "Viti i themelimit",
  address: "Adresa",
  postcode: "Kodi postar",
  town: "Qyteti",
  region: "Rajoni",
  country: "Shteti",
  gearStd: "Veglat e peshkimit STD",
  gearEU: "Vegël peshkimi EU",
  gearsEU: "Veglat e peshkimit EU",
  gearsEUList: "Lista e veglave të peshkimit EU",
  addGearEU: "Shto vegël peshkimi EU",
  gearEUCreatedSuccessfully: "Vegla e peshkimit EU u krijua me sukses",
  editGearEU: "Modifiko veglën e peshkimit EU",
  gearEUEditedSuccessfully: "Vegla e peshkimit EU u modifikua me sukses",
  fishingTechniqueEU: "Teknika e peshkimit EU",
  fishingTechniquesEU: "Teknikat e peshkimit EU",
  fishingTechniquesEUList: "Lista e teknikave të peshkimit EU",
  addFishingTechniqueEU: "Shto teknikë të peshkimit EU",
  fishingTechniqueEUCreatedSuccessfully:
    "Teknika e peshkimit EU u krijua me sukses",
  editFishingTechniqueEU: "Modifiko teknikën e peshkimit EU",
  fishingTechniqueEUEditedSuccessfully:
    "Teknika e peshkimit EU u modifikua me sukses",
  marineFisherySubSector: "Nën kategoria e peshkimit detar",
  marineFisherySubSectors: "Nën kategoritë e peshkimit detar",
  addMarineFisherySubSector: "Shto nën kategori të peshkimit detar",
  marineFisherySubSectorCreatedSuccessfully:
    "Nën kategoria e peshkimit detar u krijua me sukses",
  editMarineFisherySubSector: "Modifiko nën kategorinë e peshkimit detar",
  marineFisherySubSectorEditedSuccessfully:
    "Nën kategoria e peshkimit detar u modifikua me sukses",
  marineFisherySubSectorsList: "Lista e nën kategorive të peshkimit detar",
  MMSIIndicator: "Tregues MMSI",
  MMSIId: "Id e MMSI",
  searchOwner: "Kërko pronar",
  fleetSegmentationsList: "Segmentimi i Flotës",
  addFleetSegmentations: "Shto segmentim të flotës",
  fleetSegmentationsCreatedSuccessfully:
    "Segmentimi i flotës u krijua me sukses",
  editFleetSegmentations: "Modifiko segmentimin e flotës",
  fleetSegmentationsEditedSuccessfully:
    "Segmentimi i flotës u modifikua me sukses",
  fleetSegmentations: "Segmentimi i flotës",
  simpleSearch: "Kërkim i thjeshtë",
  createUserName: "Emri i përdoruesit të krijiit",
  casInclusions: "Përfshirjet e Cas",
  lengthMin: "Gjatësi Minimale",
  lengthMax: "Gjatësi Maksimale",
  gtMin: "Gt Minimale",
  gtMax: "Gt Maksimale",
  powerMin: "Fuqi Minimale",
  powerMax: "Fuqi Maksimale",
  constructionYearMin: "Viti i ndërtimit minimal",
  constructionYearMax: "Viti i ndërtimit maksimal",
  searchGear: "Kërko vegla",
  resetFilters: "Pastroni filtrat",
  advancedSearch: "Kërkim i avancuar",
  distributionOfEquipment: "Shpërndarja e pajisjeve",
  distributionByPortVesselType: "Shpërndarja sipas llojit të anijes dhe portit",
  distributionByVesselTypePort: "Shpërndarja sipas portit dhe llojit të anijes",
  distributionOfVesselsByVesselType: "Shpërndarja sipas llojit të anijes",
  distributionOfVesselsByMinorStratum: "Shpërndarja e anijeve sipas nenshtresave",
  distributionOfVesselsByTypeLength: "Shpërndarja e anijeve sipas gjatësisë",
  distributionOfVesselByPowerVesselType: "Shpërndarja e anijeve sipas fuqisë",
  basicNumericalStatistics: "Statistikat baze",
  distributionOfVesselsByPort: "Shpërndarja e anijeve sipas porteve",
  allPdfReport: 'Të gjitha PDF',
  exportEuropeanHake: 'Export Merluci',
  exportSmallPelagic: 'Export Pelagjik të vegjël',
  exportVesselRecording: 'Export mbi regjistrimin e anijeve',
  exportOver15m: 'Export Anije mbi 15m',
  exportUnder15m: 'Export Anije nën 15m',
  exportSearch: 'Export Filtrim'
};

export default al;
