import React from "react";
import Layout from "@hoc/layouts/Layout";
import GearsIndex from "@components/Gear/GearsIndex";

const GearsPage = () => {
  return (
    <Layout>
      <GearsIndex />
    </Layout>
  );
};

export default GearsPage;
