import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import ShowEngineLocation from "@components/EngineLocation/ShowEngineLocation";
import EditEngineLocation from "@components/EngineLocation/EditEngineLocation";
import EngineLocationService from "@services/EngineLocationService";

const EngineLocationActions = ({ engineLocation, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowEngineLocation engineLocation={engineLocation} />
      </div>
      <div className={"mr-2"}>
        <EditEngineLocation
          engineLocation={engineLocation}
          onEdited={onEdited}
        />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={EngineLocationService}
          id={engineLocation.id}
          onDeleted={onDeleted}
          permission={"delete_engine_location"}
        />
      </div>
    </div>
  );
};

export default EngineLocationActions;
