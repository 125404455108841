import API from "@utils/plugins/API";

const SubAreaService = {
  all: (params) => {
    return API.get("sub-areas", { params: params });
  },
  create: (name, name_eng, description) => {
    return API.post("sub-areas", {
      name,
      name_eng,
      description,
    });
  },
  update: (id, name, name_eng, description) => {
    return API.put(`sub-areas/${id}`, {
      name,
      name_eng,
      description,
    });
  },
  find: (id) => {
    return API.get(`sub-areas/${id}`);
  },
  destroy: (id) => {
    return API.delete(`sub-areas/${id}`);
  },
};

export default SubAreaService;
