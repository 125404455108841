import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import ShowWinchType from "@components/WinchType/ShowWinchType";
import EditWinchType from "@components/WinchType/EditWinchType";
import WinchTypeService from "@services/WinchTypeService";

const WinchTypeActions = ({ winchType, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowWinchType winchType={winchType} />
      </div>
      <div className={"mr-2"}>
        <EditWinchType winchType={winchType} onEdited={onEdited} />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={WinchTypeService}
          id={winchType.id}
          onDeleted={onDeleted}
          permission={"delete_winch_type"}
        />
      </div>
    </div>
  );
};

export default WinchTypeActions;
