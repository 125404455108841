import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import FishingTechniqueEUService from "@services/FishingTechniqueEUService";
import ShowFishingTechniqueEU from "@components/FishingTechniqueEU/ShowFishingTechniqueEU";
import EditFishingTechniqueEU from "@components/FishingTechniqueEU/EditFishingTechniqueEU";

const FishingTechniqueEUActions = ({
  fishingTechniqueEU,
  onEdited,
  onDeleted,
}) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowFishingTechniqueEU fishingTechniqueEU={fishingTechniqueEU} />
      </div>
      <div className={"mr-2"}>
        <EditFishingTechniqueEU
          fishingTechniqueEU={fishingTechniqueEU}
          onEdited={onEdited}
        />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={FishingTechniqueEUService}
          id={fishingTechniqueEU.id}
          onDeleted={onDeleted}
          permission={"delete_fishing_technique_eu"}
        />
      </div>
    </div>
  );
};

export default FishingTechniqueEUActions;
