import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Plus } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
import Equipment from "@components/Vessel/partials/Equipment";

const VesselEquipments = ({ disabled = false, data, onChange, isClearable = false }) => {
  const { t } = useTranslation();

  const addEquipment = () => {
    const equipment = {
      equipment_category_id: "",
      equipment_type_id: "",
      purchaseYear: "",
      quantity: "",
      measurement: "",
      measurement_unit_id: "",
      remarks: "",
    };
    onChange([...data, equipment]);
  };

  const handleDelete = (index) => {
    const newEquipment = data.filter((equipment) => equipment !== data[index]);
    onChange(newEquipment);
  };

  const updateEquipment = (index, equipment) => {
    const newEquipment = [...data];
    newEquipment[index] = equipment;
    onChange(newEquipment);
  };

  return (
    <div>
      <div className="text-sm font-bold mb-7 mt-7">{t("equipments")}</div>
      <div className="flex flex-col gap-5">
        {data?.map((equipment) => (
          <Equipment
            isClearable={isClearable}
            disabled={disabled}
            data={equipment}
            key={data.indexOf(equipment)}
            onDelete={handleDelete}
            onChange={updateEquipment}
            index={data.indexOf(equipment)}
          />
        ))}
      </div>
      {!disabled && (
        <button onClick={addEquipment}>
          <div className="flex text-indigo-600 mt-2">
            <Plus />
            <div className="ml-2 font-semibold">{t("addEquipment")}</div>
          </div>
        </button>
      )}
    </div>
  );
};

export default VesselEquipments;
