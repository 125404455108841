import React, { useEffect, useState } from "react";
import ViewModal from "@core/modals/ViewModal";
import ViewButton from "@core/buttons/atoms/ViewButton";
import RowDetails from "@hoc/cruds/RowDetails";
import { useTranslation } from "react-i18next";
import SubStrataService from "@services/SubStrataService";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import StrataService from "@services/StrataService";

const ShowSubStrata = ({ subStrata }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [subStrataData, setSubStrataData] = useState([]);
  const [selectedStratum, setSelectedStratum] = useState();

  const closeModal = () => {
    setShowModal(false);
    setSubStrataData([]);
    setSelectedStratum();
  };
  useEffect(() => {
    if (showModal)
      SubStrataService.find(subStrata.id).then((response) => {
        setSubStrataData(response.data.data);
      });
  }, [subStrata.id, showModal]);

  useEffect(() => {
    if (showModal) {
      StrataService.all().then((response) => {
        const strata = response.data.data.map((stratum) => {
          return {
            value: stratum.id,
            ...stratum,
          };
        });
        const initialStratum = strata?.find(
          (initialStratum) => initialStratum.id === subStrataData.stratum_id
        );
        setSelectedStratum(initialStratum);
      });
    }
  }, [showModal, subStrataData.stratum_id]);

  return (
    <PermissionHandler permission={"view_sub_strata"}>
      <ViewButton onClick={() => setShowModal(true)} />
      <ViewModal
        show={showModal}
        title={`${t("overview")}`}
        onClose={closeModal}
      >
        <div className="flex flex-col mt-5">
          <RowDetails label={t("name")} value={subStrataData.name} />
          <RowDetails label={t("nameEng")} value={subStrataData.name_eng} />
          <RowDetails
            label={t("description")}
            value={subStrataData.description}
          />
          <RowDetails label={t("strataName")} value={selectedStratum?.name} />
          <RowDetails
            label={t("strataNameEng")}
            value={selectedStratum?.name_eng}
          />
          <RowDetails
            label={t("strataDescription")}
            value={selectedStratum?.description}
          />
        </div>
      </ViewModal>
    </PermissionHandler>
  );
};

export default ShowSubStrata;
