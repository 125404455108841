import React from "react";
import Layout from "@hoc/layouts/Layout";
import PortsIndex from "@components/Port/PortsIndex";

const PortsPage = () => {
  return (
    <Layout>
      <PortsIndex />
    </Layout>
  );
};

export default PortsPage;
