import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import GearEUService from "@services/GearEUService";
import EditGearEU from "@components/GearEU/EditGearEU";
import ShowGearEU from "@components/GearEU/ShowGearEU";

const GearEUActions = ({ gearEU, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowGearEU gearEU={gearEU} />
      </div>
      <div className={"mr-2"}>
        <EditGearEU gearEU={gearEU} onEdited={onEdited} />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={GearEUService}
          id={gearEU.id}
          onDeleted={onDeleted}
          permission={"delete_gear_eu"}
        />
      </div>
    </div>
  );
};

export default GearEUActions;
