import React from "react";
import Layout from "@hoc/layouts/Layout";
import DisposalTypesIndex from "@components/DisposalType/DisposalTypesIndex";

const DisposalTypesPage = () => {
  return (
    <Layout>
      <DisposalTypesIndex />
    </Layout>
  );
};

export default DisposalTypesPage;
