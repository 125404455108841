import API from "@utils/plugins/API";

const WinchOperationService = {
  all: (params) => {
    return API.get("winch-operation-types", { params: params });
  },
  create: (name, name_eng, description) => {
    return API.post("winch-operation-types", {
      name,
      name_eng,
      description,
    });
  },
  update: (id, name, name_eng, description) => {
    return API.put(`winch-operation-types/${id}`, {
      name,
      name_eng,
      description,
    });
  },
  find: (id) => {
    return API.get(`winch-operation-types/${id}`);
  },
  destroy: (id) => {
    return API.delete(`winch-operation-types/${id}`);
  },
};

export default WinchOperationService;
