import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import WinchOperationService from "@services/WinchOperationService";
import ShowWinchOperation from "@components/WinchOperationType/ShowWinchOperation";
import EditWinchOperation from "@components/WinchOperationType/EditWinchOperation";

const WinchOperationActions = ({ winchOperation, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowWinchOperation winchOperation={winchOperation} />
      </div>
      <div className={"mr-2"}>
        <EditWinchOperation
          winchOperation={winchOperation}
          onEdited={onEdited}
        />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={WinchOperationService}
          id={winchOperation.id}
          onDeleted={onDeleted}
          permission={"delete_winch_operation_type"}
        />
      </div>
    </div>
  );
};

export default WinchOperationActions;
