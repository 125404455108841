import API from "@utils/plugins/API";

const CaptaincyService = {
  all: (params) => {
    return API.get("captaincies", { params: params });
  },
  create: (name, name_eng, description) => {
    return API.post("captaincies", {
      name,
      name_eng,
      description,
    });
  },
  update: (id, name, name_eng, description) => {
    return API.put(`captaincies/${id}`, {
      name,
      name_eng,
      description,
    });
  },
  find: (id) => {
    return API.get(`captaincies/${id}`);
  },
  destroy: (id) => {
    return API.delete(`captaincies/${id}`);
  },
};

export default CaptaincyService;
