import React, { useEffect, useState } from "react";
import ViewModal from "@core/modals/ViewModal";
import ViewButton from "@core/buttons/atoms/ViewButton";
import RowDetails from "@hoc/cruds/RowDetails";
import { useTranslation } from "react-i18next";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import HullMaterialService from "@services/HullMaterialService";

const ShowHullMaterial = ({ hullMaterial }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [hullMaterialData, setHullMaterialData] = useState([]);

  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (showModal)
      HullMaterialService.find(hullMaterial.id).then((response) => {
        setHullMaterialData(response.data.data);
      });
  }, [hullMaterial.id, showModal]);

  return (
    <PermissionHandler permission={"view_hull_materials"}>
      <ViewButton onClick={() => setShowModal(true)} />
      <ViewModal
        show={showModal}
        title={`${t("overview")}`}
        onClose={closeModal}
      >
        <div className="flex flex-col mt-5">
          <RowDetails label={t("name")} value={hullMaterialData.name} />
          <RowDetails label={t("nameEng")} value={hullMaterialData.name_eng} />
          <RowDetails
            label={t("description")}
            value={hullMaterialData.description}
          />
          <RowDetails
            label={t("hullMaterialEUName")}
            value={hullMaterialData?.hull_materials_eu?.name}
          />
          <RowDetails
            label={t("hullMaterialEUNameEng")}
            value={hullMaterialData?.hull_materials_eu?.name_eng}
          />
          <RowDetails
            label={t("hullMaterialEUDescription")}
            value={hullMaterialData?.hull_materials_eu?.description}
          />
        </div>
      </ViewModal>
    </PermissionHandler>
  );
};

export default ShowHullMaterial;
