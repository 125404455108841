import React, { useMemo, useState } from "react";
import Datatable from "@core/table/Datatable";
import MobileTable from "@core/table/MobileTable";
import { useTranslation } from "react-i18next";
import EngineLocationService from "@services/EngineLocationService";
import CreateEngineLocation from "@components/EngineLocation/CreateEngineLocation";
import EngineLocationActions from "@components/EngineLocation/partials/EngineLocationActions";

const EngineLocationsIndex = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [metaData, setMetaData] = useState();
  const [loading, setLoading] = useState(true);
  const [updatedTable, setUpdatedTable] = useState(0);

  const headers = useMemo(
    () => [
      {
        id: "name",
        name: t("name"),
        cell: (row) => row.name,
      },
      {
        id: "nameEng",
        name: t("nameEng"),
        cell: (row) => row.name_eng,
      },
      {
        id: "description",
        name: t("description"),
        cell: (row) => row.description,
      },
      {
        id: "actions",
        name: t("actions"),
        cell: (row) => (
          <EngineLocationActions
            engineLocation={row}
            onEdited={() => setUpdatedTable((prev) => prev + 1)}
            onDeleted={() => setUpdatedTable((prev) => prev + 1)}
          />
        ),
      },
    ],
    [t]
  );

  const getData = (params) => {
    setLoading(true);
    EngineLocationService.all(params).then((response) => {
      setData(response.data.data);
      setMetaData(response.data.metadata);
      setLoading(false);
    });
  };

  return (
    <div className="border bg-white rounded justify-between items-center mb-5 py-2">
      <div className="mx-3 my-5">
        <label className="text-lg font-bold">{t("engineLocationsList")}</label>
        <div className="flex w-1/2 sm:w-1/4 md:w-1/6 mt-3 mr-2">
          <CreateEngineLocation
            onAdded={() => setUpdatedTable((prev) => prev + 1)}
            label={"Add Engine Location"}
          />
        </div>
        <div className="block lg:hidden">
          <MobileTable
            headers={headers}
            data={data}
            page={metaData?.currentPage}
            totalRows={metaData?.total}
            pageCount={metaData?.lastPage}
          />
        </div>
        <div className="hidden lg:block">
          <Datatable
            data={data}
            headers={headers}
            totalRows={metaData?.total}
            getData={getData}
            isLoading={loading}
            extraDependencies={[updatedTable]}
          />
        </div>
      </div>
    </div>
  );
};

export default EngineLocationsIndex;
