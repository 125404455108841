import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import MobileMenu from "@hoc/partials/MobileMenu";
import UserDropdown from "@components/User/partials/UserDropdown";
import logo from "@assets/svg/logo.png";
import euLogo from "@assets/svg/eu_logo.png";
import { useHistory } from "react-router-dom";

const LogoHeader = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  return (
    <nav className="bg-white">
      <div className="relative items-center h-16 lg:h-20 shadow flex">
        <img
          className={"h-16 hover:cursor-pointer mt-3"}
          src={euLogo}
          alt="logo"
          onClick={() => {
            history.push("/");
          }}
        />
        <img
          className={"h-22 hover:cursor-pointer ml-1"}
          src={logo}
          alt="logo"
          onClick={() => {
            history.push("/");
          }}
        />
        <div className="hidden lg:flex w-full justify-end">
          <div className="flex mr-6">
            <UserDropdown />
          </div>
        </div>
      </div>
      <div
        onClick={() => setOpen(!open)}
        className="flex-1 text-2xl absolute right-8 top-6 cursor-pointer lg:hidden text-indigo-900 z-40"
      >
        {open ? <FaTimes classname="sticky" /> : <FaBars />}
      </div>
      <MobileMenu open={open} />
    </nav>
  );
};

export default LogoHeader;
