import React, { useState } from "react";
import AddButton from "@core/buttons/atoms/AddButton";
import AddModal from "@core/modals/AddModal";
import Input from "@core/inputs/Input";
import { showError, showSuccess } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import CountryService from "@services/CountryService";

const CreateCountry = ({ onAdded }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [englishName, setEnglishName] = useState("");
  const [frenchName, setFrenchName] = useState("");
  const [spanishName, setSpanishName] = useState("");
  const [unitedNation, setUnitedNation] = useState("");
  const [unitedNationDp, setUnitedNationDp] = useState("");
  const [iso2, setIso2] = useState("");
  const [iso3, setIso3] = useState("");
  const [gfcm, setGfcm] = useState(false);
  const [europeanUnion, setEuropeanUnion] = useState(false);
  const [medFisis, setMedfisis] = useState(false);
  const [description, setDescription] = useState("");

  const closeModal = () => {
    setShowModal(false);
    setName("");
    setEnglishName("");
    setFrenchName("");
    setSpanishName("");
    setUnitedNation("");
    setUnitedNationDp("");
    setIso2("");
    setIso3("");
    setGfcm(false);
    setEuropeanUnion(false);
    setMedfisis(false);
    setDescription("");
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    CountryService.create(
      name,
      englishName,
      frenchName,
      spanishName,
      description,
      unitedNation,
      unitedNationDp,
      iso2,
      iso3,
      gfcm,
      europeanUnion,
      medFisis
    )
      .then(() => {
        showSuccess(t("countryCreatedSuccessfully"));
        onAdded();
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PermissionHandler permission={"create_country"}>
      <AddButton label={t("addCountry")} onClick={() => setShowModal(true)} />
      <AddModal
        show={showModal}
        title={t("addCountry")}
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}
      >
        <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
          <Input
            height="h-10"
            label={t("name")}
            placeholder={t("name")}
            value={name}
            onChange={setName}
          />
          <Input
            height="h-10"
            label={t("nameEng")}
            placeholder={t("nameEng")}
            value={englishName}
            onChange={setEnglishName}
          />
          <Input
            height="h-10"
            label={t("nameFrn")}
            placeholder={t("nameFrn")}
            value={frenchName}
            onChange={setFrenchName}
          />
          <Input
            height="h-10"
            label={t("nameEsp")}
            placeholder={t("nameEsp")}
            value={spanishName}
            onChange={setSpanishName}
          />
          <Input
            height="h-10"
            label={t("unitedNation")}
            placeholder={t("unitedNation")}
            value={unitedNation}
            onChange={setUnitedNation}
          />
          <Input
            height="h-10"
            label={t("unitedNationDp")}
            placeholder={t("unitedNationDp")}
            value={unitedNationDp}
            onChange={setUnitedNationDp}
          />
          <Input
            height="h-10"
            label={t("iso2")}
            placeholder={t("iso2")}
            value={iso2}
            onChange={setIso2}
          />
          <Input
            height="h-10"
            label={t("iso3")}
            placeholder={t("iso3")}
            value={iso3}
            onChange={setIso3}
          />
          <Input
            height="h-10"
            label={t("description")}
            placeholder={t("description")}
            value={description}
            onChange={setDescription}
          />
          <div className="flex">
            <div className="flex items-center mt-5">
              <input
                type="checkbox"
                checked={gfcm}
                onClick={() => {
                  setGfcm(!gfcm);
                }}
                className="w-4 h-4 text-indigo-700 bg-gray-100 rounded border-gray-300 focus:ring-indigo-500 focus:ring-2"
              />
              <label className="ml-2 text-sm text-gray-700">{t("gfcm")}</label>
            </div>
            <div className="flex items-center mt-5 ml-3">
              <input
                type="checkbox"
                checked={europeanUnion}
                onClick={() => {
                  setEuropeanUnion(!europeanUnion);
                }}
                className="w-4 h-4 text-indigo-700 bg-gray-100 rounded border-gray-300 focus:ring-indigo-500 focus:ring-2"
              />
              <label className="ml-2 text-sm text-gray-700">
                {t("europeanUnion")}
              </label>
            </div>
            <div className="flex items-center mt-5 ml-5">
              <input
                type="checkbox"
                checked={medFisis}
                onClick={() => {
                  setMedfisis(!medFisis);
                }}
                className="w-4 h-4 text-indigo-700 bg-gray-100 rounded border-gray-300 focus:ring-indigo-500 focus:ring-2"
              />
              <label className="ml-2 text-sm text-gray-700">
                {t("medFisis")}
              </label>
            </div>
          </div>
        </div>
      </AddModal>
    </PermissionHandler>
  );
};

export default CreateCountry;
