import API from "@utils/plugins/API";

const HullMaterialEUService = {
  all: (params) => {
    return API.get("hull-materials-eu", { params: params });
  },
  create: (name, name_eng, description) => {
    return API.post("hull-materials-eu", {
      name,
      name_eng,
      description,
    });
  },
  update: (id, name, name_eng, description) => {
    return API.put(`hull-materials-eu/${id}`, {
      name,
      name_eng,
      description,
    });
  },
  find: (id) => {
    return API.get(`hull-materials-eu/${id}`);
  },
  destroy: (id) => {
    return API.delete(`hull-materials-eu/${id}`);
  },
};

export default HullMaterialEUService;
