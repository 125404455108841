import { ADD_SELECTDATA, REMOVE_SELECTDATA } from "@constants/redux";

const selectDataReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_SELECTDATA:
      return {
        ...state,
        ...action.payload,
      };
    case REMOVE_SELECTDATA:
      return {};
    default:
      return state;
  }
};

export default selectDataReducer;
