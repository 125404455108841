import React from "react";
import Layout from "@hoc/layouts/Layout";
import IssuingOfficesIndex from "@components/IssuingOffice/IssuingOfficesIndex";

const IssuingOfficesPage = () => {
  return (
    <Layout>
      <IssuingOfficesIndex />
    </Layout>
  );
};

export default IssuingOfficesPage;
