import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";

const AuthRoute = ({ component: Component, permission, ...rest }) => {
  const permissions = useSelector((state) =>
    _.get(state, "permissionsReducer.permissions", [])
  );

  const accessToken = useSelector((state) =>
    _.get(state, "authenticationReducer.access_token", null)
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!accessToken) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }
        if (
          permissions.length > 0 &&
          permission &&
          !permission.some((permission) => permissions.includes(permission))
        ) {
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default AuthRoute;
