import React from "react";
import Layout from "@hoc/layouts/Layout";
import OwnersIndex from "@components/Owner/OwnersIndex";

const OwnersPage = () => {
  return (
    <Layout>
      <OwnersIndex />
    </Layout>
  );
};

export default OwnersPage;
