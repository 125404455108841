import API from "@utils/plugins/API";

const GroupOfSpeciesService = {
  all: (params) => {
    return API.get("groups-of-species", { params: params });
  },
  create: (name, name_eng, description, group_of_species_gfcm_id) => {
    return API.post("groups-of-species", {
      name,
      name_eng,
      description,
      group_of_species_gfcm_id,
    });
  },
  update: (id, name, name_eng, description, group_of_species_gfcm_id) => {
    return API.put(`groups-of-species/${id}`, {
      name,
      name_eng,
      description,
      group_of_species_gfcm_id,
    });
  },
  find: (id) => {
    return API.get(`groups-of-species/${id}`);
  },
  destroy: (id) => {
    return API.delete(`groups-of-species/${id}`);
  },
};

export default GroupOfSpeciesService;
