import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import PortService from "@services/PortService";
import EditPort from "@components/Port/EditPort";
import ShowPort from "@components/Port/ShowPort";

const PortActions = ({ port, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowPort port={port} />
      </div>
      <div className={"mr-2"}>
        <EditPort port={port} onEdited={onEdited} />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={PortService}
          id={port.id}
          onDeleted={onDeleted}
          permission={"delete_port"}
        />
      </div>
    </div>
  );
};

export default PortActions;
