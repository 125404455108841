import React, { useState } from "react";
import AddButton from "@core/buttons/atoms/AddButton";
import AddModal from "@core/modals/AddModal";
import Input from "@core/inputs/Input";
import { showError, showSuccess } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import PortService from "@services/PortService";
import PortTypeSelect from "@components/PortType/partials/PortTypeSelect";
import MinorStrataSelect from "@components/MinorStrata/partials/MinorStrataSelect";
import GeoSubAreaSelect from "@components/GeoSubArea/partials/GeoSubAreaSelect";

const CreatePort = ({ onAdded }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [description, setDescription] = useState("");
  const [selectedMinorStratum, setSelectedMinorStratum] = useState();
  const [selectedGeoSubArea, setSelectedGeoSubArea] = useState();
  const [selectedPortType, setSelectedPortType] = useState();
  const [portCode, setPortCode] = useState("");
  const [portCodeEU, setPortCodeEU] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const closeModal = () => {
    setShowModal(false);
    setName("");
    setNameEn("");
    setDescription("");
    setPortCode("");
    setSelectedGeoSubArea("");
    setSelectedMinorStratum("");
    setSelectedMinorStratum("");
    setSelectedPortType("");
    setLatitude("");
    setLongitude("");
    setPortCodeEU("");
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    PortService.create(
      name,
      nameEn,
      description,
      selectedMinorStratum,
      selectedGeoSubArea,
      selectedPortType,
      latitude,
      longitude,
      portCode,
      portCodeEU
    )
      .then(() => {
        showSuccess(t("portCreatedSuccessfully"));
        closeModal();
        onAdded();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PermissionHandler permission={"create_port"}>
      <AddButton label={t("addPort")} onClick={() => setShowModal(true)} />
      <AddModal
        show={showModal}
        title={t("addPort")}
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}
      >
        <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5 sm:gap-10">
          <Input
            height="h-10"
            label={t("name")}
            placeholder={t("name")}
            value={name}
            onChange={setName}
          />
          <Input
            height="h-10"
            label={t("nameEng")}
            placeholder={t("nameEng")}
            value={nameEn}
            onChange={setNameEn}
          />
          <Input
            height="h-10"
            label={t("description")}
            placeholder={t("description")}
            value={description}
            onChange={setDescription}
          />
          <div>
            <div className="text-xs font-semibold mb-2 flex">
              {t("minorStratum")}
            </div>
            <MinorStrataSelect
              openModal={showModal}
              onSelect={setSelectedMinorStratum}
              selected={selectedMinorStratum}
            />
          </div>
          <div>
            <div className="text-xs font-semibold mb-2">{t("geoSubArea")}</div>
            <GeoSubAreaSelect
              openModal={showModal}
              onSelect={setSelectedGeoSubArea}
              selected={selectedGeoSubArea}
            />
          </div>
          <div>
            <div className="text-xs font-semibold mb-2">{t("portType")}</div>
            <PortTypeSelect
              openModal={showModal}
              onSelect={setSelectedPortType}
              selected={selectedPortType}
            />
          </div>
          <Input
            height="h-10"
            label={t("latitude")}
            placeholder={t("latitude")}
            value={latitude}
            onChange={setLatitude}
          />
          <Input
            height="h-10"
            label={t("longitude")}
            placeholder={t("longitude")}
            value={longitude}
            onChange={setLongitude}
          />
          <Input
            height="h-10"
            label={t("portCode")}
            placeholder={t("portCode")}
            value={portCode}
            onChange={setPortCode}
          />
          <Input
            height="h-10"
            label={t("portCodeEU")}
            placeholder={t("portCodeEU")}
            value={portCodeEU}
            onChange={setPortCodeEU}
          />
        </div>
      </AddModal>
    </PermissionHandler>
  );
};

export default CreatePort;
