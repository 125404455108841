import React from "react";
import Layout from "@hoc/layouts/Layout";
import HullMaterialsIndex from "@components/HullMaterial/HullMaterialsIndex";

const HullMaterialsPage = () => {
  return (
    <Layout>
      <HullMaterialsIndex />
    </Layout>
  );
};

export default HullMaterialsPage;
