import React from "react";
import {useTranslation} from "react-i18next";
import Input from "@core/inputs/Input";
import "react-datepicker/dist/react-datepicker.css";
import CDefaultDatePicker from "@core/inputs/dateTime/CDefaultDatePicker";
import ApiSelect from "@core/dropdowns/ApiSelect";
import AuthorizationTypeService from "@services/AuthorizationTypeService";
import IssuingOfficeService from "@services/IssuingOfficeService";
import Gear from "@components/Vessel/partials/Gear";
import {Plus} from "tabler-icons-react";

const VesselAuthorization = ({
                               disabled = false,
                               data,
                               onChange,
                               gears,
                               onGearsChange,
                               required = true,
                               isClearable = false
                             }) => {
  const {t} = useTranslation();

  const handleChange = (value, name) => {
    onChange({...data, [name]: value});
  };

  const addGear = () => {
    const gear = {
      gear_class_id: "",
      gear_type_id: "",
      target_group_of_species_id: "",
      remarks: "",
    };
    onGearsChange([...gears, gear]);
  };

  const handleDelete = (index) => {
    const newGear = gears.filter((gear) => gear !== gears[index]);
    onGearsChange(newGear);
  };

  const handleUpdate = (index, gear) => {
    const newGear = [...gears];
    newGear[index] = gear;
    onGearsChange(newGear);
  };

  return (
    <>
      <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
        <Input
          disabled={disabled}
          height="h-10"
          label={t("detarRegistrationNumber")}
          placeholder={t("detarRegistrationNumber")}
          value={data?.detar_registration_number}
          onChange={(val) => handleChange(val, "detar_registration_number")}
        />
        <div>
          <div className="text-sm font-semibold flex mb-2">
            {t("authorizationType")}
            {required && <div className="text-red-600 ml-1">*</div>}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={"authorisationType"}
            disabled={disabled}
            service={AuthorizationTypeService}
            onSelect={(val) => handleChange(val, "authorisation_type_id")}
            selected={data?.authorisation_type_id}
          />
        </div>
        <Input
          required={required}
          disabled={disabled}
          height="h-10"
          label={t("documentId")}
          placeholder={t("documentId")}
          value={data?.document_id}
          onChange={(val) => handleChange(val, "document_id")}
        />
        <div>
          <div className="text-sm font-semibold mb-2 flex">
            {t("issuingOffice")}
            {required && <div className="text-red-600 ml-1">*</div>}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={"issuingOffice"}
            disabled={disabled}
            service={IssuingOfficeService}
            onSelect={(val) => handleChange(val, "issuing_office_id")}
            selected={data?.issuing_office_id}
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">{t("issueDate")}</div>
          <CDefaultDatePicker
            disabled={disabled}
            value={data?.issue_date}
            onChange={(val) => handleChange(val, "issue_date")}
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2 flex">
            {t("expiryDate")}
            {required && <div className="text-red-600 ml-1">*</div>}
          </div>
          <CDefaultDatePicker
            disabled={disabled}
            value={data?.expiry_date}
            onChange={(val) => handleChange(val, "expiry_date")}
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">
            {t("fisheryEntryDate")}
          </div>
          <CDefaultDatePicker
            disabled={disabled}
            value={data?.fishery_entry_date}
            onChange={(val) => handleChange(val, "fishery_entry_date")}
          />
        </div>
        <Input
          required={required}
          disabled={disabled}
          height="h-10"
          label={t("fisheryDepartmentRegistrationNumber")}
          placeholder={t("fisheryDepartmentRegistrationNumber")}
          value={data?.fishery_department_registration_number}
          onChange={(val) =>
            handleChange(val, "fishery_department_registration_number")
          }
        />
      </div>
      <div className="flex items-center mt-5">
        <input
          disabled={disabled}
          type="checkbox"
          checked={data?.license_indicator}
          value={data?.license_indicator}
          onChange={() =>
            handleChange(!data?.license_indicator, "license_indicator")
          }
          className="w-4 h-4 text-indigo-700 bg-gray-100 rounded border-gray-300 focus:ring-indigo-500 focus:ring-2"
        />
        <label className="ml-2 text-base text-gray-700">
          {t("licenseIndicator")}
        </label>
      </div>
      <div className="mt-4 mb-2">
        <Input
          disabled={disabled}
          height="h-10"
          label={t("remarks")}
          placeholder={t("remarks")}
          value={data?.remarks}
          onChange={(val) => handleChange(val, "remarks")}
        />
      </div>
      <div>
        <div className="text-sm font-bold mb-7 mt-7">{t("gears")}</div>
        <div className="flex flex-col gap-5">
          {gears?.map((gear, index) => (
            <Gear
              isClearable={isClearable}
              required={required}
              disabled={disabled}
              data={gear}
              key={index}
              onDelete={handleDelete}
              onChange={handleUpdate}
              index={index}
            />
          ))}
        </div>
        {!disabled && (
          <button onClick={addGear}>
            <div className="flex text-indigo-600 mt-2">
              <Plus/>
              <div className="ml-2 font-semibold">{t("addGear")}</div>
            </div>
          </button>
        )}
      </div>
    </>
  );
};

export default VesselAuthorization;
