import API from "@utils/plugins/API";

const MarineFisherySubsectorService = {
  all: (params) => {
    return API.get("marine-fishery-sub-sectors", { params: params });
  },
  create: (name, name_eng, description) => {
    return API.post("marine-fishery-sub-sectors", {
      name,
      name_eng,
      description,
    });
  },
  update: (id, name, name_eng, description) => {
    return API.put(`marine-fishery-sub-sectors/${id}`, {
      name,
      name_eng,
      description,
    });
  },
  find: (id) => {
    return API.get(`marine-fishery-sub-sectors/${id}`);
  },
  destroy: (id) => {
    return API.delete(`marine-fishery-sub-sectors/${id}`);
  },
};

export default MarineFisherySubsectorService;
