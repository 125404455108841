import React, { useCallback, useEffect, useState } from "react";
import LogoHeader from "@hoc/partials/LogoHeader";
import TabSwitch from "@core/tabs/TabSwitch";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import VesselCharacteristics from "@components/Vessel/tabs/VesselCharacteristics";
import VesselService from "@services/VesselService";
import { FaArrowLeft } from "react-icons/fa";
import VesselAuthorization from "@components/Vessel/tabs/VesselAuthorization";
import VesselStructuralCharacteristics from "@components/Vessel/tabs/VesselStructuralCharacteristics";
import VesselElectronics from "@components/Vessel/tabs/VesselElectronics";
import VesselDeckMachinery from "@components/Vessel/tabs/VesselDeckMachinery";
import VesselCrew from "@components/Vessel/tabs/VesselCrew";
import VesselPollutionPrevention from "@components/Vessel/tabs/VesselPollutionPrevention";
import VesselEngines from "@components/Vessel/tabs/VesselEngines";
import VesselEquipments from "@components/Vessel/tabs/VesselEquipments";
import VesselPorts from "@components/Vessel/tabs/VesselPorts";
import VesselOperations from "@components/Vessel/tabs/VesselOperations";
import VesselRemarks from "@components/Vessel/tabs/VesselRemarks";
import Lottie from "@core/Lottie";
import { wait } from "@utils/helpers";
import VesselOwnerships from "@components/Vessel/tabs/VesselOwnerships";

const ShowVesselPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const { vesselId } = useParams();

  const [isFetching, setIsFetching] = useState(true);
  const [active, setActive] = useState("characteristics");
  const [characteristics, setCharacteristics] = useState({});
  const [structuralCharacteristics, setStructuralCharacteristics] = useState(
    {}
  );
  const [authorisation, setAuthorisation] = useState({});
  const [engines, setEngines] = useState([]);
  const [electronic, setElectronic] = useState({});
  const [equipments, setEquipments] = useState([]);
  const [deckMachinery, setDeckMachinery] = useState({});
  const [ownerships, setOwnerships] = useState([]);
  const [crew, setCrew] = useState({});
  const [ports, setPorts] = useState([]);
  const [operations, setOperations] = useState([]);
  const [pollutionPrevention, setPollutionPrevention] = useState({});
  const [remarks, setRemarks] = useState("");
  const [gears, setGears] = useState([]);
  const [winches, setWinches] = useState([]);

  const getData = useCallback(() => {
    setIsFetching(true);
    VesselService.find(vesselId)
      .then((res) => {
        const data = res.data.data;
        setCharacteristics(data.characteristics);
        setStructuralCharacteristics(data.structural_characteristics);
        setAuthorisation(data.authorisation);
        setEngines(data.engines || []);
        setElectronic(data.electronic);
        setEquipments(data.equipments || []);
        setDeckMachinery(data.deck_machinery);
        setOwnerships(data.ownership || []);
        setCrew(data.crew);
        setPorts(data.ports || []);
        setOperations(data.operations || []);
        setPollutionPrevention(data.pollution_prevention);
        setRemarks(data.remarks);
        setGears(data?.authorisation?.gears || []);
        setWinches(data?.deck_machinery?.winches || []);
      })
      .finally(async () => {
        await wait();
        setIsFetching(false);
      });
  }, [vesselId]);

  useEffect(() => {
    if (!id && !vesselId) {
      history.push("/");
    }
  }, [history, id, vesselId]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <PermissionHandler permission={"view_vessels"}>
      <div className={"min-h-screen flex flex-row bg-gray-100 pb-30"}>
        <div className={"flex-1"}>
          <LogoHeader />
          <div className="mt-10 px-15">
            <TabSwitch
              containerClassName="mt-10"
              items={[
                "characteristics",
                "structuralCharacteristics",
                "authorization",
                "engines",
                "electronics",
                "equipment",
                "deckMachinery",
                "ownership",
                "crew",
                "ports",
                "operations",
                "pollutionPrevention",
                "remarks",
              ]}
              activeTab={active}
              onChange={setActive}
            />

            <div className="bg-white rounded-b-lg justify-between items-center pt-2 pb-10 px-22 shadow">
              {isFetching && (
                <div className={"flex justify-center items-center p-10"}>
                  <Lottie
                    width={200}
                    animationData={require("@assets/animations/loading.json")}
                  />
                </div>
              )}
              {!isFetching && (
                <>
                  {active === "characteristics" && (
                    <VesselCharacteristics
                      disabled
                      data={characteristics}
                      onChange={setCharacteristics}
                      onShow
                    />
                  )}
                  {active === "structuralCharacteristics" && (
                    <VesselStructuralCharacteristics
                      disabled
                      data={structuralCharacteristics}
                      onChange={setStructuralCharacteristics}
                    />
                  )}
                  {active === "authorization" && (
                    <VesselAuthorization
                      disabled
                      data={authorisation}
                      onChange={setAuthorisation}
                      gears={gears}
                      onGearsChange={setGears}
                    />
                  )}
                  {active === "engines" && (
                    <VesselEngines
                      disabled
                      data={engines}
                      onChange={setEngines}
                    />
                  )}
                  {active === "electronics" && (
                    <VesselElectronics
                      disabled
                      data={electronic}
                      onChange={setElectronic}
                    />
                  )}
                  {active === "equipment" && (
                    <VesselEquipments
                      disabled
                      data={equipments}
                      onChange={setEquipments}
                    />
                  )}
                  {active === "deckMachinery" && (
                    <VesselDeckMachinery
                      disabled
                      data={deckMachinery}
                      onChange={setDeckMachinery}
                      winches={winches}
                      onWinchesChange={setWinches}
                    />
                  )}
                  {active === "ownership" && (
                    <VesselOwnerships
                      createOwner={false}
                      disabled
                      data={ownerships}
                      onChange={setOwnerships}
                    />
                  )}
                  {active === "crew" && (
                    <VesselCrew disabled data={crew} onChange={setCrew} />
                  )}
                  {active === "ports" && (
                    <VesselPorts disabled data={ports} onChange={setPorts} />
                  )}
                  {active === "operations" && (
                    <VesselOperations
                      disabled
                      data={operations}
                      onChange={setOperations}
                    />
                  )}
                  {active === "pollutionPrevention" && (
                    <VesselPollutionPrevention
                      disabled
                      data={pollutionPrevention}
                      onChange={setPollutionPrevention}
                    />
                  )}
                  {active === "remarks" && (
                    <VesselRemarks
                      disabled
                      data={remarks}
                      onChange={setRemarks}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="sticky w-full shadow border-t bottom-0 bg-white flex items-center justify-end py-3 px-3">
        <Link to={`/census/${id}`}>
          <div className="flex cursor-pointer mr-5">
            <FaArrowLeft className="mt-1.5 mr-2" />
            {t("goBack")}
          </div>
        </Link>
      </div>
    </PermissionHandler>
  );
};

export default ShowVesselPage;
