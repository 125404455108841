import React, { useEffect, useState } from "react";
import EditModal from "@core/modals/EditModal";
import EditButton from "@core/buttons/atoms/EditButton";
import Input from "@core/inputs/Input";
import { showError, showSuccess } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import GroupOfSpeciesGFCMService from "@services/GroupOfSpeciesGFCMService";

const EditGroupOfSpeciesGFCM = ({ groupOfSpeciesGFCMA, onEdited }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [englishName, setEnglishName] = useState("");
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setName("");
    setDescription("");
    setEnglishName("");
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    GroupOfSpeciesGFCMService.update(
      groupOfSpeciesGFCMA.id,
      name,
      englishName,
      description
    )
      .then(() => {
        showSuccess(t("groupOfSpeciesGFCMEditedSuccessfully"));
        onEdited();
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (showModal)
      GroupOfSpeciesGFCMService.find(groupOfSpeciesGFCMA.id).then(
        (response) => {
          setName(response.data.data.name);
          setEnglishName(response.data.data.name_eng);
          setDescription(response.data.data.description);
        }
      );
  }, [groupOfSpeciesGFCMA.id, showModal]);

  return (
    <PermissionHandler permission={"update_group_of_species_gfcm"}>
      <EditButton onClick={() => setShowModal(true)} />
      <EditModal
        show={showModal}
        title={t("editGroupOfSpeciesGFCM")}
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}
        label={t("edit")}
      >
        <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
          <Input
            height="h-10"
            label={t("name")}
            placeholder={t("name")}
            value={name}
            onChange={setName}
          />
          <Input
            height="h-10"
            label={t("nameEng")}
            placeholder={t("nameEng")}
            value={englishName}
            onChange={setEnglishName}
          />
          <Input
            height="h-10"
            label={t("description")}
            placeholder={t("description")}
            value={description}
            onChange={setDescription}
          />
        </div>
      </EditModal>
    </PermissionHandler>
  );
};

export default EditGroupOfSpeciesGFCM;
