import React from "react";
import Layout from "@hoc/layouts/Layout";
import FleetSegmentationsIndex from "@components/FleetSegmentation/FleetSegmentationsIndex";

const FleetSegmentationsPage = () => {
  return (
    <Layout>
      <FleetSegmentationsIndex/>
    </Layout>
  );
};

export default FleetSegmentationsPage;
