import React from "react";
import Layout from "@hoc/layouts/Layout";
import WinchOperationsIndex from "@components/WinchOperationType/WinchOperationsIndex";

const WinchOperationPage = () => {
  return (
    <Layout>
      <WinchOperationsIndex />
    </Layout>
  );
};

export default WinchOperationPage;
