import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import EditMinorStrata from "@components/MinorStrata/EditMinorStrata";
import ShowMinorStrata from "@components/MinorStrata/ShowMinorStrata";
import MinorStrataService from "@services/MinorStrataService";

const MinorStrataActions = ({ minorStrata, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowMinorStrata minorStrata={minorStrata} />
      </div>
      <div className={"mr-2"}>
        <EditMinorStrata minorStrata={minorStrata} onEdited={onEdited} />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={MinorStrataService}
          id={minorStrata.id}
          onDeleted={onDeleted}
          permission={"delete_sub_stratum"}
        />
      </div>
    </div>
  );
};

export default MinorStrataActions;
