import API from "@utils/plugins/API";

const EquipmentCategoryService = {
  all: (params) => {
    return API.get("equipment-categories", { params: params });
  },
  create: (name, name_eng, description) => {
    return API.post("equipment-categories", {
      name,
      name_eng,
      description,
    });
  },
  update: (id, name, name_eng, description) => {
    return API.put(`equipment-categories/${id}`, {
      name,
      name_eng,
      description,
    });
  },
  find: (id) => {
    return API.get(`equipment-categories/${id}`);
  },
  destroy: (id) => {
    return API.delete(`equipment-categories/${id}`);
  },
};

export default EquipmentCategoryService;
