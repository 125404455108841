import API from "@utils/plugins/API";

const PDFService = {
  reports: (id, report_type) => {
    return API.get(
      `reports/get-report-signed-url?census_id=${id}&report_type=${report_type}`
    );
  },
};

export default PDFService;
