import API from "@utils/plugins/API";

const OwnerService = {
  all: (params) => {
    return API.get('owners', {params: params})
  },
  create: (company_type_id, company_name, established_year, address, postcode, town, region_id, country_id, registration_office_id, remarks) => {
    return API.post('owners', {
      company_type_id,
      company_name,
      established_year,
      address,
      postcode,
      town,
      region_id,
      country_id,
      registration_office_id,
      remarks
    })
  },
  update: (id, company_type_id, company_name, established_year, address, postcode, town, region_id, country_id, registration_office_id, remarks) => {
    return API.put(`owners/${id}`, {
      company_type_id,
      company_name,
      established_year,
      address,
      postcode,
      town,
      region_id,
      country_id,
      registration_office_id,
      remarks
    })
  },
  find: (id) => {
    return API.get(`owners/${id}`)
  },
  destroy: (id) => {
    return API.delete(`owners/${id}`)
  },
}

export default OwnerService;
