import React from "react";
import Input from "@core/inputs/Input";
import { Trash } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
import ApiSelect from "@core/dropdowns/ApiSelect";
import PortService from "@services/PortService";
import MonthService from "@services/MonthService";

const Port = ({ data, index, onDelete, onChange, disabled, required, isClearable  }) => {
  const { t } = useTranslation();

  const handleChange = (value, name) => {
    onChange(index, { ...data, [name]: value });
  };

  return (
    <div key={index}>
      <div className="grid grid-cols-5 gap-5">
        <div>
          <div className="text-xs font-semibold mb-2 flex">
            {t("port")}
            {required && <div className="text-red-600 ml-1">*</div>}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={"port"}
            disabled={disabled}
            service={PortService}
            onSelect={(val) => handleChange(val, "port_id")}
            selected={data?.port_id}
          />
        </div>
        <div>
          <div className="text-xs font-semibold mb-2 flex">
            {t("beginMonth")}
            {required && <div className="text-red-600 ml-1">*</div>}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={"month"}
            disabled={disabled}
            service={MonthService}
            onSelect={(val) => handleChange(val, "begin_month_id")}
            selected={data?.begin_month_id}
          />
        </div>
        <div>
          <div className="text-xs font-semibold mb-2 flex">
            {t("endMonth")}
            {required && <div className="text-red-600 ml-1">*</div>}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={"month"}
            disabled={disabled}
            service={MonthService}
            onSelect={(val) => handleChange(val, "end_month_id")}
            selected={data?.end_month_id}
          />
        </div>
        <Input
          disabled={disabled}
          height="h-10"
          label={t("remarks")}
          placeholder={t("remarks")}
          value={data?.remarks}
          onChange={(val) => handleChange(val, "remarks")}
          extraClasses={"text-xs"}
        />
        {!disabled && (
          <div className="flex self-center pt-5">
            <button
              className="flex text-red-600 cursor-pointer ml-auto"
              onClick={() => onDelete(index)}
            >
              <Trash />
            </button>
          </div>
        )}
      </div>
      <hr className="px-5 my-3" />
    </div>
  );
};

export default Port;
