import React from "react";
import { useTranslation } from "react-i18next";
import Input from "@core/inputs/Input";
import ApiSelect from "@core/dropdowns/ApiSelect";
import DisposalTypeService from "@services/DisposalTypeService";

const VesselPollutionPrevention = ({ disabled = false, data, onChange, isClearable = false }) => {
  const { t } = useTranslation();

  const handleChange = (value, name) => {
    onChange({ ...data, [name]: value });
  };

  return (
    <>
      <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
        <div>
          <div className="text-sm font-semibold mb-2">
            {t("oilResidueDisposal")}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={"disposalOilResidue"}
            disabled={disabled}
            service={DisposalTypeService}
            onSelect={(val) => handleChange(val, "disposal_oil_residue_id")}
            selected={data?.disposal_oil_residue_id}
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">
            {t("otherResidueDisposal")}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={"disposalOtherResidue"}
            disabled={disabled}
            service={DisposalTypeService}
            onSelect={(val) => handleChange(val, "disposal_other_residue_id")}
            selected={data?.disposal_other_residue_id}
          />
        </div>
      </div>
      <Input
        disabled={disabled}
        height="h-10"
        label={t("remarks")}
        placeholder={t("remarks")}
        value={data.remarks}
        onChange={(val) => handleChange(val, "remarks")}
      />
    </>
  );
};

export default VesselPollutionPrevention;
