import API from "@utils/plugins/API";

const VesselTypeService = {
  all: (params) => {
    return API.get("vessel-types", { params: params });
  },
  create: (
    name,
    name_eng,
    vessel_type_fffao_id,
    std_abbreviation,
    description
  ) => {
    return API.post("vessel-types", {
      name,
      name_eng,
      vessel_type_fffao_id,
      std_abbreviation,
      description,
    });
  },
  update: (
    id,
    name,
    name_eng,
    vessel_type_fffao_id,
    std_abbreviation,
    description
  ) => {
    return API.put(`vessel-types/${id}`, {
      name,
      name_eng,
      vessel_type_fffao_id,
      std_abbreviation,
      description,
    });
  },
  find: (id) => {
    return API.get(`vessel-types/${id}`);
  },
  destroy: (id) => {
    return API.delete(`vessel-types/${id}`);
  },
};

export default VesselTypeService;
