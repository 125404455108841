import React, { useMemo, useState } from "react";
import Datatable from "@core/table/Datatable";
import MobileTable from "@core/table/MobileTable";
import { useTranslation } from "react-i18next";
import OperationalStatusActions from "@components/OperationalStatus/partials/OperationalStatusActions";
import OperationalStatusService from "@services/OperationalStatusService";

const OperationalStatusesIndex = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [metaData, setMetaData] = useState();
  const [loading, setLoading] = useState(true);

  const headers = useMemo(
    () => [
      {
        id: "name",
        name: t("name"),
        cell: (row) => row.name,
      },
      {
        id: "nameEng",
        name: t("nameEng"),
        cell: (row) => row.name_eng,
      },
      {
        id: "description",
        name: t("description"),
        cell: (row) => row.description,
      },
      {
        id: "actions",
        name: t("actions"),
        cell: (row) => <OperationalStatusActions operationalStatus={row} />,
      },
    ],
    [t]
  );

  const getData = (params) => {
    setLoading(true);
    OperationalStatusService.all(params).then((response) => {
      setData(response.data.data);
      setMetaData(response.data.metadata);
      setLoading(false);
    });
  };

  return (
    <div className="border bg-white rounded justify-between items-center mb-5 py-2">
      <div className="mx-3 my-5">
        <label className="text-lg font-bold">
          {t("operationalStatusesList")}
        </label>
        <div className="block lg:hidden">
          <MobileTable
            headers={headers}
            data={data}
            page={metaData?.currentPage}
            totalRows={metaData?.total}
            pageCount={metaData?.lastPage}
          />
        </div>
        <div className="hidden lg:block">
          <Datatable
            data={data}
            headers={headers}
            totalRows={metaData?.total}
            getData={getData}
            isLoading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default OperationalStatusesIndex;
