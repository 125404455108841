import React from "react";
import ShowOperationalStatus from "@components/OperationalStatus/ShowOperationalStatus";

const OperationalStatusActions = ({ operationalStatus }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowOperationalStatus operationalStatus={operationalStatus} />
      </div>
    </div>
  );
};

export default OperationalStatusActions;
