import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import GearService from "@services/GearService";
import ShowGear from "@components/Gear/ShowGear";
import EditGear from "@components/Gear/EditGear";

const GearActions = ({ gear, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowGear gear={gear} />
      </div>
      <div className={"mr-2"}>
        <EditGear gear={gear} onEdited={onEdited} />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={GearService}
          id={gear.id}
          onDeleted={onDeleted}
          permission={"delete_gear"}
        />
      </div>
    </div>
  );
};

export default GearActions;
