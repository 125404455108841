import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import ShowGroupOfSpecies from "@components/GroupOfSpecies/ShowGroupOfSpecies";
import EditGroupOfSpecies from "@components/GroupOfSpecies/EditGroupOfSpecies";
import GroupOfSpeciesService from "@services/GroupOfSpeciesService";

const GroupOfSpeciesActions = ({ groupOfSpecies, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowGroupOfSpecies groupOfSpecies={groupOfSpecies} />
      </div>
      <div className={"mr-2"}>
        <EditGroupOfSpecies
          groupOfSpecies={groupOfSpecies}
          onEdited={onEdited}
        />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={GroupOfSpeciesService}
          id={groupOfSpecies.id}
          onDeleted={onDeleted}
          permission={"delete_group_of_species"}
        />
      </div>
    </div>
  );
};

export default GroupOfSpeciesActions;
