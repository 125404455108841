import React from "react";
import Layout from "@hoc/layouts/Layout";
import StratasIndex from "@components/Strata/StratasIndex";

const StartasPage = () => {
  return (
    <Layout>
      <StratasIndex />
    </Layout>
  );
};

export default StartasPage;
