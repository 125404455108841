import React from "react";
import Layout from "@hoc/layouts/Layout";
import SubStratasIndex from "@components/SubStrata/SubStratasIndex";

const SubStartasPage = () => {
  return (
    <Layout>
      <SubStratasIndex />
    </Layout>
  );
};

export default SubStartasPage;
