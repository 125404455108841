import React from "react";
import Layout from "@hoc/layouts/Layout";
import GearClassesIndex from "@components/GearClass/GearClassesIndex";

const GearClassesPage = () => {
  return (
    <Layout>
      <GearClassesIndex />
    </Layout>
  );
};

export default GearClassesPage;
