import React from "react";
import Layout from "@hoc/layouts/Layout";
import FishingZonesIndex from "@components/FishingZone/FishingZonesIndex";

const FishingZonesPage = () => {
  return (
    <Layout>
      <FishingZonesIndex />
    </Layout>
  );
};

export default FishingZonesPage;
