import API from "@utils/plugins/API";

const MinorStrataService = {
  all: (params) => {
    return API.get("minor-strata", { params: params });
  },
  create: (name, name_eng, sub_stratum_id, description) => {
    return API.post("minor-strata", {
      name,
      name_eng,
      sub_stratum_id,
      description,
    });
  },
  update: (id, name, name_eng, sub_stratum_id, description) => {
    return API.put(`minor-strata/${id}`, {
      name,
      name_eng,
      sub_stratum_id,
      description,
    });
  },
  find: (id) => {
    return API.get(`minor-strata/${id}`);
  },
  destroy: (id) => {
    return API.delete(`minor-strata/${id}`);
  },
};

export default MinorStrataService;
