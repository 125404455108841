import React from 'react';
import Input from '@core/inputs/Input';
import {Trash} from 'tabler-icons-react';
import NumericalInput from "@core/inputs/NumericalInput";
import {useTranslation} from "react-i18next";
import ApiSelect from "@core/dropdowns/ApiSelect";
import CountryService from "@services/CountryService";
import EnergySourceService from "@services/EnergySourceService";
import EngineLocationService from "@services/EngineLocationService";
import CountrySelect from "@components/Country/partials/CountrySelect";

const Engine = ({
                  data,
                  index,
                  onDelete,
                  onChange,
                  disabled = false,
                  required,
                  isClearable
                }) => {
  const {t} = useTranslation();

  const handleChange = (value, name) => {
    onChange(index, {...data, [name]: value});
  };

  return (
    <div key={index}>
      <div className="grid grid-cols-5 gap-3">
        <NumericalInput
          disabled={true}
          height='h-10'
          label={t('rank')}
          placeholder={t('rank')}
          value={index + 1}
          extraClasses={'text-xs'}
        />
        <Input
          disabled={disabled}
          height='h-10'
          label={t('model')}
          placeholder={t('model')}
          value={data?.model}
          onChange={(val) => handleChange(val, 'model')}
          extraClasses={'text-xs'}
        />
        <div>
          <div className="text-xs font-semibold mb-2 flex">
            {t('location')}
            {required && <div className="text-red-600 ml-1">*</div>}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={'engineLocation'}
            disabled={disabled}
            service={EngineLocationService}
            onSelect={(val) => handleChange(val, 'location_id')}
            selected={data?.location_id}
            placeholder={t('chooseLocation')}
          />
        </div>
        <NumericalInput
          required={required}
          disabled={disabled}
          height='h-10'
          label={t('power')}
          placeholder={t('power')}
          value={data?.power}
          onChange={(val) => handleChange(val, 'power')}
          extraClasses={'text-xs'}
        />
        <div>
          <div className="text-xs font-semibold mb-2">
            {t('energySource')}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={'energySource'}
            disabled={disabled}
            service={EnergySourceService}
            onSelect={(val) => handleChange(val, 'energy_source_id')}
            selected={data?.energy_source_id}
          />
        </div>
        <div>
          <div className="text-xs font-semibold mb-2">
            {t('construction')}
          </div>
          <CountrySelect
            isClearable={isClearable}
            disabled={disabled}
            service={CountryService}
            onSelect={(val) => handleChange(val, 'construction_country_id')}
            selected={data?.construction_country_id}
            placeholder={t('chooseConstruction')}
          />
        </div>
        <NumericalInput
          disabled={disabled}
          height='h-10'
          label={t('constructionYear')}
          placeholder={t('constructionYear')}
          value={data?.construction_year}
          onChange={(val) => handleChange(val, 'construction_year')}
          extraClasses={'text-xs'}
        />
        <Input
          disabled={disabled}
          height='h-10'
          label={t('remarks')}
          placeholder={t('remarks')}
          value={data?.remarks}
          onChange={(val) => handleChange(val, 'remarks')}
          extraClasses={'text-xs'}
        />
        <div/>
        {!disabled && <div className="flex self-center pt-5">
          <button
            className="flex text-red-600 cursor-pointer ml-auto"
            onClick={() => onDelete(index)}
          >
            <Trash/>
          </button>
        </div>}
      </div>
      <hr className="px-5 my-3"/>
    </div>
  );
};

export default Engine;