import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Plus } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
import Port from "@components/Vessel/partials/Port";

const VesselPorts = ({ disabled = false, data, onChange, required = true, isClearable = false }) => {
  const { t } = useTranslation();

  const addPort = () => {
    const port = {
      port_id: "",
      begin_month_id: "",
      end_month_id: "",
      remarks: "",
    };
    onChange([...data, port]);
  };

  const handleDelete = (index) => {
    const newPort = data.filter((port) => port !== data[index]);
    onChange(newPort);
  };

  const handleUpdate = (index, port) => {
    const newPort = [...data];
    newPort[index] = port;
    onChange(newPort);
  };

  return (
    <div>
      <div className="text-sm font-bold mb-7 mt-7">{t("ports")}</div>
      <div className="flex flex-col gap-5">
        {data.map((port) => (
          <Port
            isClearable={isClearable}
            required={required}
            disabled={disabled}
            data={port}
            key={data.indexOf(port)}
            onDelete={handleDelete}
            onChange={handleUpdate}
            index={data.indexOf(port)}
          />
        ))}
      </div>
      {!disabled && (
        <button onClick={addPort}>
          <div className="flex text-indigo-600 mt-2">
            <Plus />
            <div className="ml-2 font-semibold">{t("addPort")}</div>
          </div>
        </button>
      )}
    </div>
  );
};

export default VesselPorts;
