import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Plus } from "tabler-icons-react";
import Engine from "@components/Vessel/partials/Engine";
import { useTranslation } from "react-i18next";

const VesselEngines = ({ disabled = false, data, onChange, required = true, isClearable = false }) => {
  const { t } = useTranslation();

  const addEngine = () => {
    const engine = {
      model: "",
      location_id: "",
      power: "",
      energy_source_id: "",
      construction_country_id: "",
      construction_year: "",
      remarks: "",
    };
    onChange([...data, engine]);
  };

  const handleDelete = (index) => {
    const newEngine = data.filter((engine) => engine !== data[index]);
    onChange(newEngine);
  };

  const updateEngine = (index, engine) => {
    const newEngine = [...data];
    newEngine[index] = engine;
    onChange(newEngine);
  };

  return (
    <div>
      <div className="text-sm font-bold mb-7 mt-7">{t("engines")}</div>
      <div className="flex flex-col gap-5">
        {data?.map((engine) => (
          <Engine
            isClearable={isClearable}
            required={required}
            disabled={disabled}
            data={engine}
            key={data.indexOf(engine)}
            onDelete={handleDelete}
            onChange={updateEngine}
            index={data.indexOf(engine)}
          />
        ))}
      </div>
      {!disabled && (
        <button onClick={addEngine}>
          <div className="flex text-indigo-600 mt-2">
            <Plus />
            <div className="ml-2 font-semibold">{t("addEngine")}</div>
          </div>
        </button>
      )}
    </div>
  );
};

export default VesselEngines;
