import React, {useState, useEffect} from "react";
import Select from "react-select";
import {addSelectData} from "@redux/select/Action";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import GearService from "@services/GearService";
import i18n from "i18next";

const GearTypeSelect = ({
                          isClearable = false,
                          reduxKey,
                          onSelect,
                          selected,
                          gearClassId,
                          disabled = false,
                          showModal = true,
                        }) => {
  const language = i18n.language;
  const dispatch = useDispatch();
  const [selectedObject, setSelectedObject] = useState();

  // eslint-disable-next-line no-undef
  const options = useSelector((state) =>
    _.get(state, `selectDataReducer.${reduxKey}`, [])
  );

  const handleChangeSelected = (item, triggeredAction) => {
    if (triggeredAction.action === 'clear') {
      setSelectedObject();
      onSelect();
    }
    setSelectedObject(item);
    onSelect && onSelect(item.id);
  };

  useEffect(() => {
    if(showModal){
    GearService.all({gear_class_id: gearClassId, "no-pagination": true,}).then((response) => {
      dispatch(addSelectData({[reduxKey]: response.data?.data}));
    });
  }}, [dispatch, gearClassId, reduxKey, showModal]);

  useEffect(() => {
    const foundItem = Object.values(options)?.find(
      (item) => item.id === selected
    );
    setSelectedObject(foundItem ? foundItem : null);
  }, [selected, options]);

  return (
    <Select
      isClearable={isClearable}
      isDisabled={disabled}
      getOptionValue={(option) => option.name}
      menuPortalTarget={document.body}
      styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
      isSearchable={true}
      options={options}
      onChange={handleChangeSelected}
      value={selectedObject}
      getOptionLabel={(gearType) => (
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <span style={{marginLeft: 5}}>
            {language === "al" ? gearType.name : gearType.name_eng}
          </span>
        </div>
      )}
    />
  );
};

export default GearTypeSelect;
