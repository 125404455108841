import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import EditPortType from "@components/PortType/EditPortType";
import PortTypeService from "@services/PortTypeService";
import ShowPortType from "@components/PortType/ShowPortType";

const PortTypeActions = ({ portType, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowPortType portType={portType} />
      </div>
      <div className={"mr-2"}>
        <EditPortType portType={portType} onEdited={onEdited} />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={PortTypeService}
          id={portType.id}
          onDeleted={onDeleted}
          permission={"delete_port_type"}
        />
      </div>
    </div>
  );
};

export default PortTypeActions;
