import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import VesselTypeFFFAOService from "@services/VesselTypeFFFAOService";
import ShowVesselTypeFFFAO from "@components/VesselTypeFFFAO/ShowVesselTypeFFFAO";
import EditVesselTypeFFFAO from "@components/VesselTypeFFFAO/EditVesselTypeFFFAO";

const VesselTypeFFFAOActions = ({ vesselTypeFFFAO, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowVesselTypeFFFAO vesselTypeFFFAO={vesselTypeFFFAO} />
      </div>
      <div className={"mr-2"}>
        <EditVesselTypeFFFAO
          vesselTypeFFFAO={vesselTypeFFFAO}
          onEdited={onEdited}
        />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={VesselTypeFFFAOService}
          id={vesselTypeFFFAO.id}
          onDeleted={onDeleted}
          permission={"delete_vessel_type_fffao"}
        />
      </div>
    </div>
  );
};

export default VesselTypeFFFAOActions;
