import React from "react";
import ShowStatisticalDivision from "@components/StatisticalDivision/ShowStatisticalDivision";
import EditStatisticalDivision from "@components/StatisticalDivision/EditStatisticalDivision";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import StatisticalDivisionService from "@services/StatisticalDivisionService";

const StatisticalDivisionActions = ({
  statisticalDivision,
  onEdited,
  onDeleted,
}) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowStatisticalDivision statisticalDivision={statisticalDivision} />
      </div>
      <div className={"mr-2"}>
        <EditStatisticalDivision
          statisticalDivision={statisticalDivision}
          onEdited={onEdited}
        />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={StatisticalDivisionService}
          id={statisticalDivision.id}
          onDeleted={onDeleted}
          permission={"delete_statistical_division"}
        />
      </div>
    </div>
  );
};

export default StatisticalDivisionActions;
