import API from "@utils/plugins/API";

const GeoSubAreaService = {
  all: (params) => {
    return API.get("geo-sub-areas", { params: params });
  },
  create: (name, name_eng, description, fao_statistical_division_id) => {
    return API.post("geo-sub-areas", {
      name,
      name_eng,
      description,
      fao_statistical_division_id,
    });
  },
  update: (id, name, name_eng, description, fao_statistical_division_id) => {
    return API.put(`geo-sub-areas/${id}`, {
      name,
      name_eng,
      description,
      fao_statistical_division_id,
    });
  },
  find: (id) => {
    return API.get(`geo-sub-areas/${id}`);
  },
  destroy: (id) => {
    return API.delete(`geo-sub-areas/${id}`);
  },
};

export default GeoSubAreaService;
