import AdminAPI from "@utils/plugins/AdminAPI";
import API from "@utils/plugins/API";

const AuthService = {
  login: (username, password) => {
    return AdminAPI.post("login", {
      username,
      password,
    });
  },
  changePassword: (password, password_confirmation) => {
    return API.patch("users/change-my-password", {
      password,
      password_confirmation,
    });
  },
};

export default AuthService;
