import API from "@utils/plugins/API";

const CountryService = {
  all: (params) => {
    return API.get("countries", { params: params });
  },
  create: (
    name,
    name_eng,
    name_frn,
    name_esp,
    description,
    un,
    undp,
    iso_2,
    iso_3,
    gfcm,
    eu,
    med_fisis
  ) => {
    return API.post("countries", {
      name,
      name_eng,
      name_frn,
      name_esp,
      description,
      un,
      undp,
      iso_2,
      iso_3,
      gfcm,
      eu,
      med_fisis,
    });
  },
  update: (
    id,
    name,
    name_eng,
    name_frn,
    name_esp,
    description,
    un,
    undp,
    iso_2,
    iso_3,
    gfcm,
    eu,
    med_fisis
  ) => {
    return API.put(`countries/${id}`, {
      name,
      name_eng,
      name_frn,
      name_esp,
      description,
      un,
      undp,
      iso_2,
      iso_3,
      gfcm,
      eu,
      med_fisis,
    });
  },
  find: (id) => {
    return API.get(`countries/${id}`);
  },
  destroy: (id) => {
    return API.delete(`countries/${id}`);
  },
};

export default CountryService;
