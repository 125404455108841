import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import EquipmentService from "@services/EquipmentService";
import ShowEquipment from "@components/Equipment/ShowEquipment";
import EditEquipment from "@components/Equipment/EditEquipment";

const EquipmentActions = ({ equipment, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowEquipment equipment={equipment} />
      </div>
      <div className={"mr-2"}>
        <EditEquipment equipment={equipment} onEdited={onEdited} />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={EquipmentService}
          id={equipment.id}
          onDeleted={onDeleted}
          permission={"delete_equipment"}
        />
      </div>
    </div>
  );
};

export default EquipmentActions;
