const en = {
  noData: "No Data",
  areYouSure: "Are you sure?",
  areYouSureYouWantToDeleteThisEntity:
    "Are you sure you want to delete this entity?",
  areYouSureYouWantToActivateThisUser:
    "Are you sure you want to activate this user?",
  areYouSureYouWantToDeactivateThisUser:
    "Are you sure you want to deactivate this user?",
  areYouSureYouWantToSealThisCensus:
    "Are you sure you want to seal this census?",
  areYouSureYouWantToReopenThisCensus:
    "Are you sure you want to reopen this census?",
  areYouSureYouWantToRestore: "Are you sure you want to restore this census?",
  deletedSuccessfully: "Deleted Successfully",
  entity: "Entity",
  fleetRegisterTitle: "Fleet register",
  logout: "Logout",
  activate: "Activate",
  deactivate: "Deactivate",
  user: "User",
  users: "Users",
  censuses: "Censuses",
  census: "Census",
  fleet: "Fleet",
  role: "Role",
  roles: "Roles",
  pleaseWait: "Please Wait...",
  signInToYourAccount: "Sign in to your account",
  signIn: "Sign In",
  email: "Email",
  password: "Password",
  name: "Name",
  description: "Description",
  createdAt: "Created At",
  deleteAt: "Deleted At",
  updatedAt: "Updated At",
  actions: "Actions",
  rolesList: "Roles List",
  submit: "Submit",
  manage: "Manage",
  allPermissions: "All Permissions",
  selectedPermissions: "Selected Permissions",
  add: "Add",
  remove: "Remove",
  viewRole: "View Role",
  assignARole: "Assign a role",
  roleCreatedSuccessfully: "Role created successfully!",
  roleEditedSuccessfully: "Role edited successfully!",
  edit: "Edit",
  view: "View",
  permissions: "Permissions",
  confirm: "Confirm",
  editUserPassword: "Edit user password",
  passwordUpdatedSuccessfully: "Password updated successfully",
  changePassword: "Change Password",
  newPassword: "New Password",
  confirmPassword: "Confirm Password",
  deactivateUser: "Deactivate User",
  activateUser: "Activate User",
  statusUpdate: "Status updated successfully",
  userCreatedSuccessfully: "User created successfully!",
  userEditedSuccessfully: "User edited successfully!",
  userName: "User Name",
  userEmail: "User Email",
  status: "Status",
  userList: "Users List",
  editUser: "Edit User",
  editRole: "Edit Role",
  overview: "Overview",
  search: "Search...",
  close: "Close",
  delete: "Delete",
  censusList: "Censuses List",
  censusName: "Census Name",
  censusCreatedSuccessfully: "Census created successfully!",
  censusEditedSuccessfully: "Census edited successfully!",
  seal: "Seal",
  sealedAt: "Sealed at",
  reopen: "Reopen",
  closingReason: "Closing Reason",
  clone: "Clone",
  censusClonedSuccessfully: "Census cloned successfully!",
  closedCensuses: "Closed Censuses",
  closedCensusesList: "Closed Censuses List",
  restoreCensus: "Restore Census",
  censusSealedSuccessfully: "Census sealed successfully",
  censusReopenedSuccessfully: "Census reopened successfully!",
  censusRestoredSuccessfully: "Census restored successfully!",
  cloneCensus: "Clone Census",
  reopenCensus: "Reopen Census",
  sealCensus: "Seal Census",
  editCensus: "Edit Census",
  configurations: "Configurations",
  sealingUser: "Sealing user",
  creatingUser: "Creating User",
  nameEng: "Name ENG",
  fishingZone: "Fishing zone",
  fishingZones: "Fishing zones",
  addFishingZone: "Add fishing zone",
  editFishingZone: "Edit fishing zone",
  fishingZonesList: "Fishing zones list",
  fishingZoneCreatedSuccessfully: "Fishing zone created successfully",
  fishingZoneEditedSuccessfully: "Fishing zone edited successfully",
  authorizationTypesList: "Authorization Types List",
  addAuthorizationType: "Add Authorization type",
  authorizationTypes: "Authorization types",
  authorizationType: "Authorization type",
  authorizationTypeCreatedSuccessfully:
    "Authorization type created successfully",
  authorizationTypeEditedSuccessfully: "Authorization type edited successfully",
  editAuthorizationType: "Edit authorization type",
  issuingOffices: "Issuing offices",
  issuingOfficesList: "Issuing offices list",
  addIssuingOffice: "Add issuing office",
  issuingOfficeCreatedSuccessfully: "Issuing office added successfully",
  issuingOfficeEditedSuccessfully: "Issuing office edited successfully",
  editIssuingOffice: "Edit issuing office",
  strata: "Stratum",
  stratas: "Strata",
  stratasList: "Stratas list",
  addStrata: "Add strata",
  strataCreatedSuccessfully: "Strata created successfully",
  editStrata: "Edit strata",
  strataEditedSuccessfully: "Strata edited successfully",
  portTypes: "Port types",
  portTypesList: "Port types list",
  portTypeCreatedSuccessfully: "Port type created successfully",
  portTypeEditedSuccessfully: "Port type edited successfully",
  editPortType: "Edit port type",
  chooseStratum: "Choose Stratum",
  subStratas: "Substratas",
  subStratasList: "Substratas List",
  addSubStrata: "Add Substrata",
  subStrataCreatedSuccessfully: "Substrata created successfully",
  editSubStrata: "Edit substrata",
  subStrataEditedSuccessfully: "Substrata edited successfully",
  strataName: "Strata Name",
  strataNameEng: "Strata Name ENG",
  strataDescription: "Strata Description",
  minorStratas: "Minor Stratas",
  minorStratasList: "Minor stratas list",
  addMinorStratum: "Add minor stratum",
  minorStrataCreatedSuccessfully: "Minor strata created successfully",
  editMinorStratum: "Edit minor stratum",
  minorStrataEditedSuccessfully: "Minor strata edited successfully",
  subStrataName: "Substrata name",
  subStrataNameEng: "Substrata name ENG",
  subStrataDescription: "Substrata description",
  chooseSubStratum: "Choose Substratum",
  inactivityReasons: "Inactivity reasons",
  inactivityReasonsList: "Inactivity reasons list",
  addInactivityReason: "Add inactivity reason",
  inactivityReasonCreatedSuccessfully: "Inactivity reason created successfully",
  editInactivityReason: "Edit inactivity reason",
  inactivityReasonEditedSuccessfully: "Inactivity reason edited successfully",
  chooseCensus: "Choose Census",
  captainciesList: "Port authorities List",
  captaincies: "Port authorities",
  addCaptaincy: "Add port authority",
  captaincyCreatedSuccessfully: "Port authority created successfully",
  editCaptaincy: "Edit port authority",
  captaincyEditedSuccessfully: "Port authority edited successfully",
  vesselsList: "Vessels List",
  addVessel: "Add vessel",
  vesselCreatedSuccessfully: "Vessel created successfully",
  editVessel: "Edit Vessel",
  vesselEditedSuccessfully: "Vessel edited successfully",
  uniqueRegistrationNumber: "Unique Registration Number",
  characteristics: "Characteristics",
  vesselName: "Vessel Name",
  registrationNumber: "Registration Number",
  fisheryDepartmentRegistrationNumber: "Fishery Department Registration Number",
  vesselType: "Vessel Type",
  homePort: "Home Port",
  operationalStatus: "Operational Status",
  length: "Length",
  gt: "GT",
  grt: "GRT",
  gts: "GTS",
  power: "Power",
  subArea: "Subarea",
  subAreas: "Subareas",
  subAreasList: "Subareas List",
  addSubArea: "Add Subarea",
  subAreaCreatedSuccessfully: "Subarea created successfully",
  editSubArea: "Edit subarea",
  subAreaEditedSuccessfully: "Subarea edited successfully",
  width: "Width",
  depth: "Depth",
  constructionCountry: "Construction country",
  chooseConstructionCountry: "Choose construction country",
  constructionYear: "Construction year",
  hullMaterial: "Hull material",
  chooseHullMaterial: "Choose hull material",
  decked: "Decked",
  authorization: "Authorization",
  detarRegistrationNumber: "DETAR registration number",
  chooseAuthorizationType: "Choose authorization type",
  issuingOffice: "Issuing office",
  chooseIssuingOffice: "Choose issuing office",
  issueDate: "Issue date",
  expiryDate: "Expiry date",
  fisheryEntryDate: "Fishery entry date",
  documentId: "Document ID",
  licenseIndicator: "License indicator",
  gears: "Gears",
  gearsList: "Gears list",
  addGear: "Add gear",
  gearCreatedSuccessfully: "Gear created successfully",
  editGear: "Edit gear",
  gearEditedSuccessfully: "Gear edited successfully",
  matchingCodeGear: "Matching code gear",
  gearStd: "Gear STD",
  gearsEU: "Gears EU",
  rank: "Rank",
  gearClass: "Gear class",
  chooseGearClass: "Choose gear class",
  gearType: "Gear type",
  chooseGearType: "Choose gear type",
  targetGroupOfSpecies: "Target group of species",
  chooseTargetGroupOfSpecies: "Choose target group of species",
  externalMarking: "External marking",
  flag: "Flag",
  chooseFlag: "Choose flag",
  chooseOriginalFlag: "Choose original flag",
  registrationOffice: "Registration office",
  registrationDate: "Registration date",
  originalName: "Original name",
  originalRegistrationNumber: "Original registration number",
  originalDeletionNumber: "Original deletion number",
  originalFlag: "Original flag",
  nonOperationalReason: "Non operational reason",
  remarks: "Remarks",
  engines: "Engines",
  addEngine: "Add engine",
  model: "Model",
  construction: "Construction",
  chooseConstruction: "Choose construction",
  location: "Location",
  chooseLocation: "Choose location",
  energySource: "Energy source",
  chooseEnergySource: "Choose energy source",
  electronics: "Electronics",
  equipment: "Equipment",
  equipments: "Equipments",
  equipmentList: "Equipment list",
  addEquipment: "Add equipment",
  equipmentCreatedSuccessfully: "Equipment created successfully",
  editEquipment: "Edit equipment",
  equipmentEditedSuccessfully: "Equipment edited successfully",
  equipmentCategory: "Equipment category",
  equipmentCategoryName: "Equipment category name",
  equipmentCategoryNameEng: "Equipment category name ENG",
  equipmentCategoryDescription: "Equipment category description",
  chooseEquipmentCategory: "Choose equipment category",
  equipmentType: "Equipment type",
  chooseEquipmentType: "Choose equipment type",
  purchaseYear: "Purchase year",
  quantity: "Quantity",
  capacity: "Capacity",
  capacityUnit: "Capacity unit",
  capacityUnits: "Capacity units",
  capacityUnitsList: "Capacity units list",
  addCapacityUnit: "Add capacity unit",
  capacityUnitCreatedSuccessfully: "Capacity unit created successfully",
  editCapacityUnit: "Edit capacity unit",
  capacityUnitEditedSuccessfully: "Capacity unit edited successfully",
  chooseMeasurementUnit: "Choose measurement unit",
  chooseCapacityUnit: "Choose capacity unit",
  deckMachinery: "Deck machinery",
  gearActivationType: "Gear activation type",
  chooseGearActivationMethod: "Choose gear activation method",
  gearActivationMethodsList: "Gear activation methods list",
  gearActivationMethods: "Gear activation methods",
  gearActivationMethod: "Gear activation method",
  addGearActivationMethod: "Add gear activation method",
  gearActivationMethodCreatedSuccessfully:
    "Gear activation method created successfully",
  editGearActivationMethod: "Edit gear activation method",
  gearActivationMethodEditedSuccessfully:
    "Gear activation method edited successfully",
  winch: "Winch",
  addWinch: "Add winch",
  winchTypes: "Winch types",
  winchType: "Winch type",
  winchTypesList: "Winch types list",
  chooseWinchType: "Choose winch type",
  addWinchType: "Add winch type",
  winchTypeCreatedSuccessfully: "Winch type created successfully",
  editWinchType: "Edit winch type",
  winchTypeEditedSuccessfully: "Winch type edited successfully",
  operatedBy: "Operated by",
  chooseWinchOperationType: "Choose winch operation type",
  winchOperationsList: "Winch operations list",
  winchOperations: "Winch operations",
  winchOperationTypes: "Winch operation types",
  addWinchOperation: "Add winch operation",
  winchOperationCreatedSuccessfully: "Winch operation created successfully",
  editWinchOperation: "Edit winch operation",
  winchOperationEditedSuccessfully: "Winch operation edited successfully",
  ownership: "Ownership",
  owners: "Owners",
  addOwner: "Add owner",
  chooseOwner: "Choose owner",
  crew: "Crew",
  minimumNumber: "Minimal number",
  maximumNumber: "Maximal Number",
  fullTimeNumber: "Full time number",
  seasonalOrPartTimeNumber: "Seasonal or part time number",
  femaleNumber: "Female number",
  ports: "Ports",
  addPort: "Add port",
  portCreatedSuccessfully: "Port created successfully",
  editPort: "Edit port",
  portEditedSuccessfully: "Port edited successfully",
  port: "Port",
  beginMonth: "Begin month",
  endMonth: "End month",
  chooseMonth: "Choose month",
  operations: "Operations",
  tripsPerMonth: "Trips per month",
  addOperation: "Add operation",
  chooseFishingZone: "Choose fishing zone",
  geoSubArea: "Geo sub area",
  chooseGeoSubArea: "Choose geo sub area",
  pollutionPrevention: "Pollution prevention",
  oilResidueDisposal: "Oil residue disposal",
  otherResidueDisposal: "Other residue disposal",
  chooseDisposalType: "Choose disposal type",
  goBack: "Go back",
  back: "Back",
  next: "Next",
  chooseMaterial: "Choose material",
  chooseCountry: "Choose country",
  chooseOffice: "Choose office",
  chooseReason: "Choose reason",
  chooseStatus: "Choose status",
  chooseType: "Choose type",
  chooseClass: "Choose class",
  chooseCategory: "Choose category",
  chooseMethod: "Choose method",
  chooseZone: "Choose zone",
  chooseTargetGroup: "Choose target group",
  chooseSource: "Choose source",
  class: "Class",
  type: "Tipi",
  structuralCharacteristics: "Structural characteristics",
  originalCharacteristics: "Original Characteristics",
  operationalInformation: "Operational Information",
  chooseOperationalStatus: "Choose operational status",
  chooseRegistrationOffice: "Choose registration office",
  chooseNonOperationalReason: "Choose non operational reason",
  chooseVesselType: "Choose vessel type",
  choosePort: "Choose port",
  emoNumber: "IMO Number",
  VMSIndicator: "VMS Indicator ",
  VMSId: "VMS Id",
  ERSIndicator: "ERS Indicator",
  ERSId: "ERS Id",
  totalVessels: "Total vessels",
  vesselTypes: "Vessel types",
  totalEnginePower: "Total engine power",
  crewMembers: "Crew members",
  totalPorts: "Total ports",
  totalEngines: "Total engines",
  grossTonageTotal: "Gross tonage total",
  seasonalPartTimeNumber: "Seasonal / Part Time Number",
  countries: "Countries",
  countriesList: "Countries List",
  addCountry: "Add Country",
  countryCreatedSuccessfully: "Country created successfully",
  editCountry: "Edit country",
  countryEditedSuccessfully: "Country edited successfully",
  unitedNation: "United Nation",
  unitedNationDp: "United Nation Development Program",
  iso2: "Iso2",
  iso3: "Iso3",
  europeanUnion: "European Union",
  gfcm: "General Fisheries Commission for the Mediterranean",
  nameFrn: "French Name",
  nameEsp: "Spanish Name",
  medFisis: "MedFisis",
  operationalStatuses: "Operational Statuses",
  operationalStatusesList: "Operational Statuses List",
  addOperationalStatus: "Add operational status",
  operationalStatusCreatedSuccessfully:
    "Operational Status created successfully",
  editOperationalStatus: "Edit operational status",
  operationalStatusEditedSuccessfully: "Operational Status edited successfully",
  statisticalDivision: "Statistical Division",
  statisticalDivisions: "Statistical Divisions",
  statisticalDivisionsList: "Statistical Divisions List",
  addStatisticalDivision: "Add statistical division",
  statisticalDivisionCreatedSuccessfully:
    "Statistical Division created successfully",
  editStatisticalDivision: "Edit statistical division",
  statisticalDivisionEditedSuccessfully:
    "Statistical division edited successfully",
  subAreaName: "Subarea Name",
  subAreaNameEng: "Subarea NameENG",
  subAreaDescription: "SubArea Description",
  exportExcel: "Export Excel",
  exportOld: "Export Old",
  exportPDF: "Export PDF",
  numericalStatistics: "Numerical Statistics",
  portsList: "Ports list",
  latitude: "Latitude",
  longitude: "Longitude",
  portType: "Port type",
  portCode: "Port code",
  portCodeEU: "Port code EU",
  minorStrataName: "Minor strata name",
  minorStrataNameEng: "Minor strata name ENG",
  minorStrataDescription: "Minor strata description",
  geoSubAreaName: "Geo sub area name",
  geoSubAreaNameEng: "Geo sub area name ENG",
  geoSubAreaDescription: "Geo sub area description",
  portTypeName: "Port type name",
  portTypeNameEng: "Port type name ENG",
  portTypeDescription: "Port type description",
  addPortType: "Add Port Type",
  gearClasses: "Gear classes",
  gearClassesList: "Gear classes list",
  addGearClass: "Add gear class",
  gearClassAddedSuccessfully: "Gear class added successfully",
  editGearClass: "Edit gear class",
  gearClassEditedSuccessfully: "Gear class edited successfully",
  groupOfSpeciesGFCM: "Group of Species GFCM",
  groupOfSpeciesGFCMList: "Group of Species GFCM list",
  addGroupOfSpeciesGFCM: "Add group of species GFCM",
  editGroupOfSpeciesGFCM: "Edit group of species GFCM",
  groupOfSpeciesGFCMEditedSuccessfully:
    "Group of Species GFCM edited successfully",
  groupOfSpeciesGFCMECreatedSuccessfully:
    "Group of Species GFCM created successfully",
  hullMaterials: "Hull materials",
  hullMaterialsList: "Hull materials list",
  addHullMaterial: "Add hull materials",
  hullMaterialAdded: "Hull material added",
  editHullMaterial: "Edit hull material",
  hullMaterialEditedSuccessfully: "Hull material edited successfully",
  hullMaterialEUName: "Hull material EU name",
  hullMaterialEUNameEng: "Hull material EU name ENG",
  hullMaterialEUDescription: "Hull material EU description",
  hullMaterialEU: "Hull materials EU",
  hullMaterialsEU: "Hull materials EU",
  hullMaterialsEUList: "Hull materials EU list",
  addHullMaterialEU: "Add hull material EU",
  hullMaterialEUAdded: "Hull material EU added",
  editHullMaterialEU: "Edit hull material EU",
  hullMaterialEUEditedSuccessfully: "Hull material EU edited successfully",
  energySources: "Energy sources",
  energySourcesList: "Energy sources list",
  addEnergySource: "Add energy source",
  energySourceAddedSuccessfully: "Energy source added successfully",
  editEnergySource: "Edit energy source",
  energySourceEditedSuccessfully: "Energy source edited successfully",
  groupOfSpecies: "Group of Species",
  groupOfSpeciesList: "Group of Species list",
  addGroupOfSpecies: "Add group of species",
  editGroupOfSpecies: "Edit group of species",
  groupOfSpeciesEditedSuccessfully: "Group of Species edited successfully",
  groupOfSpeciesCreatedSuccessfully: "Group of Species created successfully",
  groupOfSpeciesGFCMName: "Group of Species GFCM Name",
  groupOfSpeciesGFCMNameEng: "Group of Species GFCM Name Eng",
  groupOfSpeciesGFCMDescription: "Group of Species GFCM Description",
  equipmentCategories: "Equipment categories",
  equipmentCategoriesList: "Equipment categories list",
  addEquipmentCategory: "Add equipment category",
  equipmentCategoryAddedSuccessfully: "Equipment category added successfully",
  editEquipmentCategory: "Edit equipment category",
  equipmentCategoryEditedSuccessfully: "Equipment category edited successfully",
  engineLocations: "Engine locations",
  addEngineLocation: "Add engine location",
  engineLocationAddedSuccessfully: "Engine location added successfully",
  editEngineLocation: "Edit engine location",
  engineLocationEditedSuccessfully: "Engine location edited successfully",
  engineLocationsList: "Engine locations list",
  vesselTypesList: "Vessel Types List",
  addVesselType: "Add Vessel Type",
  vesselTypeAddedSuccessfully: "Vessel Type added successfully",
  editVesselType: "Edit Vessel Type",
  vesselTypeEditedSuccessfully: "Vessel Type edited successfully",
  vesselTypesFFFAO: "Vessel Types FFFAO",
  vesselTypeFFFAOList: "Vessel Types FFFAO List",
  addVesselTypeFFFAO: "Add Vessel Type FFFAO",
  vesselTypeFFFAOAddedSuccessfully: "Vessel Type FFFAO added successfully",
  editVesselTypeFFFAO: "Edit Vessel Type FFFAO",
  vesselTypeFFFAOEditedSuccessfully: "Vessel Type FFFAO edited successfully",
  vesselTypeFFFAOName: "Vessel Type FFFAO Name",
  stdAbbreviation: "STD Abbreviation",
  minorStratum: "Minor stratum",
  disposalTypes: "Disposal Types",
  disposalTypesList: "Disposal Types List",
  addDisposalType: "Add disposal type",
  disposalTypeCreatedSuccessfully: "Disposal Type created successfully",
  editDisposalType: "Edit Disposal Type",
  disposalTypeEditedSuccessfully: "Disposal Type edited successfully",
  geoSubAreas: "Geo Sub Areas",
  geoSubAreasList: "Geo Sub Areas List",
  addGeoSubArea: "Add Geo Sub Area",
  geoSubAreaCreatedSuccessfully: "Geo Sub Area created successfully",
  editGeoSubArea: "Edit Geo sub area",
  geoSubAreaEditedSuccessfully: "Geo Sub Area edited successfully",
  statisticalDivisionName: "Statistical Division name",
  statisticalDivisionNameEng: "Statistical Division name eng",
  statisticalDivisionDescription: "Statistical Division description",
  companyTypes: "Company types",
  companyType: "Company type",
  companyTypesList: "Company types list",
  addCompanyType: "Add company type",
  companyTypeCreatedSuccessfully: "Company type created successfully",
  editCompanyType: "Edit company type",
  companyTypeEditedSuccessfully: "Company type edited successfully",
  ownersList: "Owners List",
  ownerCreatedSuccessfully: "Owner created successfully",
  editOwner: "Edit Owner",
  ownerEditedSuccessfully: "Owner edited successfully",
  companyName: "Company Name",
  establishedYear: "Established Year",
  address: "Address",
  postcode: "Postcode",
  town: "Town",
  region: "Region",
  country: "Country",
  gearEU: "Gear EU",
  gearsEUList: "Gears EU List",
  addGearEU: "Add Gear EU",
  gearEUCreatedSuccessfully: "Gear EU created successfully",
  editGearEU: "Edit Gear EU",
  gearEUEditedSuccessfully: "Gear EU edited successfully",
  fishingTechniqueEU: "Fishing technique EU",
  fishingTechniquesEU: "Fishing techniques EU",
  fishingTechniquesEUList: "Fishing techniques EU List",
  addFishingTechniqueEU: "Add fishing technique EU",
  fishingTechniqueEUCreatedSuccessfully:
    "Fishing technique EU created successfully",
  editFishingTechniqueEU: "Edit fishing technique EU",
  fishingTechniqueEUEditedSuccessfully:
    "Fishing technique EU edited successfully",
  marineFisherySubSector: "Marine fishery sub sector",
  marineFisherySubSectors: "Marine fishery sub sectors",
  addMarineFisherySubSector: "Add marine fishery sub sector",
  marineFisherySubSectorCreatedSuccessfully:
    "Marine fishery sub sector created successfully",
  editMarineFisherySubSector: "Edit marine fishery sub sector",
  marineFisherySubSectorEditedSuccessfully:
    "Marine fishery sub sector edited successfully",
  marineFisherySubSectorsList: "Marine fishery sub sectors list",
  MMSIIndicator: "MMSI Indicator ",
  MMSIId: "MMSI Id",
  searchOwner: "Search Owner",
  fleetSegmentationsList: "Fleet Segmentations List",
  addFleetSegmentations: "Add Fleet Segmentations",
  fleetSegmentationsCreatedSuccessfully:
    "Fleet Segmentation created successfully",
  editFleetSegmentations: "Edit Fleet Segmentations",
  fleetSegmentationsEditedSuccessfully:
    "Fleet Segmentations edited successfully",
  fleetSegmentations: "Fleet Segmentations",
  distributionOfEquipment: "Distribution of Equipment",
  distributionByPortVesselType: "Distribution by Port Vessel Type",
  distributionByVesselTypePort: "Distribution by Vessel Port Type",
  distributionOfVesselsByVesselType: "Distribution Of Vessels By Vessel Type",
  distributionOfVesselsByMinorStratum: "Distribution Of Vessels By MinorStratum",
  distributionOfVesselsByTypeLength: "Distribution Of Vessels By Type Length",
  basicNumericalStatistics: "Basic Numerical Statistics",
  distributionOfVesselsByPort: "Distribution Of Vessels By Port",
  distributionOfVesselByPowerVesselType: "Distribution Of Vessel By Power",
  simpleSearch: "Simple Search",
  createUserName: "Create User Name",
  casInclusions: "Cas Inclusions",
  lengthMin: "Minimum Length",
  lengthMax: "Maximum Length",
  gtMin: "Minimum Gt",
  gtMax: "Maximum Gt",
  powerMin: "Minimum Power",
  powerMax: "Maximum Power",
  constructionYearMin: "Minimum Construction Year",
  constructionYearMax: "Maximum Construction Year",
  searchGear: "Search Gear",
  resetFilters: "Reset Filters",
  advancedSearch: "Advanced Search",
  allPdfReport: 'All PDF',
  exportEuropeanHake: 'Export European Hake',
  exportSmallPelagic: 'Export Small Pelagic',
  exportVesselRecording: 'Export Vessel Recording',
  exportOver15m: 'Export Over 15m',
  exportUnder15m: 'Export Under 15m',
  exportSearch: 'Export Search'
};

export default en;
