import React from "react";
import Layout from "@hoc/layouts/Layout";
import StatisticalDivisionsIndex from "@components/StatisticalDivision/StatisticalDivisionsIndex";

const StatisticalDivisionsPage = () => {
  return (
    <Layout>
      <StatisticalDivisionsIndex />
    </Layout>
  );
};

export default StatisticalDivisionsPage;
