import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import ShowSubStrata from "@components/SubStrata/ShowSubStrata";
import EditSubStrata from "@components/SubStrata/EditSubStrata";
import SubStrataService from "@services/SubStrataService";

const SubStrataActions = ({ subStrata, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowSubStrata subStrata={subStrata} />
      </div>
      <div className={"mr-2"}>
        <EditSubStrata subStrata={subStrata} onEdited={onEdited} />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={SubStrataService}
          id={subStrata.id}
          onDeleted={onDeleted}
          permission={"delete_sub_stratum"}
        />
      </div>
    </div>
  );
};

export default SubStrataActions;
