import API from "@utils/plugins/API";

const CensusService = {
  all: (params) => {
    return API.get("censuses", { params });
  },
  create: (name) => {
    return API.post("censuses", {
      name,
    });
  },
  update: (id, name) => {
    return API.put(`censuses/${id}`, {
      name,
    });
  },
  find: (id) => {
    return API.get(`censuses/${id}`);
  },
  destroy: (id) => {
    return API.delete(`censuses/${id}`);
  },
  clone: (id, name) => {
    return API.post(`censuses/${id}/copy`, {
      name,
    });
  },
  seal: (id, closed_reason) => {
    return API.put(`censuses/${id}/seal`, {
      closed_reason,
    });
  },
  restore: (id) => {
    return API.put(`censuses/${id}/restore`);
  },
  reopen: (id) => {
    return API.put(`censuses/${id}/reopen`, {});
  },
};
export default CensusService;
