import API from "@utils/plugins/API";

const GearService = {
  all: (params) => {
    return API.get("gears", { params: params });
  },
  create: (
    name,
    name_eng,
    description,
    matching_code_gear,
    gear_eu_id,
    gear_class_id,
    gear_std,
    fishing_technique_eu_id,
    marine_fishery_subsector_id
  ) => {
    return API.post("gears", {
      name,
      name_eng,
      description,
      matching_code_gear,
      gear_eu_id,
      gear_class_id,
      gear_std,
      fishing_technique_eu_id,
      marine_fishery_subsector_id,
    });
  },
  update: (
    id,
    name,
    name_eng,
    description,
    matching_code_gear,
    gear_eu_id,
    gear_class_id,
    gear_std,
    fishing_technique_eu_id,
    marine_fishery_subsector_id
  ) => {
    return API.put(`gears/${id}`, {
      name,
      name_eng,
      description,
      matching_code_gear,
      gear_eu_id,
      gear_class_id,
      gear_std,
      fishing_technique_eu_id,
      marine_fishery_subsector_id,
    });
  },
  find: (id) => {
    return API.get(`gears/${id}`);
  },
  destroy: (id) => {
    return API.delete(`gears/${id}`);
  },
};

export default GearService;
