import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import GroupOfSpeciesGFCMService from "@services/GroupOfSpeciesGFCMService";
import EditGroupOfSpeciesGFCM from "@components/GroupOfSpeciesGFCM/EditGroupOfSpeciesGFCM";
import ShowGroupOfSpeciesGFCM from "@components/GroupOfSpeciesGFCM/ShowGroupOfSpeciesGFCM";

const GroupOfSpeciesGFCMActions = ({
  groupOfSpeciesGFCMA,
  onEdited,
  onDeleted,
}) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowGroupOfSpeciesGFCM groupOfSpeciesGFCMA={groupOfSpeciesGFCMA} />
      </div>
      <div className={"mr-2"}>
        <EditGroupOfSpeciesGFCM
          groupOfSpeciesGFCMA={groupOfSpeciesGFCMA}
          onEdited={onEdited}
        />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={GroupOfSpeciesGFCMService}
          id={groupOfSpeciesGFCMA.id}
          onDeleted={onDeleted}
          permission={"delete_group_of_species_gfcm"}
        />
      </div>
    </div>
  );
};

export default GroupOfSpeciesGFCMActions;
