import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import MarineFisherySubsectorService from "@services/MarineFisherySubsectorService";
import ShowMarineFisherySubsector from "@components/MarineFisherySubsector/ShowMarineFisherySubsector";
import EditMarineFisherySubsector from "@components/MarineFisherySubsector/EditMarineFisherySubsector";

const MarineFisherySubsectorActions = ({
  marineFisherySubsector,
  onEdited,
  onDeleted,
}) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowMarineFisherySubsector
          marineFisherySubsector={marineFisherySubsector}
        />
      </div>
      <div className={"mr-2"}>
        <EditMarineFisherySubsector
          marineFisherySubsector={marineFisherySubsector}
          onEdited={onEdited}
        />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={MarineFisherySubsectorService}
          id={marineFisherySubsector.id}
          onDeleted={onDeleted}
          permission={"delete_marine_fishery_sub_sector"}
        />
      </div>
    </div>
  );
};

export default MarineFisherySubsectorActions;
