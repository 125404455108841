import React from "react";
import { useTranslation } from "react-i18next";
import Input from "@core/inputs/Input";
import "react-datepicker/dist/react-datepicker.css";
import ApiSelect from "@core/dropdowns/ApiSelect";
import CaptaincyService from "@services/CaptaincyService";
import VesselTypeService from "@services/VesselTypeService";
import OperationalStatusService from "@services/OperationalStatusService";
import InactivityReasonService from "@services/InactivityReasonService";
import PortService from "@services/PortService";
import CDefaultDatePicker from "@core/inputs/dateTime/CDefaultDatePicker";
import NumericalInput from "@core/inputs/NumericalInput";

const VesselCharacteristics = ({
  disabled = false,
  data,
  onChange,
  onShow = false,
  required = true,
  isClearable = false,
}) => {
  const { t } = useTranslation();

  const handleChange = (value, name) => {
    onChange({ ...data, [name]: value });
  };

  return (
    <>
      <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-x-5 gap-y-2">
        {onShow && (
          <Input
            required={required}
            disabled={disabled}
            height="h-10"
            label={t("uniqueRegistrationNumber")}
            placeholder={t("uniqueRegistrationNumber")}
            value={data?.unique_registration_number}
            onChange={(val) => handleChange(val, "unique_registration_number")}
          />
        )}
        <Input
          required={required}
          disabled={disabled}
          height="h-10"
          label={t("registrationNumber")}
          placeholder={t("registrationNumber")}
          value={data?.registration_number}
          onChange={(val) => handleChange(val, "registration_number")}
        />
        <Input
          disabled={disabled}
          height="h-10"
          label={t("externalMarking")}
          placeholder={t("externalMarking")}
          value={data?.external_marking}
          onChange={(val) => handleChange(val, "external_marking")}
        />
        <NumericalInput
          disabled={disabled}
          height="h-10"
          label={t("emoNumber")}
          placeholder={t("emoNumber")}
          value={data?.emo_number}
          onChange={(val) => handleChange(val, "emo_number")}
        />
        <div>
          <div className="text-sm font-semibold mb-2 flex">
            {t("registrationOffice")}
            {required && <div className="text-red-600 ml-1">*</div>}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={"captaincy"}
            disabled={disabled}
            placeholder={t("chooseRegistrationOffice")}
            service={CaptaincyService}
            onSelect={(val) => handleChange(val, "registration_office_id")}
            selected={data?.registration_office_id}
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2 flex">
            {t("registrationDate")}
            {required && <div className="text-red-600 ml-1">*</div>}
          </div>
          <CDefaultDatePicker
            disabled={disabled}
            value={data?.registration_date}
            onChange={(val) => handleChange(val, "registration_date")}
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2 flex">
            {t("vesselType")}
            {required && <div className="text-red-600 ml-1">*</div>}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={"vesselType"}
            disabled={disabled}
            service={VesselTypeService}
            onSelect={(val) => handleChange(val, "vessel_type_id")}
            selected={data?.vessel_type_id}
          />
        </div>
        <Input
          required={required}
          disabled={disabled}
          height="h-10"
          label={t("name")}
          placeholder={t("name")}
          value={data?.name}
          onChange={(val) => handleChange(val, "name")}
        />
      </div>
      <div className="my-6 font-bold text-lg">
        {t("originalCharacteristics")}
      </div>
      <div className="mb-2 grid grid-cols-2 gap-x-5 gap-y-2">
        <Input
          disabled={disabled}
          height="h-10"
          label={t("originalName")}
          placeholder={t("originalName")}
          value={data?.original_name}
          onChange={(val) => handleChange(val, "original_name")}
        />
        <Input
          disabled={disabled}
          height="h-10"
          label={t("originalRegistrationNumber")}
          placeholder={t("originalRegistrationNumber")}
          value={data?.original_registration_number}
          onChange={(val) => handleChange(val, "original_registration_number")}
        />
        <Input
          disabled={disabled}
          height="h-10"
          label={t("originalDeletionNumber")}
          placeholder={t("originalDeletionNumber")}
          value={data?.original_deletion_details}
          onChange={(val) => handleChange(val, "original_deletion_details")}
        />
      </div>
      <div className="my-6 font-bold text-lg">
        {t("operationalInformation")}
      </div>
      <div className="mb-2 grid grid-cols-2 gap-x-5 gap-y-2">
        <div>
          <div className="text-sm font-semibold mb-2 flex">
            {t("operationalStatus")}
            {required &&  <div className="text-red-600 ml-1">*</div>}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={"operationalStatuses"}
            disabled={disabled}
            service={OperationalStatusService}
            onSelect={(val) => handleChange(val, "operational_status_id")}
            selected={data?.operational_status_id}
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">
            {t("nonOperationalReason")}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={"nonOperationalStatuses"}
            disabled={disabled || data?.operational_status_id === "1"}
            service={InactivityReasonService}
            onSelect={(val) => handleChange(val, "non_operational_reason_id")}
            selected={data?.non_operational_reason_id}
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2 flex">
            {t("homePort")}
            {required &&  <div className="text-red-600 ml-1">*</div>}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={"homePort"}
            disabled={disabled}
            service={PortService}
            onSelect={(val) => handleChange(val, "home_port_id")}
            selected={data?.home_port_id}
          />
        </div>
        <Input
          disabled={disabled}
          height="h-10"
          label={t("remarks")}
          placeholder={t("remarks")}
          value={data?.remarks}
          onChange={(val) => handleChange(val, "remarks")}
        />
      </div>
    </>
  );
};

export default VesselCharacteristics;
