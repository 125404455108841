import React, { useMemo, useState } from "react";
import Datatable from "@core/table/Datatable";
import MobileTable from "@core/table/MobileTable";
import OwnerActions from "@components/Owner/partials/OwnerActions";
import OwnerService from "@services/OwnerService";
import CreateOwner from "@components/Owner/CreateOwner";
import { useTranslation } from "react-i18next";

const OwnersIndex = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [metaData, setMetaData] = useState();
  const [loading, setLoading] = useState(true);
  const [updatedTable, setUpdatedTable] = useState(0);

  const headers = useMemo(
    () => [
      {
        id: "companyName",
        name: t("companyName"),
        cell: (row) => row.company_name,
      },
      {
        id: "companyType",
        name: t("companyType"),
        cell: (row) => row.company_type,
      },
      {
        id: "country",
        name: t("country"),
        cell: (row) => row.country,
      },
      {
        id: "establishedYear",
        name: t("establishedYear"),
        cell: (row) => row.established_year,
      },
      {
        id: "registrationOffice",
        name: t("registrationOffice"),
        cell: (row) => row.registration_office,
      },
      {
        id: "town",
        name: t("town"),
        cell: (row) => row.town,
      },
      {
        id: "actions",
        name: t("actions"),
        cell: (row) => (
          <OwnerActions
            owner={row}
            onEdited={() => setUpdatedTable((prev) => prev + 1)}
            onDeleted={() => setUpdatedTable((prev) => prev + 1)}
          />
        ),
      },
    ],
    [t]
  );

  const getData = (params) => {
    setLoading(true);
    OwnerService.all(params).then((response) => {
      setData(response.data.data);
      setMetaData(response.data.metadata);
      setLoading(false);
    });
  };

  return (
    <div className="border bg-white rounded justify-between items-center mb-5 py-2">
      <div className="mx-3 my-5">
        <label className="text-lg font-bold">{t("ownersList")}</label>
        <div className="flex w-1/2 sm:w-1/4 md:w-1/6 mt-3 mr-2">
          <CreateOwner
            onAdded={() => setUpdatedTable((prev) => prev + 1)}
            label={t("addOwner")}
          />
        </div>
        <div className="block lg:hidden">
          <MobileTable
            headers={headers}
            data={data}
            page={metaData?.currentPage}
            totalRows={metaData?.total}
            pageCount={metaData?.lastPage}
          />
        </div>
        <div className="hidden lg:block">
          <Datatable
            data={data}
            headers={headers}
            totalRows={metaData?.total}
            getData={getData}
            isLoading={loading}
            extraDependencies={[updatedTable]}
          />
        </div>
      </div>
    </div>
  );
};

export default OwnersIndex;
