import React from "react";
import Input from "@core/inputs/Input";
import { Trash } from "tabler-icons-react";
import NumericalInput from "@core/inputs/NumericalInput";
import { useTranslation } from "react-i18next";
import ApiSelect from "@core/dropdowns/ApiSelect";
import MeasurementUnitService from "@services/MeasurementUnitService";
import EquipmentCategoryService from "@services/EquipmentCategoryService";
import EquipmentTypeSelect from "@components/EquipmentType/partials/EquipmentTypeSelect";

const Equipment = ({ data, index, onDelete, onChange, disabled, isClearable }) => {
  const { t } = useTranslation();

  const handleChange = (value, name) => {
    onChange(index, { ...data, [name]: value });
  };

  return (
    <div key={index}>
      <div className="grid grid-cols-4 gap-5">
        <div>
          <div className="text-xs font-semibold mb-2">
            {t("equipmentCategory")}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={"equipment"}
            disabled={disabled}
            service={EquipmentCategoryService}
            selected={data?.equipment_category_id}
            onSelect={(val) => handleChange(val, "equipment_category_id")}
          />
        </div>
        <div>
          <div className="text-xs font-semibold mb-2 ">
            {t("equipmentType")}
          </div>
          <EquipmentTypeSelect
            isClearable={isClearable}
            reduxKey={`equipmentType${index}`}
            disabled={disabled}
            equipmentCategoryId={data?.equipment_category_id}
            selected={data?.equipment_type_id}
            onSelect={(val) => handleChange(val, "equipment_type_id")}
          />
        </div>
        <NumericalInput
          disabled={disabled}
          height="h-10"
          label={t("purchaseYear")}
          placeholder={t("purchaseYear")}
          value={data?.purchase_year}
          onChange={(val) => handleChange(val, "purchase_year")}
          extraClasses={"text-xs"}
        />
        <NumericalInput
          disabled={disabled}
          height="h-10"
          label={t("quantity")}
          placeholder={t("quantity")}
          value={data?.quantity}
          onChange={(val) => handleChange(val, "quantity")}
          extraClasses={"text-xs"}
        />
        <NumericalInput
          disabled={disabled}
          height="h-10"
          label={t("capacity")}
          placeholder={t("capacity")}
          value={data?.capacity}
          onChange={(val) => handleChange(val, "capacity")}
          extraClasses={"text-xs"}
        />
        <div>
          <div className="text-xs font-semibold mb-2">
            {t("capacityUnit")}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={"capacityUnit"}
            disabled={disabled}
            service={MeasurementUnitService}
            selected={data?.capacity_unit_id}
            onSelect={(val) => handleChange(val, "capacity_unit_id")}
          />
        </div>
        <Input
          disabled={disabled}
          height="h-10"
          label={t("remarks")}
          placeholder={t("remarks")}
          value={data?.remarks}
          onChange={(val) => handleChange(val, "remarks")}
          extraClasses={"text-xs"}
        />
        {!disabled && (
          <div className="flex self-center pt-5 flex-start">
            <button
              className="flex text-red-600 cursor-pointer ml-auto"
              onClick={() => onDelete(index)}
            >
              <Trash />
            </button>
          </div>
        )}
      </div>
      <hr className="px-5 my-3" />
    </div>
  );
};

export default Equipment;
