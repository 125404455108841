import React, { useEffect, useState } from "react";
import ViewModal from "@core/modals/ViewModal";
import ViewButton from "@core/buttons/atoms/ViewButton";
import RowDetails from "@hoc/cruds/RowDetails";
import { useTranslation } from "react-i18next";
import PortService from "@services/PortService";
import PermissionHandler from "@hoc/cruds/PermissionHandler";

const ShowPort = ({ port }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [portData, setPortData] = useState([]);

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal)
      PortService.find(port.id).then((response) => {
        setPortData(response.data.data);
      });
  }, [port.id, showModal]);

  return (
    <PermissionHandler permission={"view_ports"}>
      <ViewButton onClick={() => setShowModal(true)} />
      <ViewModal
        show={showModal}
        title={`${t("overview")}`}
        onClose={closeModal}
      >
        <div className="flex flex-col mt-5">
          <RowDetails label={t("name")} value={portData.name} />
          <RowDetails label={t("nameEng")} value={portData.name_eng} />
          <RowDetails label={t("description")} value={portData.description} />
          <RowDetails
            label={t("minorStrataName")}
            value={portData.minor_stratum?.name}
          />
          <RowDetails
            label={t("minorStrataNameEng")}
            value={portData.minor_stratum?.name_eng}
          />
          <RowDetails
            label={t("minorStrataDescription")}
            value={portData.minor_stratum?.description}
          />
          <RowDetails
            label={t("geoSubAreaName")}
            value={portData.geo_sub_area?.name}
          />
          <RowDetails
            label={t("geoSubAreaNameEng")}
            value={portData.geo_sub_area?.name_eng}
          />
          <RowDetails
            label={t("geoSubAreaDescription")}
            value={portData.geo_sub_area?.description}
          />
          <RowDetails
            label={t("portTypeName")}
            value={portData.port_type?.name}
          />
          <RowDetails
            label={t("portTypeNameEng")}
            value={portData.port_type?.name_eng}
          />
          <RowDetails
            label={t("portTypeDescription")}
            value={portData.port_type?.description}
          />
          <RowDetails label={t("latitude")} value={portData.latitude} />
          <RowDetails label={t("longitude")} value={portData.longitude} />
          <RowDetails label={t("portCode")} value={portData.port_code} />
          <RowDetails label={t("portCodeEU")} value={portData.port_code_eu} />
        </div>
      </ViewModal>
    </PermissionHandler>
  );
};

export default ShowPort;
