import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import EquipmentCategoryService from "@services/EquipmentCategoryService";
import { addSelectData } from "@redux/select/Action";
import _ from "lodash";

const EquipmentCategorySelect = ({
  openModal,
  onSelect,
  selected,
  placeholder,
  disabled = false,
}) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const language = i18n.language;
  const [selectedObject, setSelectedObject] = useState();

  // eslint-disable-next-line no-undef
  const options = useSelector((state) =>
    _.get(state, "selectDataReducer.equipmentCategories", [])
  );

  const handleChangeSelected = (item) => {
    setSelectedObject(item);
    onSelect && onSelect(item.id);
  };

  useEffect(() => {
    if (openModal) {
      EquipmentCategoryService.all().then((response) => {
        dispatch(addSelectData({ equipmentCategories: response.data?.data }));
      });
    }
  }, [openModal, dispatch]);

  useEffect(() => {
    if (options) {
      const foundItem = Object.values(options)?.find(
        (item) => item.id === selected
      );
      setSelectedObject(foundItem ? foundItem : null);
    }
  }, [selected, options]);

  return (
    <Select
      isDisabled={disabled}
      getOptionValue={(option) => option.id}
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      isSearchable={false}
      options={options}
      onChange={handleChangeSelected}
      value={selectedObject}
      placeholder={placeholder}
      getOptionLabel={(selectedObject) => (
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          className="text-sm"
        >
          <span style={{ marginLeft: 5 }}>
            {language === "al" ? selectedObject.name : selectedObject.name_eng}
          </span>
        </div>
      )}
    />
  );
};

export default EquipmentCategorySelect;
