import React, { useEffect, useState } from "react";
import ViewModal from "@core/modals/ViewModal";
import ViewButton from "@core/buttons/atoms/ViewButton";
import RowDetails from "@hoc/cruds/RowDetails";
import { useTranslation } from "react-i18next";
import MinorStrataService from "@services/MinorStrataService";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import SubStrataService from "@services/SubStrataService";

const ShowMinorStrata = ({ minorStrata }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [minorStrataData, setMinorStrataData] = useState({});
  const [selectedSubStratum, setSelectedSubStratum] = useState();

  const closeModal = () => {
    setShowModal(false);
    setMinorStrataData([]);
    setSelectedSubStratum();
  };

  useEffect(() => {
    if (showModal)
      MinorStrataService.find(minorStrata.id).then((response) => {
        setMinorStrataData(response.data.data);
      });
  }, [minorStrata.id, showModal]);

  useEffect(() => {
    if (showModal) {
      SubStrataService.all().then((response) => {
        const subStrata = response.data.data.map((subStratum) => {
          return {
            value: subStratum.id,
            ...subStratum,
          };
        });
        const initialSubStratum = subStrata?.find(
          (initialSubStratum) =>
            initialSubStratum.id === minorStrataData.sub_stratum_id
        );
        setSelectedSubStratum(initialSubStratum);
      });
    }
  }, [showModal, minorStrataData.sub_stratum_id]);

  return (
    <PermissionHandler permission={"view_minor_strata"}>
      <ViewButton onClick={() => setShowModal(true)} />
      <ViewModal
        show={showModal}
        title={`${t("overview")}`}
        onClose={closeModal}
      >
        <div className="flex flex-col mt-5">
          <RowDetails label={t("name")} value={minorStrataData.name} />
          <RowDetails label={t("nameEng")} value={minorStrataData.name_eng} />
          <RowDetails
            label={t("description")}
            value={minorStrataData.description}
          />
          <RowDetails
            label={t("subStrataName")}
            value={selectedSubStratum?.name}
          />
          <RowDetails
            label={t("subStrataNameEng")}
            value={selectedSubStratum?.name_eng}
          />
          <RowDetails
            label={t("subStrataDescription")}
            value={selectedSubStratum?.description}
          />
        </div>
      </ViewModal>
    </PermissionHandler>
  );
};

export default ShowMinorStrata;
