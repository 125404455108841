import API from "@utils/plugins/API";

const StrataService = {
  all: (params) => {
    return API.get("strata", { params: params });
  },
  create: (name, name_eng, description) => {
    return API.post("strata", {
      name,
      name_eng,
      description,
    });
  },
  update: (id, name, name_eng, description) => {
    return API.put(`strata/${id}`, {
      name,
      name_eng,
      description,
    });
  },
  find: (id) => {
    return API.get(`strata/${id}`);
  },
  destroy: (id) => {
    return API.delete(`strata/${id}`);
  },
};

export default StrataService;
