import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import AuthorizationTypeService from "@services/AuthorizationTypeService";
import ShowAuthorizationType from "@components/AuthorizationTypes/ShowAuthorizationType";
import EditAuthorizationType from "@components/AuthorizationTypes/EditAuthorizationType";

const AuthorizationTypeActions = ({
  authorizationType,
  onEdited,
  onDeleted,
}) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowAuthorizationType authorizationType={authorizationType} />
      </div>
      <div className={"mr-2"}>
        <EditAuthorizationType
          authorizationType={authorizationType}
          onEdited={onEdited}
        />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={AuthorizationTypeService}
          id={authorizationType.id}
          onDeleted={onDeleted}
          permission={"delete_authorization_type"}
        />
      </div>
    </div>
  );
};

export default AuthorizationTypeActions;
