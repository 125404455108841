import React from "react";
import Layout from "@hoc/layouts/Layout";
import GearsEUIndex from "@components/GearEU/GearsEUIndex";

const GearsEUPage = () => {
  return (
    <Layout>
      <GearsEUIndex />
    </Layout>
  );
};

export default GearsEUPage;
