import React from "react";
import Layout from "@hoc/layouts/Layout";
import EquipmentIndex from "@components/Equipment/EquipmentIndex";

const EquipmentPage = () => {
  return (
    <Layout>
      <EquipmentIndex />
    </Layout>
  );
};

export default EquipmentPage;
