import React from "react";
import {
  FaBuilding,
  FaCog,
  FaAnchor,
  FaDatabase,
  FaFish,
  FaToolbox,
  FaUsers,
  FaUserShield,
  FaRuler,
  FaCity,
  FaBiohazard,
  FaTools,
  FaCogs,
  FaShip,
} from "react-icons/fa";
import {
  GiWorld,
  GiAbstract070,
  GiHarborDock,
  GiIronHulledWarship,
  GiPowerLightning,
  GiBoatFishing,
  GiCargoCrane,
  GiDoubleFish,
  GiFishingPole,
  GiTurbine,
  GiFlatPlatform,
  GiSubmarine,
} from "react-icons/gi";
import logo from "@assets/svg/logo.png";
import euLogo from "@assets/svg/eu_logo.png";
import SidebarItem from "@core/items/SidebarItem";
import SidebarSubItem from "@core/items/SidebarSubItem";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

const Sidebar = () => {
  const {t} = useTranslation();
  const history = useHistory();

  return (
    <aside className="h-screen sticky top-0 min-w-max w-56 bg-white border hidden lg:block relative">
      <div className="h-16 flex items-center justify-center">
        <img
          className={"lg:h-16 mt-6 sm:h-6 xs:h-6 hover:cursor-pointer"}
          src={euLogo}
          alt="logo"
          onClick={() => {
            history.push("/");
          }}
        />
        <img
          className={"lg:h-22 sm:h-6 xs:h-5 hover:cursor-pointer ml-1 mt-4"}
          src={logo}
          alt="logo"
          onClick={() => {
            history.push("/");
          }}
        />
      </div>
      <ul className="flex flex-col py-4 mt-6 h-screen overflow-y-auto">
        <SidebarItem
          icon={<FaToolbox/>}
          label={t("authorizationTypes")}
          permissions={["view_authorization_types"]}
          link={"/configurations/authorization-types"}
        />
        <SidebarItem
          icon={<FaRuler/>}
          label={t("capacityUnits")}
          permissions={["view_capacity_units"]}
          link={"/configurations/capacity-units"}
        />
        <SidebarItem
          icon={<FaDatabase/>}
          label={t("censuses")}
          permissions={["view_census"]}
          link={"/configurations/censuses"}
        />
        <SidebarItem
          icon={<FaCity/>}
          label={t("companyTypes")}
          permissions={["view_company_types"]}
          link={"/configurations/company-types"}
        />
        <SidebarItem
          icon={<GiWorld/>}
          label={t("countries")}
          permissions={["view_countries"]}
          link={"/configurations/countries"}
        />
        <SidebarItem
          icon={<FaBiohazard/>}
          label={t("disposalTypes")}
          permissions={["view_disposal_types"]}
          link={"/configurations/disposal-types"}
        />
        <SidebarItem
          icon={<GiPowerLightning/>}
          label={t("energySources")}
          permissions={["view_energy_sources"]}
          link={"/configurations/energy-sources"}
        />
        <SidebarItem
          icon={<GiTurbine/>}
          label={t("engineLocations")}
          permissions={["view_engine_locations"]}
          link={"/configurations/engine-locations"}
        />
        <SidebarItem
          icon={<FaTools/>}
          label={t("equipment")}
          permissions={["view_equipments", "view_equipment_categories"]}
          exact
        >
          <SidebarSubItem
            label={t("equipment")}
            link={"/configurations/equipments"}
            exact
            permission={"view_equipments"}
          />
          <SidebarSubItem
            label={t("equipmentCategories")}
            link={"/configurations/equipment-categories"}
            exact
            permission={"view_equipment_categories"}
          />
        </SidebarItem>
        <SidebarItem
          icon={<GiBoatFishing/>}
          label={t("fishingTechniquesEU")}
          permissions={["view_fishing_techniques_eu"]}
          link={"/configurations/fishing-techniques-eu"}
        />
        <SidebarItem
          icon={<FaFish/>}
          label={t("fishingZones")}
          permissions={["view_fishing_zones"]}
          link={"/configurations/fishing-zones"}
        />
        <SidebarItem
          icon={<GiSubmarine/>}
          label={t("fleetSegmentations")}
          permissions={["view_fleet_segmentations"]}
          link={"/configurations/fleet-segmentations"}
        />
        <SidebarItem
          icon={<FaCogs/>}
          label={t("gears")}
          permissions={[
            "view_gear_classes",
            "view_gears",
            "view_gear_activation_methods",
          ]}
          exact
        >
          <SidebarSubItem
            label={t("gears")}
            link={"/configurations/gears"}
            exact
            permission={"view_gears"}
          />
          <SidebarSubItem
            label={t("gearClasses")}
            link={"/configurations/gear-classes"}
            exact
            permission={"view_gear_classes"}
          />
          <SidebarSubItem
            label={t("gearActivationMethods")}
            link={"/configurations/gear-activation-methods"}
            exact
            permission={"view_gear_activation_methods"}
          />
          <SidebarSubItem
            label={t("gearEU")}
            link={"/configurations/gear-eu"}
            exact
            permission={"view_gears_eu"}
          />
        </SidebarItem>
        <SidebarItem
          icon={<GiDoubleFish/>}
          label={t("groupOfSpecies")}
          permissions={["view_groups_of_species", "view_group_of_species_gfcm"]}
          exact
        >
          <SidebarSubItem
            label={t("groupOfSpecies")}
            link={"/configurations/groups-of-species"}
            exact
            permission={"view_groups_of_species"}
          />
          <SidebarSubItem
            label={t("groupOfSpeciesGFCM")}
            link={"/configurations/groups-of-species-gfcm"}
            exact
            permission={"view_group_of_species_gfcm"}
          />
        </SidebarItem>
        <SidebarItem
          icon={<GiIronHulledWarship/>}
          label={t("hullMaterials")}
          permissions={["view_hull_materials", "view_hull_materials_eu"]}
        >
          <SidebarSubItem
            label={t("hullMaterials")}
            link={"/configurations/hull-materials"}
            exact
            permission={"view_hull_materials"}
          />
          <SidebarSubItem
            label={t("hullMaterialsEU")}
            link={"/configurations/hull-materials-eu"}
            exact
            permission={"view_hull_materials_eu"}
          />
        </SidebarItem>
        <SidebarItem
          icon={<FaBuilding/>}
          label={t("issuingOffices")}
          permissions={["view_issuing_offices"]}
          link={"/configurations/issuing-offices"}
        />
        <SidebarItem
          icon={<GiFishingPole/>}
          label={t("marineFisherySubSectors")}
          permissions={["view_marine_fishery_sub_sectors"]}
          link={"/configurations/marine-fishery-sub-sectors"}
        />
        <SidebarItem
          icon={<FaCog/>}
          label={t("operationalStatuses")}
          permissions={["view_operational_statuses", "view_inactivity_reasons"]}
        >
          <SidebarSubItem
            label={t("operationalStatuses")}
            link={"/configurations/operational-statuses"}
            exact
            permission={"view_operational_statuses"}
          />
          <SidebarSubItem
            label={t("inactivityReasons")}
            link={"/configurations/inactivity-reasons"}
            exact
            permission={"view_inactivity_reasons"}
          />
        </SidebarItem>
        <SidebarItem
          icon={<FaUsers/>}
          label={t("owners")}
          permissions={["view_owners"]}
          link={"/configurations/owners"}
        />
        <SidebarItem
          icon={<GiHarborDock/>}
          label={t("ports")}
          permissions={["view_ports", "view_port_types"]}
        >
          <SidebarSubItem
            label={t("ports")}
            link={"/configurations/ports"}
            exact
            permission={"view_ports"}
          />
          <SidebarSubItem
            label={t("portTypes")}
            link={"/configurations/port-types"}
            exact
            permission={"view_port_types"}
          />
        </SidebarItem>
        <SidebarItem
          icon={<FaAnchor/>}
          label={t("captaincies")}
          permissions={["view_captaincies"]}
          link={"/configurations/captaincies"}
        />
        <SidebarItem
          icon={<GiAbstract070/>}
          label={t("stratas")}
          permissions={["view_strata", "view_sub_strata"]}
          exact
        >
          <SidebarSubItem
            label={t("stratas")}
            link={"/configurations/stratas"}
            permission={"view_strata"}
          />
          <SidebarSubItem
            label={t("subStratas")}
            link={"/configurations/sub-stratas"}
            permission={"view_sub_strata"}
          />
          <SidebarSubItem
            label={t("minorStratas")}
            link={"/configurations/minor-stratas"}
            permission={"view_minor_strata"}
          />
        </SidebarItem>
        <SidebarItem
          icon={<GiFlatPlatform/>}
          label={t("subAreas")}
          permissions={["view_sub_areas", "view_statistical_divisions"]}
          exact
        >
          <SidebarSubItem
            label={t("geoSubAreas")}
            link={"/configurations/geo-sub-areas"}
            permission={"view_geo_sub_areas"}
          />
          <SidebarSubItem
            label={t("statisticalDivisions")}
            link={"/configurations/statistical-divisions"}
            permission={"view_statistical_divisions"}
          />
          <SidebarSubItem
            label={t("subAreas")}
            link={"/configurations/sub-areas"}
            permission={"view_sub_areas"}
          />
        </SidebarItem>
        <SidebarItem
          icon={<FaUserShield/>}
          label={t("users")}
          permissions={["view_users", "view_roles"]}
          exact
        >
          <SidebarSubItem
            label={t("users")}
            link={"/configurations/users"}
            permission={"view_users"}
          />
          <SidebarSubItem
            label={t("roles")}
            link={"/configurations/roles"}
            permission={"view_roles"}
          />
        </SidebarItem>
        <SidebarItem
          icon={<FaShip/>}
          label={t("vesselTypes")}
          permissions={["view_vessel_types", "view_vessel_types_fffao"]}
        >
          <SidebarSubItem
            label={t("vesselTypes")}
            link={"/configurations/vessel-types"}
            exact
            permission={"view_vessel_types"}
          />
          <SidebarSubItem
            label={t("vesselTypesFFFAO")}
            link={"/configurations/vessel-types-fffao"}
            exact
            permission={"view_vessel_types_fffao"}
          />
        </SidebarItem>
        <SidebarItem
          icon={<GiCargoCrane/>}
          label={t("Winches")}
          permissions={["view_winch_types"]}
        >
          <SidebarSubItem
            label={t("winchTypes")}
            link={"/configurations/winch-types"}
            exact
            permission={"view_winch_types"}
          />
          <SidebarSubItem
            label={t("winchOperationTypes")}
            link={"/configurations/winch-operation-types"}
            exact
            permission={"view_winch_operation_types"}
          />
        </SidebarItem>
        <div className="py-10"/>
      </ul>
    </aside>
  );
};

export default Sidebar;
