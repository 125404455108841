import React from "react";
import Layout from "@hoc/layouts/Layout";
import GeoSubAreasIndex from "@components/GeoSubArea/GeoSubAreasIndex";

const GeoSubAreasPage = () => {
  return (
    <Layout>
      <GeoSubAreasIndex />
    </Layout>
  );
};

export default GeoSubAreasPage;
