import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import CountryService from "@services/CountryService";
import ShowCountry from "@components/Country/ShowCountry";
import EditCountry from "@components/Country/EditCountry";

const CountryActions = ({ country, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowCountry country={country} />
      </div>
      <div className={"mr-2"}>
        <EditCountry country={country} onEdited={onEdited} />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={CountryService}
          id={country.id}
          onDeleted={onDeleted}
          permission={"delete_country"}
        />
      </div>
    </div>
  );
};

export default CountryActions;
