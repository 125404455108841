import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import GearClassService from "@services/GearClassService";
import ShowGearClass from "@components/GearClass/ShowGearClass";
import EditGearClass from "@components/GearClass/EditGearClass";

const GearClassActions = ({ gearClass, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowGearClass gearClass={gearClass} />
      </div>
      <div className={"mr-2"}>
        <EditGearClass gearClass={gearClass} onEdited={onEdited} />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={GearClassService}
          id={gearClass.id}
          onDeleted={onDeleted}
          permission={"delete_gear_class"}
        />
      </div>
    </div>
  );
};

export default GearClassActions;
