import React from "react";
import Layout from "@hoc/layouts/Layout";
import CensusesIndex from "@components/Census/CensusesIndex";

const CensusesPage = () => {
  return (
    <Layout>
      <CensusesIndex />
    </Layout>
  );
};

export default CensusesPage;
