import React, {useEffect, useState} from 'react';
import ViewModal from "@core/modals/ViewModal";
import ViewButton from "@core/buttons/atoms/ViewButton";
import RowDetails from "@hoc/cruds/RowDetails";
import {useTranslation} from "react-i18next";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import OwnerService from "@services/OwnerService";

const ShowOwner = ({owner}) => {
  const {t} = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [ownerData, setOwnerData] = useState([]);

  const closeModal = () => {
    setShowModal(false)
  }
  useEffect(() => {
    if (showModal)
      OwnerService.find(owner.id).then((response) => {
        setOwnerData(response.data.data);
      });
  }, [owner.id, showModal]);

  return (
    <PermissionHandler permission={'view_owners'}>
      <ViewButton onClick={() => setShowModal(true)}/>
      <ViewModal
        show={showModal}
        title={`${t('overview')}`}
        onClose={closeModal}
      >
        <div className='flex flex-col mt-5'>
          <RowDetails label={t('companyType')} value={ownerData.company_type}/>
          <RowDetails label={t('companyName')} value={ownerData.company_name}/>
          <RowDetails label={t('establishedYear')} value={ownerData.established_year}/>
          <RowDetails label={t('address')} value={ownerData.address}/>
          <RowDetails label={t('postcode')} value={ownerData.postcode}/>
          <RowDetails label={t('town')} value={ownerData.town}/>
          <RowDetails label={t('region')} value={ownerData.region_name}/>
          <RowDetails label={t('country')} value={ownerData.country}/>
          <RowDetails label={t('registrationOffice')} value={ownerData.registration_office}/>
          <RowDetails label={t('remarks')} value={ownerData.remarks}/>
        </div>
      </ViewModal>
    </PermissionHandler>
  )
}

export default ShowOwner;