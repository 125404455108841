import React, { useMemo, useState } from "react";
import Datatable from "@core/table/Datatable";
import MobileTable from "@core/table/MobileTable";
import CensusActions from "@components/Census/partials/CensusActions";
import moment from "moment/moment";
import CreateCensus from "@components/Census/CreateCensus";
import CensusService from "@services/CensusService";
import { useTranslation } from "react-i18next";
import GreenBadge from "@core/badges/GreenBadge";
import OrangeBadge from "@core/badges/OrangeBadge";

const CensusesIndex = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [metaData, setMetaData] = useState();
  const [updatedTable, setUpdatedTable] = useState(0);

  const headers = useMemo(
    () => [
      {
        id: "censusName",
        name: t("censusName"),
        cell: (row) => row.name,
        minWidth: "300px",
      },
      {
        id: "created_at",
        name: t("createdAt"),
        cell: (row) => moment(row.created_at).format("MMMM Do YYYY"),
        minWidth: "200px",
      },
      {
        id: "status",
        name: t("status"),
        cell: (row) =>
          row.expired_at ? (
            <OrangeBadge text={"Sealed"} />
          ) : (
            <GreenBadge text={"Open"} />
          ),
        width: "100px",
      },
      {
        id: "actions",
        name: t("actions"),
        cell: (row) => (
          <CensusActions
            census={row}
            onEdited={() => setUpdatedTable((prev) => prev + 1)}
          />
        ),
      },
    ],
    [t]
  );
  const getData = (params) => {
    setLoading(true);
    CensusService.all(params).then((response) => {
      setMetaData(response.data.metadata);
      setData(response.data.data);
      setLoading(false);
    });
  };

  return (
    <div className="border bg-white rounded justify-between items-center mb-5 py-2">
      <div className="mx-3 my-5">
        <label className="text-lg font-bold">{t("censusList")}</label>
        <div className="flex flex-row">
          <div className="flex w-1/2 sm:w-1/4 md:w-1/6 mt-3 mr-2">
            <CreateCensus
              onAdded={() => setUpdatedTable((prev) => prev + 1)}
              label={`${t("add")} ${t("census")}`}
            />
          </div>
        </div>
        <div className="block lg:hidden">
          <MobileTable
            headers={headers}
            data={data}
            page={metaData?.currentPage}
            totalRows={metaData?.total}
            pageCount={metaData?.lastPage}
          />
        </div>
        <div className="hidden lg:block">
          <Datatable
            data={data}
            headers={headers}
            totalRows={metaData?.total}
            getData={getData}
            isLoading={loading}
            extraDependencies={[updatedTable]}
          />
        </div>
      </div>
    </div>
  );
};

export default CensusesIndex;
