import API from "@utils/plugins/API";

const PortService = {
  all: (params) => {
    return API.get("ports", { params: params });
  },
  create: (
    name,
    name_eng,
    description,
    minor_stratum_id,
    geo_sub_area_id,
    port_type_id,
    latitude,
    longitude,
    port_code,
    port_code_eu
  ) => {
    return API.post("ports", {
      name,
      name_eng,
      description,
      minor_stratum_id,
      geo_sub_area_id,
      port_type_id,
      latitude,
      longitude,
      port_code,
      port_code_eu,
    });
  },
  update: (
    id,
    name,
    name_eng,
    description,
    minor_stratum_id,
    geo_sub_area_id,
    port_type_id,
    latitude,
    longitude,
    port_code,
    port_code_eu
  ) => {
    return API.put(`ports/${id}`, {
      name,
      name_eng,
      description,
      minor_stratum_id,
      geo_sub_area_id,
      port_type_id,
      latitude,
      longitude,
      port_code,
      port_code_eu,
    });
  },
  find: (id) => {
    return API.get(`ports/${id}`);
  },
  destroy: (id) => {
    return API.delete(`ports/${id}`);
  },
};

export default PortService;
