import React from "react";
import Layout from "@hoc/layouts/Layout";
import AuthorizationTypesIndex from "@components/AuthorizationTypes/AuthorizationTypesIndex";

const AuthorizationTypesPage = () => {
  return (
    <Layout>
      <AuthorizationTypesIndex />
    </Layout>
  );
};

export default AuthorizationTypesPage;
