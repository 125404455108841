import React, { useEffect, useState } from "react";
import EditModal from "@core/modals/EditModal";
import EditButton from "@core/buttons/atoms/EditButton";
import Input from "@core/inputs/Input";
import { showError, showSuccess } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import GearService from "@services/GearService";
import GearEUSelect from "@components/GearEU/partials/GearEUSelect";
import MarineFisherySubsectorSelect from "@components/MarineFisherySubsector/partials/MarineFisherySubsectorSelect";
import FishingTechniqueEUSelect from "@components/FishingTechniqueEU/partials/FishingTechniqueEUSelect";
import GearClassSelect from "@components/GearClass/partials/GearClassSelect";

const EditGear = ({ gear, onEdited }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [nameEn, setNameEn] = useState("");
  const [matchingCodeGear, setMatchingCodeGear] = useState("");
  const [gearStd, setGearStd] = useState("");
  const [description, setDescription] = useState("");
  const [selectedGearClass, setSelectedGearClass] = useState();
  const [selectedGearEU, setSelectedGearEU] = useState();
  const [selectedFishingTechniqueEU, setSelectedFishingTechniqueEU] =
    useState();
  const [selectedMarineFisherySubSector, setSelectedMarineFisherySubSector] =
    useState();

  const closeModal = () => {
    setShowModal(false);
    setName("");
    setDescription("");
    setNameEn("");
    setGearStd("");
    setMatchingCodeGear("");
    setSelectedGearClass("");
    setSelectedGearEU("");
    setSelectedFishingTechniqueEU("");
    setSelectedMarineFisherySubSector("");
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    GearService.update(
      gear.id,
      name,
      nameEn,
      description,
      matchingCodeGear,
      selectedGearEU,
      selectedGearClass,
      gearStd,
      selectedFishingTechniqueEU,
      selectedMarineFisherySubSector
    )
      .then(() => {
        showSuccess(t("gearEditedSuccessfully"));
        onEdited();
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (showModal)
      GearService.find(gear.id).then((response) => {
        setName(response.data.data.name);
        setNameEn(response.data.data.name_eng);
        setMatchingCodeGear(response.data.data.matching_code_gear);
        setGearStd(response.data.data.gear_std);
        setDescription(response.data.data.description);
        setSelectedGearClass(response.data.data.gear_class_id);
        setSelectedFishingTechniqueEU(
          response.data.data.fishing_technique_eu_id
        );
        setSelectedMarineFisherySubSector(
          response.data.data.machine_fishery_subsector_id
        );
        setSelectedGearEU(response.data.data.gear_eu_id);
      });
  }, [gear.id, showModal]);

  return (
    <PermissionHandler permission={"update_gear"}>
      <EditButton onClick={() => setShowModal(true)} />
      <EditModal
        show={showModal}
        title={t("editGear")}
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}
        label={t("edit")}
      >
        <div className="mt-8 mb-2 grid grid-cols-2 gap-5">
          <Input
            height="h-10"
            label={t("name")}
            placeholder={t("name")}
            value={name}
            onChange={setName}
          />
          <Input
            height="h-10"
            label={t("nameEng")}
            placeholder={t("nameEng")}
            value={nameEn}
            onChange={setNameEn}
          />
          <div>
            <div className="text-sm font-semibold mb-2 flex">
              {t("gearsEU")}
            </div>
            <GearEUSelect
              openModal={showModal}
              onSelect={setSelectedGearEU}
              selected={selectedGearEU}
            />
          </div>
          <div>
            <div className="text-sm font-semibold mb-2 flex">
              {t("marineFisherySubSectors")}
            </div>
            <MarineFisherySubsectorSelect
              openModal={showModal}
              onSelect={setSelectedMarineFisherySubSector}
              selected={selectedMarineFisherySubSector}
            />
          </div>
          <div>
            <div className="text-sm font-semibold mb-2 flex">
              {t("fishingTechniquesEU")}
            </div>
            <FishingTechniqueEUSelect
              openModal={showModal}
              onSelect={setSelectedFishingTechniqueEU}
              selected={selectedFishingTechniqueEU}
            />
          </div>
          <div>
            <div className="text-sm font-semibold mb-2 flex">
              {t("gearClasses")}
            </div>
            <GearClassSelect
              openModal={showModal}
              onSelect={setSelectedGearClass}
              selected={selectedGearClass}
            />
          </div>
          <Input
            height="h-10"
            label={t("matchingCodeGear")}
            placeholder={t("matchingCodeGear")}
            value={matchingCodeGear}
            onChange={setMatchingCodeGear}
          />
          <Input
            height="h-10"
            label={t("gearStd")}
            placeholder={t("gearStd")}
            value={gearStd}
            onChange={setGearStd}
          />
          <Input
            height="h-10"
            label={t("description")}
            placeholder={t("description")}
            value={description}
            onChange={setDescription}
          />
        </div>
      </EditModal>
    </PermissionHandler>
  );
};

export default EditGear;
