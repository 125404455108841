import React, { useEffect, useState } from "react";
import ViewModal from "@core/modals/ViewModal";
import ViewButton from "@core/buttons/atoms/ViewButton";
import RowDetails from "@hoc/cruds/RowDetails";
import { useTranslation } from "react-i18next";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import HullMaterialEUService from "@services/HullMaterialEUService";

const ShowHullMaterialEU = ({ hullMaterialEU }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [hullMaterialEUData, setHullMaterialEUData] = useState([]);

  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (showModal)
      HullMaterialEUService.find(hullMaterialEU.id).then((response) => {
        setHullMaterialEUData(response.data.data);
      });
  }, [hullMaterialEU.id, showModal]);

  return (
    <PermissionHandler permission={"view_hull_materials_eu"}>
      <ViewButton onClick={() => setShowModal(true)} />
      <ViewModal
        show={showModal}
        title={`${t("overview")}`}
        onClose={closeModal}
      >
        <div className="flex flex-col mt-5">
          <RowDetails label={t("name")} value={hullMaterialEUData.name} />
          <RowDetails
            label={t("nameEng")}
            value={hullMaterialEUData.name_eng}
          />
          <RowDetails
            label={t("description")}
            value={hullMaterialEUData.description}
          />
        </div>
      </ViewModal>
    </PermissionHandler>
  );
};

export default ShowHullMaterialEU;
