import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import ShowCaptaincy from "@components/Captaincy/ShowCaptaincy";
import CaptaincyService from "@services/CaptaincyService";
import EditCaptaincy from "@components/Captaincy/EditCaptaincy";

const CaptaincyActions = ({ captaincy, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowCaptaincy captaincy={captaincy} />
      </div>
      <div className={"mr-2"}>
        <EditCaptaincy captaincy={captaincy} onEdited={onEdited} />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={CaptaincyService}
          id={captaincy.id}
          onDeleted={onDeleted}
          permission={"delete_captaincy"}
        />
      </div>
    </div>
  );
};

export default CaptaincyActions;
