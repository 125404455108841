import React from "react";
import Layout from "@hoc/layouts/Layout";
import EnergySourcesIndex from "@components/EnergySources/EnergySourcesIndex";

const EnergySourcesPage = () => {
  return (
    <Layout>
      <EnergySourcesIndex />
    </Layout>
  );
};

export default EnergySourcesPage;
