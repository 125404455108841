import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import ViewButton from "@core/buttons/atoms/ViewButton";
import EditButton from "@core/buttons/atoms/EditButton";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import VesselService from "@services/VesselService";

const VesselActions = ({ expiredAtAt = false, vessel, onDeleted }) => {
  const { id } = useParams();
  const history = useHistory();
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ViewButton
          onClick={() => {
            history.push({
              pathname: `/census/${id}/show-vessel/${vessel.id}`,
            });
          }}
        />
      </div>
      {!expiredAtAt && (
        <>
          <div className={"mr-2"}>
            <EditButton
              onClick={() => {
                history.push({
                  pathname: `/census/${id}/edit-vessel/${vessel.id}`,
                });
              }}
            />
          </div>
          <div className={"mr-2"}>
            <DeleteEntity
              service={VesselService}
              id={vessel.id}
              onDeleted={onDeleted}
              permission={"delete_vessel"}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default VesselActions;
