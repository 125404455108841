import React from "react";
import {Trash} from "tabler-icons-react";
import {useTranslation} from "react-i18next";
import ApiSelect from "@core/dropdowns/ApiSelect";
import GearClassService from "@services/GearClassService";
import GearTypeSelect from "@components/GearType/partials/GearTypeSelect";

const Gear = ({data, index, onDelete, onChange, disabled = false, showModal}) => {
  const {t} = useTranslation();

  const handleChange = (value, name) => {
    onChange(index, {...data, [name]: value});
  };

  return (
    <div key={index}>
      <div className="grid grid-cols-3 gap-5">
        <div>
          <div className="text-xs font-semibold mb-2 flex">
            {t("gearClass")}
            <div className="text-red-600 ml-1">*</div>
          </div>
          <ApiSelect
            showModal={showModal}
            isClearable
            reduxKey={"gearClass"}
            disabled={disabled}
            service={GearClassService}
            onSelect={(val) => handleChange(val, "gear_class_id")}
            selected={data?.gear_class_id}
          />
        </div>
        <div>
          <div className="text-xs font-semibold mb-2 flex">
            {t("gearType")}
            <div className="text-red-600 ml-1">*</div>
          </div>
          <GearTypeSelect
            showModal={showModal}
            isClearable
            reduxKey={`gearType${index}`}
            disabled={disabled}
            gearClassId={data?.gear_class_id}
            onSelect={(val) => handleChange(val, "gear_type_id")}
            selected={data?.gear_type_id}
          />
        </div>
        {!disabled && (
          <div className="flex self-center pt-5 flex-start mr-5">
            <button
              className="flex text-red-600 cursor-pointer ml-auto"
              onClick={() => onDelete(index)}
            >
              <Trash/>
            </button>
          </div>
        )}
      </div>
      <hr className="px-5 my-3"/>
    </div>
  );
};

export default Gear;