import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import HullMaterialService from "@services/HullMaterialService";
import ShowHullMaterial from "@components/HullMaterial/ShowHullMaterial";
import EditHullMaterial from "@components/HullMaterial/EditHullMaterial";

const HullMaterialActions = ({ hullMaterial, onEdited, onDeleted }) => {
  // TODO: replace this with correct permission at DeleteEntity
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowHullMaterial hullMaterial={hullMaterial} />
      </div>
      <div className={"mr-2"}>
        <EditHullMaterial hullMaterial={hullMaterial} onEdited={onEdited} />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={HullMaterialService}
          id={hullMaterial.id}
          onDeleted={onDeleted}
          permission={"delete_hull_materials"}
        />
      </div>
    </div>
  );
};

export default HullMaterialActions;
