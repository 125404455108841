import React from "react";
import {useTranslation} from "react-i18next";
import Input from "@core/inputs/Input";
import NumericalInput from "@core/inputs/NumericalInput";

const VesselCrew = ({disabled = false, data, onChange}) => {
  const {t} = useTranslation();

  const handleChange = (value, name) => {
    onChange({...data, [name]: value === '' ? null : value});
  };

  return (
    <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
      <NumericalInput
        disabled={disabled}
        height="h-10"
        label={t("minimumNumber")}
        placeholder={t("minimumNumber")}
        value={data.minimum_number}
        onChange={(val) => handleChange(val, "minimum_number")}
      />
      <NumericalInput
        disabled={disabled}
        height="h-10"
        label={t("maximumNumber")}
        placeholder={t("maximumNumber")}
        value={data.maximum_number}
        onChange={(val) => handleChange(val, "maximum_number")}
      />
      <NumericalInput
        disabled={disabled}
        height="h-10"
        label={t("fullTimeNumber")}
        placeholder={t("fullTimeNumber")}
        value={data.full_time_number}
        onChange={(val) => handleChange(val, "full_time_number")}
      />
      <NumericalInput
        disabled={disabled}
        height="h-10"
        label={t("seasonalPartTimeNumber")}
        placeholder={t("seasonalPartTimeNumber")}
        value={data.part_time_number}
        onChange={(val) => handleChange(val, "part_time_number")}
      />
      <NumericalInput
        disabled={disabled}
        height="h-10"
        label={t("femaleNumber")}
        placeholder={t("femaleNumber")}
        value={data.female_number}
        onChange={(val) => handleChange(val, "female_number")}
      />
      <Input
        disabled={disabled}
        height="h-10"
        label={t("remarks")}
        placeholder={t("remarks")}
        value={data.remarks}
        onChange={(val) => handleChange(val, "remarks")}
      />
    </div>
  );
};

export default VesselCrew;
