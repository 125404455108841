import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import ShowIssuingOffice from "@components/IssuingOffice/ShowIssuingOffice";
import EditIssuingOffice from "@components/IssuingOffice/EditIssuingOffice";
import IssuingOfficeService from "@services/IssuingOfficeService";

const IssuingOfficeActions = ({ issuingOffice, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowIssuingOffice issuingOffice={issuingOffice} />
      </div>
      <div className={"mr-2"}>
        <EditIssuingOffice issuingOffice={issuingOffice} onEdited={onEdited} />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={IssuingOfficeService}
          id={issuingOffice.id}
          onDeleted={onDeleted}
          permission={"delete_issuing_office"}
        />
      </div>
    </div>
  );
};

export default IssuingOfficeActions;
