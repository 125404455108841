import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import Input from "@core/inputs/Input";

const VesselElectronics = ({disabled = false, data, onChange}) => {
  const {t} = useTranslation();
  const [ircsIndicator, setIrcsIndicator] = useState(data?.ircs_indicator);
  const [ersIndicator, setErsIndicator] = useState(data?.ers_indicator);
  const [vmsIndicator, setVmsIndicator] = useState(data?.vms_indicator);
  const [mmsiIndicator, setMmsiIndicator] = useState(data?.mmsi_indicator);

  const handleChange = (value, name) => {
    onChange({...data, [name]: value});
  };

  const handleIndicator = (indicatorValue, indicatorName, idName) => {
    handleChange(indicatorValue, indicatorName);
    if (indicatorValue === false) {
      handleChange(null, idName)
    }
  }

  return (
    <>
      <div className='mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5'>
        <div>
          <div className="flex items-center mt-5 mb-3">
            <input
              disabled={disabled}
              type="checkbox"
              checked={ircsIndicator}
              value={ircsIndicator}
              onChange={() => {
                setIrcsIndicator(!ircsIndicator);
                handleIndicator(!ircsIndicator, 'ircs_indicator', 'ircs_callsign')
              }}
              className="w-4 h-4 text-indigo-700 bg-gray-100 rounded border-gray-300 focus:ring-indigo-500 focus:ring-2"/>
            <label className="ml-2 text-base text-gray-700">{t('IRCSIndicator')}</label>
          </div>
          <Input
            disabled={!ircsIndicator || disabled}
            height='h-10'
            label={t('IRCSCallsign')}
            placeholder={t('IRCSCallsign')}
            value={data.ircs_callsign}
            onChange={(val) => handleChange(val, 'ircs_callsign')}
          />
        </div>
        <div>
          <div className="flex items-center mt-5 mb-3">
            <input
              disabled={disabled}
              type="checkbox"
              checked={ersIndicator}
              value={ersIndicator}
              onChange={() => {
                setErsIndicator(!ersIndicator);
                handleIndicator(!ersIndicator, 'ers_indicator', 'ers_id')
              }}
              className="w-4 h-4 text-indigo-700 bg-gray-100 rounded border-gray-300 focus:ring-indigo-500 focus:ring-2"/>
            <label className="ml-2 text-base text-gray-700">{t('ERSIndicator')}</label>
          </div>
          <Input
            disabled={!ersIndicator || disabled}
            height='h-10'
            label={t('ERSId')}
            placeholder={t('ERSId')}
            value={data.ers_id}
            onChange={(val) => handleChange(val, 'ers_id')}
          />
        </div>
        <div>
          <div className="flex items-center mt-5 mb-3">
            <input
              disabled={disabled}
              type="checkbox"
              checked={vmsIndicator}
              value={vmsIndicator}
              onChange={() => {
                setVmsIndicator(!vmsIndicator);
                handleIndicator(!vmsIndicator, 'vms_indicator', 'vms_id')
              }}
              className="w-4 h-4 text-indigo-700 bg-gray-100 rounded border-gray-300 focus:ring-indigo-500 focus:ring-2"/>
            <label className="ml-2 text-base text-gray-700">{t('VMSIndicator')}</label>
          </div>
          <Input
            disabled={!vmsIndicator || disabled}
            height='h-10'
            label={t('VMSId')}
            placeholder={t('VMSId')}
            value={data.vms_id}
            onChange={(val) => handleChange(val, 'vms_id')}
          />
        </div>
        <div>
          <div className="flex items-center mt-5 mb-3">
            <input
              disabled={disabled}
              type="checkbox"
              checked={mmsiIndicator}
              value={mmsiIndicator}
              onChange={() => {
                setMmsiIndicator(!mmsiIndicator);
                handleIndicator(!mmsiIndicator, 'mmsi_indicator', 'mmsi_id')
              }}
              className="w-4 h-4 text-indigo-700 bg-gray-100 rounded border-gray-300 focus:ring-indigo-500 focus:ring-2"/>
            <label className="ml-2 text-base text-gray-700">{t('MMSIIndicator')}</label>
          </div>
          <Input
            disabled={!mmsiIndicator || disabled}
            height='h-10'
            label={t('MMSIId')}
            placeholder={t('MMSIId')}
            value={data.mmsi_id}
            onChange={(val) => handleChange(val, 'mmsi_id')}
          />
        </div>
      </div>
      <div className="mt-5">
        <Input
          disabled={disabled}
          height='h-10'
          label={t('remarks')}
          placeholder={t('remarks')}
          value={data.remarks}
          onChange={(val) => handleChange(val, 'remarks')}
        />
      </div>
    </>
  )
}

export default VesselElectronics;
