import React, { useEffect, useState } from "react";
import ViewModal from "@core/modals/ViewModal";
import ViewButton from "@core/buttons/atoms/ViewButton";
import RowDetails from "@hoc/cruds/RowDetails";
import { useTranslation } from "react-i18next";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import CompanyTypeService from "@services/CompanyTypeService";

const ShowCompanyType = ({ companyType }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [companyTypeData, setCompanyTypeData] = useState([]);

  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (showModal)
      CompanyTypeService.find(companyType.id).then((response) => {
        setCompanyTypeData(response.data.data);
      });
  }, [companyType.id, showModal]);

  return (
    <PermissionHandler permission={"view_company_types"}>
      <ViewButton onClick={() => setShowModal(true)} />
      <ViewModal
        show={showModal}
        title={`${t("overview")}`}
        onClose={closeModal}
      >
        <div className="flex flex-col mt-5">
          <RowDetails label={t("name")} value={companyTypeData.name} />
          <RowDetails label={t("nameEng")} value={companyTypeData.name_eng} />
          <RowDetails
            label={t("description")}
            value={companyTypeData.description}
          />
        </div>
      </ViewModal>
    </PermissionHandler>
  );
};

export default ShowCompanyType;
