import React, { useEffect, useState } from "react";
import EditModal from "@core/modals/EditModal";
import EditButton from "@core/buttons/atoms/EditButton";
import Input from "@core/inputs/Input";
import { showError, showSuccess } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import HullMaterialService from "@services/HullMaterialService";
import HullMaterialEUSelect from "@components/HullMaterialEU/partials/HullMaterialEUSelect";

const EditHullMaterial = ({ hullMaterial, onEdited }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [selectedHullMaterialEU, setSelectedHullMaterialEU] = useState();
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setName("");
    setDescription("");
    setNameEn("");
    setSelectedHullMaterialEU("");
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    HullMaterialService.update(
      hullMaterial.id,
      name,
      nameEn,
      selectedHullMaterialEU,
      description
    )
      .then(() => {
        showSuccess(t("hullMaterialEditedSuccessfully"));
        onEdited();
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (showModal)
      HullMaterialService.find(hullMaterial.id).then((response) => {
        setName(response.data.data.name);
        setNameEn(response.data.data.name_eng);
        setDescription(response.data.data.description);
        setSelectedHullMaterialEU(response.data.data.hull_materials_eu_id);
      });
  }, [hullMaterial.id, showModal]);

  return (
    <PermissionHandler permission={"update_hull_materials"}>
      <EditButton onClick={() => setShowModal(true)} />
      <EditModal
        show={showModal}
        title={t("editHullMaterial")}
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}
        label={t("edit")}
      >
        <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
          <Input
            height="h-10"
            label={t("name")}
            placeholder={t("name")}
            value={name}
            onChange={setName}
          />
          <Input
            height="h-10"
            label={t("nameEng")}
            placeholder={t("nameEng")}
            value={nameEn}
            onChange={setNameEn}
          />
          <Input
            height="h-10"
            label={t("description")}
            placeholder={t("description")}
            value={description}
            onChange={setDescription}
          />
          <div>
            <div className="text-sm font-semibold mb-2 flex">
              {t("hullMaterialsEU")}
            </div>
            <HullMaterialEUSelect
              openModal={showModal}
              onSelect={setSelectedHullMaterialEU}
              selected={selectedHullMaterialEU}
            />
          </div>
        </div>
      </EditModal>
    </PermissionHandler>
  );
};

export default EditHullMaterial;
