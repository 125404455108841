import API from "@utils/plugins/API";

const UserService = {
  all: (params) => {
    return API.get("users", { params: params });
  },
  create: (name, username, password, password_confirmation, roleId) => {
    return API.post("users", {
      name,
      username,
      password,
      password_confirmation,
      roleIds: [roleId],
    });
  },
  update: (id, name, username) => {
    return API.put(`users/${id}`, {
      name,
      username,
    });
  },
  find: (id) => {
    return API.get(`users/${id}`);
  },
  destroy: (id) => {
    return API.delete(`users/${id}`);
  },
  toggleStatus: (id) => {
    return API.patch(`users/${id}/toggle-status`);
  },
  changePassword: (id, password, password_confirmation) => {
    return API.patch(`users/${id}/change-password`, {
      password,
      password_confirmation,
    });
  },
};

export default UserService;
