import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import StrataService from "@services/StrataService";
import EditStrata from "@components/Strata/EditStrata";
import ShowStrata from "@components/Strata/ShowStrata";

const StrataActions = ({ strata, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowStrata strata={strata} />
      </div>
      <div className={"mr-2"}>
        <EditStrata strata={strata} onEdited={onEdited} />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={StrataService}
          id={strata.id}
          onDeleted={onDeleted}
          permission={"delete_stratum"}
        />
      </div>
    </div>
  );
};

export default StrataActions;
