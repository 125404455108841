import API from "@utils/plugins/API";

const CompanyTypeService = {
  all: (params) => {
    return API.get("company-types", { params: params });
  },
  create: (name, name_eng, description) => {
    return API.post("company-types", {
      name,
      name_eng,
      description,
    });
  },
  update: (id, name, name_eng, description) => {
    return API.put(`company-types/${id}`, {
      name,
      name_eng,
      description,
    });
  },
  find: (id) => {
    return API.get(`company-types/${id}`);
  },
  destroy: (id) => {
    return API.delete(`company-types/${id}`);
  },
};

export default CompanyTypeService;
