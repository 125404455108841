import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import HullMaterialEUService from "@services/HullMaterialEUService";
import ShowHullMaterialEU from "@components/HullMaterialEU/ShowHullMaterialEU";
import EditHullMaterialEU from "@components/HullMaterialEU/EditHullMaterialEU";

const HullMaterialEUActions = ({ hullMaterialEU, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowHullMaterialEU hullMaterialEU={hullMaterialEU} />
      </div>
      <div className={"mr-2"}>
        <EditHullMaterialEU
          hullMaterialEU={hullMaterialEU}
          onEdited={onEdited}
        />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={HullMaterialEUService}
          id={hullMaterialEU.id}
          onDeleted={onDeleted}
          permission={"delete_hull_materials_eu"}
        />
      </div>
    </div>
  );
};

export default HullMaterialEUActions;
