import React, { useEffect, useState } from "react";
import ViewModal from "@core/modals/ViewModal";
import ViewButton from "@core/buttons/atoms/ViewButton";
import RowDetails from "@hoc/cruds/RowDetails";
import { useTranslation } from "react-i18next";
import StrataService from "@services/StrataService";
import PermissionHandler from "@hoc/cruds/PermissionHandler";

const ShowStrata = ({ strata }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [strataData, setStrataData] = useState([]);

  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (showModal)
      StrataService.find(strata.id).then((response) => {
        setStrataData(response.data.data);
      });
  }, [strata.id, showModal]);

  return (
    <PermissionHandler permission={"view_strata"}>
      <ViewButton onClick={() => setShowModal(true)} />
      <ViewModal
        show={showModal}
        title={`${t("overview")}`}
        onClose={closeModal}
      >
        <div className="flex flex-col mt-5">
          <RowDetails label={t("name")} value={strataData.name} />
          <RowDetails label={t("nameEng")} value={strataData.name_eng} />
          <RowDetails label={t("description")} value={strataData.description} />
        </div>
      </ViewModal>
    </PermissionHandler>
  );
};

export default ShowStrata;
