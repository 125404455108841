import React, { useEffect, useState } from "react";
import EditModal from "@core/modals/EditModal";
import EditButton from "@core/buttons/atoms/EditButton";
import Input from "@core/inputs/Input";
import { showError, showSuccess } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import WinchTypeService from "@services/WinchTypeService";

const EditWinchType = ({ winchType, onEdited }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setName("");
    setNameEn("");
    setDescription("");
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    WinchTypeService.update(winchType.id, name, nameEn, description)
      .then(() => {
        showSuccess(t("winchTypeEditedSuccessfully"));
        onEdited();
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (showModal)
      WinchTypeService.find(winchType.id).then((response) => {
        setName(response.data.data.name);
        setNameEn(response.data.data.name_eng);
        setDescription(response.data.data.description);
      });
  }, [winchType.id, showModal]);

  return (
    //TODO:Add correct permission
    <PermissionHandler permission={"update_winch_type"}>
      <EditButton onClick={() => setShowModal(true)} />
      <EditModal
        show={showModal}
        title={t("editWinchType")}
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}
        label={t("edit")}
      >
        <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
          <Input
            label={t("winchType")}
            placeholder={t("winchType")}
            value={name}
            onChange={setName}
            height="h-10"
          />
          <Input
            height="h-10"
            label={t("nameEng")}
            placeholder={t("nameEng")}
            value={nameEn}
            onChange={setNameEn}
          />
          <Input
            label={t("description")}
            placeholder={t("description")}
            value={description}
            onChange={setDescription}
            height="h-10"
          />
        </div>
      </EditModal>
    </PermissionHandler>
  );
};

export default EditWinchType;
