import React from "react";
import Routes from "@router/index";
import Notification from "react-notify-toast";
import { BrowserRouter } from "react-router-dom";
import FullPageSpinner from "@hoc/partials/FullPageSpinner";
import AuthProvider from "@core/AuthProvider";

const App = () => {
  return (
    <BrowserRouter>
      <Notification options={{ zIndex: 2000, top: 10 }} />
      <AuthProvider>
        <Routes />
      </AuthProvider>
      <FullPageSpinner />
    </BrowserRouter>
  );
};

export default App;
