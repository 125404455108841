import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {FaRegFileExcel} from "react-icons/fa";
import GreenButton from "@core/buttons/electrons/GreenButton";
import {useParams} from "react-router-dom";
import ExportService from "@services/ExportService";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import {showError} from "@utils/helpers";

const Export = ({title, onClick}) => (
  <div className="p-1.5 hover:cursor-pointer">
    <div
      onClick={onClick}
      className="px-3 py-1.5 text-xs text-gray-800 rounded-lg hover:bg-emerald-50 hover:text-gray-900 text-left flex"
    >
      {title}
    </div>
  </div>
);

const Exports = ({simpleSearch, advancedSearch}) => {
  const {t} = useTranslation();
  const {id} = useParams();
  const [isOpen, setIsOpen] = useState(false);

  const exportExcel = (exportType) => {
    ExportService.reports(id, exportType).then((response) => {
      window.open(response.data.data.url);
      setIsOpen(false);
    });
  }


  const exportSearchExcel = (exportType) => {
    if (Object.keys(simpleSearch).length !== 0) {
      ExportService.simpleSearch(id, exportType, simpleSearch).then((response) => {
        window.open(response.data.data.url);
        setIsOpen(false);
      });
    }
    else if (Object.keys(advancedSearch).length !== 0) {
      ExportService.advancedSearch(id, exportType, advancedSearch).then((response) => {
        window.open(response.data.data.url);
        setIsOpen(false);
      });
    } else showError(t('noSearchFilters'))
  }

  const hasSearchFilters = () => {
   return Object.keys(simpleSearch).length !== 0 || Object.keys(advancedSearch).length !== 0;
  }

  return (
    <PermissionHandler permission={"generate_exports"}>
      <div className="relative">
        <GreenButton
          xs
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          label={
            <div className={"flex items-center p-1"}>
              <FaRegFileExcel className={"mr-1"}/>
              {t("exportExcel")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 font-bold ml-3"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </div>
          }
        ></GreenButton>
        {isOpen && (
          <div
            className="absolute right-0 z-10 mt-2 w-50 origin-top-right bg-white border border-gray-100 rounded-md shadow-lg">
            <Export
              title={t("exportOld")}
              onClick={() => {
                exportExcel("export-old");
              }}
            />
            <Export
              title={t("exportEuropeanHake")}
              onClick={() => {
                exportExcel("export-european-hake");
              }}
            />
            <Export
              title={t("exportSmallPelagic")}
              onClick={() => {
                exportExcel("export-small-pelagic");
              }}
            />
            <Export
              title={t("exportVesselRecording")}
              onClick={() => {
                exportExcel("export-vessel-recording");
              }}
            />
            <Export
              title={t("exportOver15m")}
              onClick={() => {
                exportExcel("export-over-15m");
              }}
            />
            <Export
              title={t("exportUnder15m")}
              onClick={() => {
                exportExcel("export-under-15m");
              }}
            />
            {hasSearchFilters() && <Export
              title={t("exportSearch")}
              onClick={() => {
                exportSearchExcel("export-vessel-search");
              }}
            />}
          </div>
        )}
      </div>
    </PermissionHandler>
  );
};

export default Exports;
