import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import GearActivationMethodService from "@services/GearActivationMethodService";
import EditGearActivationMethod from "@components/GearActivationMethod/EditGearActivationMethod";
import ShowGearActivationMethod from "@components/GearActivationMethod/ShowGearActivationMethod";

const GearActivationMethodActions = ({
  gearActivationMethod,
  onEdited,
  onDeleted,
}) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowGearActivationMethod gearActivationMethod={gearActivationMethod} />
      </div>
      <div className={"mr-2"}>
        <EditGearActivationMethod
          gearActivationMethod={gearActivationMethod}
          onEdited={onEdited}
        />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={GearActivationMethodService}
          id={gearActivationMethod.id}
          onDeleted={onDeleted}
          permission={"delete_gear_activation_method"}
        />
      </div>
    </div>
  );
};

export default GearActivationMethodActions;
