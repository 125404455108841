import React, { useState } from "react";
import AddButton from "@core/buttons/atoms/AddButton";
import AddModal from "@core/modals/AddModal";
import Input from "@core/inputs/Input";
import { showError, showSuccess } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import GearService from "@services/GearService";
import GearEUSelect from "@components/GearEU/partials/GearEUSelect";
import MarineFisherySubsectorSelect from "@components/MarineFisherySubsector/partials/MarineFisherySubsectorSelect";
import FishingTechniqueEUSelect from "@components/FishingTechniqueEU/partials/FishingTechniqueEUSelect";
import GearClassSelect from "@components/GearClass/partials/GearClassSelect";

const CreateGear = ({ onAdded }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [matchingCodeGear, setMatchingCodeGear] = useState("");
  const [gearStd, setGearStd] = useState("");
  const [description, setDescription] = useState("");
  const [selectedGearClass, setSelectedGearClass] = useState();
  const [selectedGearEU, setSelectedGearEU] = useState();
  const [selectedFishingTechniqueEU, setSelectedFishingTechniqueEU] =
    useState();
  const [selectedMarineFisherySubSector, setSelectedMarineFisherySubSector] =
    useState();

  const closeModal = () => {
    setShowModal(false);
    setName("");
    setDescription("");
    setNameEn("");
    setGearStd("");
    setMatchingCodeGear("");
    setSelectedGearClass("");
    setSelectedGearEU("");
    setSelectedFishingTechniqueEU("");
    setSelectedMarineFisherySubSector("");
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    GearService.create(
      name,
      nameEn,
      description,
      matchingCodeGear,
      selectedGearEU,
      selectedGearClass,
      gearStd,
      selectedFishingTechniqueEU,
      selectedMarineFisherySubSector
    )
      .then(() => {
        showSuccess(t("gearCreatedSuccessfully"));
        closeModal();
        onAdded();
        setShowModal(false);
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PermissionHandler permission={"create_gear"}>
      <AddButton label={t("addGear")} onClick={() => setShowModal(true)} />
      <AddModal
        show={showModal}
        title={t("addGear")}
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}
      >
        <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5 sm:gap-10">
          <Input
            height="h-10"
            label={t("name")}
            placeholder={t("name")}
            value={name}
            onChange={setName}
          />
          <Input
            height="h-10"
            label={t("nameEng")}
            placeholder={t("nameEng")}
            value={nameEn}
            onChange={setNameEn}
          />
          <div>
            <div className="text-sm font-semibold mb-2 flex">
              {t("gearsEU")}
            </div>
            <GearEUSelect
              openModal={showModal}
              onSelect={setSelectedGearEU}
              selected={selectedGearEU}
            />
          </div>
          <div>
            <div className="text-sm font-semibold mb-2 flex">
              {t("marineFisherySubSectors")}
            </div>
            <MarineFisherySubsectorSelect
              openModal={showModal}
              onSelect={setSelectedMarineFisherySubSector}
              selected={selectedMarineFisherySubSector}
            />
          </div>
          <div>
            <div className="text-sm font-semibold mb-2 flex">
              {t("fishingTechniquesEU")}
            </div>
            <FishingTechniqueEUSelect
              openModal={showModal}
              onSelect={setSelectedFishingTechniqueEU}
              selected={selectedFishingTechniqueEU}
            />
          </div>
          <div>
            <div className="text-sm font-semibold mb-2 flex">
              {t("gearClasses")}
            </div>
            <GearClassSelect
              openModal={showModal}
              onSelect={setSelectedGearClass}
              selected={selectedGearClass}
            />
          </div>
          <Input
            height="h-10"
            label={t("matchingCodeGear")}
            placeholder={t("matchingCodeGear")}
            value={matchingCodeGear}
            onChange={setMatchingCodeGear}
          />
          <Input
            height="h-10"
            label={t("gearStd")}
            placeholder={t("gearStd")}
            value={gearStd}
            onChange={setGearStd}
          />
          <Input
            height="h-10"
            label={t("description")}
            placeholder={t("description")}
            value={description}
            onChange={setDescription}
          />
        </div>
      </AddModal>
    </PermissionHandler>
  );
};

export default CreateGear;
