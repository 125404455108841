import React, {useEffect, useState} from 'react';
import AddButton from "@core/buttons/atoms/AddButton";
import AddModal from "@core/modals/AddModal";
import Input from "@core/inputs/Input"
import {showError, showSuccess} from "@utils/helpers";
import {useTranslation} from "react-i18next";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import OwnerService from "@services/OwnerService";
import CompanyTypeSelect from "@components/CompanyType/partials/CompanyTypeSelect";
import NumericalInput from "@core/inputs/NumericalInput";
import CountrySelect from "@components/Country/partials/CountrySelect";
import CaptaincySelect from "@components/Captaincy/partials/CaptaincySelect";
import MinorStrataSelect from "@components/MinorStrata/partials/MinorStrataSelect";

const CreateOwner = ({onAdded}) => {
  const {t} = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [companyType, setCompanyType] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [establishmentYear, setEstablishmentYear] = useState('');
  const [address, setAddress] = useState('');
  const [postCode, setPostCode] = useState('');
  const [town, setTown] = useState('');
  const [region, setRegion] = useState('');
  const [country, setCountry] = useState('');
  const [registrationOffice, setRegistrationOffice] = useState('');
  const [remarks, setRemarks] = useState('');

  const closeModal = () => {
    setShowModal(false)
    setCompanyType(null);
    setCompanyName('');
    setEstablishmentYear('');
    setAddress('');
    setPostCode('');
    setTown('');
    setRegion('');
    setCountry(null);
    setRegistrationOffice(null);
    setRemarks('');
  }

  const onSubmit = () => {
    setLoading(true)
    OwnerService.create(companyType, companyName, establishmentYear, address, postCode, town, region ? region : undefined, country ? country : undefined, registrationOffice, remarks).then(() => {
      showSuccess(t('ownerCreatedSuccessfully'));
      closeModal();
      onAdded();
      setShowModal(false);
    })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false)
      })
  };

  useEffect(() => {
    if (country !== "3") {
      setRegion(null)
    }
  }, [country])

  return (
    <PermissionHandler permission={'create_owner'}>
      <AddButton label={t('addOwner')} onClick={() => setShowModal(true)}/>
      <AddModal
        show={showModal}
        title={t('addOwner')}
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}
      >
        <div className='mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5'>
          <div>
            <div className="text-xs font-semibold mb-2 flex">
              {t('companyType')}
            </div>
            <CompanyTypeSelect
              openModal={showModal}
              onSelect={setCompanyType}
              selected={companyType}
            />
          </div>
          <Input
            height='h-10'
            label={t('companyName')}
            placeholder={t('companyName')}
            value={companyName}
            onChange={setCompanyName}
          />
          <NumericalInput
            height='h-10'
            label={t('establishedYear')}
            placeholder={t('establishedYear')}
            value={establishmentYear}
            onChange={setEstablishmentYear}
          />
          <div>
            <div className="text-xs font-semibold mb-2 flex">
              {t('registrationOffice')}
            </div>
            <CaptaincySelect
              openModal={showModal}
              onSelect={setRegistrationOffice}
              selected={registrationOffice}
            />
          </div>
          <Input
            height='h-10'
            label={t('address')}
            placeholder={t('address')}
            value={address}
            onChange={setAddress}
          />
          <Input
            height='h-10'
            label={t('postcode')}
            placeholder={t('postcode')}
            value={postCode}
            onChange={setPostCode}
          />
          <Input
            height='h-10'
            label={t('town')}
            placeholder={t('town')}
            value={town}
            onChange={setTown}
          />
          <div>
            <div className="text-xs font-semibold mb-2 flex">
              {t('region')}
            </div>
            <MinorStrataSelect
              disabled={country !== '3'}
              openModal={showModal}
              onSelect={setRegion}
              selected={region}
            />
          </div>
          <div>
            <div className="text-xs font-semibold mb-2 flex">
              {t('country')}
            </div>
            <CountrySelect
              openModal={showModal}
              onSelect={setCountry}
              selected={country}
            />
          </div>
          <Input
            height='h-10'
            label={t('remarks')}
            placeholder={t('remarks')}
            value={remarks}
            onChange={setRemarks}
          />
        </div>
      </AddModal>
    </PermissionHandler>
  )
}

export default CreateOwner;