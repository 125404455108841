import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import ShowDisposalType from "@components/DisposalType/ShowDisposalType";
import EditDisposalType from "@components/DisposalType/EditDisposalType";
import DisposalTypeService from "@services/DisposalTypeService";

const DisposalTypeActions = ({ disposalType, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowDisposalType disposalType={disposalType} />
      </div>
      <div className={"mr-2"}>
        <EditDisposalType disposalType={disposalType} onEdited={onEdited} />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={DisposalTypeService}
          id={disposalType.id}
          onDeleted={onDeleted}
          permission={"delete_disposal_type"}
        />
      </div>
    </div>
  );
};

export default DisposalTypeActions;
