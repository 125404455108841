import React, { useEffect, useState } from "react";
import ViewModal from "@core/modals/ViewModal";
import ViewButton from "@core/buttons/atoms/ViewButton";
import RowDetails from "@hoc/cruds/RowDetails";
import { useTranslation } from "react-i18next";
import FishingZoneService from "@services/FishingZoneService";
import PermissionHandler from "@hoc/cruds/PermissionHandler";

const ShowFishingZone = ({ fishingZone }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [fishingZoneData, setFishingZoneData] = useState([]);

  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (showModal)
      FishingZoneService.find(fishingZone.id).then((response) => {
        setFishingZoneData(response.data.data);
      });
  }, [fishingZone.id, showModal]);

  return (
    <PermissionHandler permission={"view_fishing_zones"}>
      <ViewButton onClick={() => setShowModal(true)} />
      <ViewModal
        show={showModal}
        title={`${t("overview")}`}
        onClose={closeModal}
      >
        <div className="flex flex-col mt-5">
          <RowDetails label={t("name")} value={fishingZoneData.name} />
          <RowDetails label={t("nameEng")} value={fishingZoneData.name_eng} />
          <RowDetails
            label={t("description")}
            value={fishingZoneData.description}
          />
        </div>
      </ViewModal>
    </PermissionHandler>
  );
};

export default ShowFishingZone;
