import React, { useEffect, useState } from "react";
import ViewModal from "@core/modals/ViewModal";
import ViewButton from "@core/buttons/atoms/ViewButton";
import RowDetails from "@hoc/cruds/RowDetails";
import { useTranslation } from "react-i18next";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import InactivityReasonService from "@services/InactivityReasonService";

const ShowInactivityReason = ({ inactivityReason }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [inactivityReasonData, setInactivityReasonData] = useState([]);

  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (showModal)
      InactivityReasonService.find(inactivityReason.id).then((response) => {
        setInactivityReasonData(response.data.data);
      });
  }, [inactivityReason.id, showModal]);

  return (
    <PermissionHandler permission={"view_inactivity_reasons"}>
      <ViewButton onClick={() => setShowModal(true)} />
      <ViewModal
        show={showModal}
        title={`${t("overview")}`}
        onClose={closeModal}
      >
        <div className="flex flex-col mt-5">
          <RowDetails label={t("name")} value={inactivityReasonData.name} />
          <RowDetails
            label={t("nameEng")}
            value={inactivityReasonData.name_eng}
          />
          <RowDetails
            label={t("description")}
            value={inactivityReasonData.description}
          />
        </div>
      </ViewModal>
    </PermissionHandler>
  );
};

export default ShowInactivityReason;
