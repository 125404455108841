import React from "react";
import Layout from "@hoc/layouts/Layout";
import EquipmentCategoriesIndex from "@components/EquipmentCategory/EquipmentCategoriesIndex";

const EquipmentCategoriesPage = () => {
  return (
    <Layout>
      <EquipmentCategoriesIndex />
    </Layout>
  );
};

export default EquipmentCategoriesPage;
