import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import FishingZoneService from "@services/FishingZoneService";

const FishingZoneSelect = ({ onSelect, selected, disabled = false, isClearable = false }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [selectedObject, setSelectedObject] = useState();

  const handleChangeSelected = (item, triggeredAction) => {
    if (triggeredAction.action === 'clear'){
      setSelectedObject();
      onSelect();
    }
    setSelectedObject(item);
    onSelect && onSelect(item.id);
  };

  useEffect(() => {
    FishingZoneService.all().then((response) => {
      const items = response.data?.data;
      const mappedOptions = items.map((item) => {
        return { value: item.id, ...item };
      });
      setOptions(mappedOptions);
    });
  }, []);

  useEffect(() => {
    const foundItem = options?.find((item) => item.id === selected);
    setSelectedObject(foundItem ? foundItem : null);
  }, [selected, options]);

  return (
    <Select
      isClearable={isClearable}
      isDisabled={disabled}
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      isSearchable={false}
      options={options}
      onChange={handleChangeSelected}
      value={selectedObject}
      placeholder={t("chooseFishingZone")}
      getOptionLabel={(fishingZone) => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span style={{ marginLeft: 5 }}>{fishingZone.name}</span>
          <span>{fishingZone.description}</span>
        </div>
      )}
    />
  );
};

export default FishingZoneSelect;
