import React from "react";
import Layout from "@hoc/layouts/Layout";
import WinchTypesIndex from "@components/WinchType/WinchTypesIndex";

const WinchTypesPage = () => {
  return (
    <Layout>
      <WinchTypesIndex />
    </Layout>
  );
};

export default WinchTypesPage;
