import React, { useEffect, useState } from "react";
import CensusService from "@services/CensusService";
import CensusLogs from "@components/Census/partials/CensusLogs";
import LogoHeader from "@hoc/partials/LogoHeader";

const HomePage = () => {
  const [censuses, setCensuses] = useState([]);
  const [selectedCensus, setSelectedCensus] = useState();

  useEffect(() => {
    CensusService.all({
      "no-pagination": true,
    }).then((response) => {
      setCensuses(response.data.data);
    });
  }, []);

  return (
    <div>
      <div className={"min-h-screen flex flex-row bg-gray-100"}>
        <div className={"flex-1"}>
          <LogoHeader />
          <div className="m-3">
            <div className="justify-between items-center mb-5 py-10">
              <div className="w-2/4 mx-auto pb-5">
                <CensusLogs
                  logs={censuses}
                  clickedReq={selectedCensus}
                  setClickedReq={setSelectedCensus}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
