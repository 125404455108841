import React, { useEffect, useState } from "react";
import ViewModal from "@core/modals/ViewModal";
import ViewButton from "@core/buttons/atoms/ViewButton";
import RowDetails from "@hoc/cruds/RowDetails";
import { useTranslation } from "react-i18next";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import FleetSegmentationService from "@services/FleetSegmentationService";

const ShowFleetSegmentation = ({ fleetSegmentation }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [fleetSegmentationData, setFleetSegmentation] = useState([]);

  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (showModal)
      FleetSegmentationService.find(fleetSegmentation.id).then((response) => {
        setFleetSegmentation(response.data.data);
      });
  }, [fleetSegmentation.id, showModal]);

  return (
    <PermissionHandler permission={"view_fleet_segmentations"}>
      <ViewButton onClick={() => setShowModal(true)} />
      <ViewModal
        show={showModal}
        title={`${t("overview")}`}
        onClose={closeModal}
      >
        <div className="flex flex-col mt-5">
          <RowDetails label={t("name")} value={fleetSegmentationData.name} />
          <RowDetails
            label={t("nameEng")}
            value={fleetSegmentationData.name_eng}
          />
          <RowDetails
            label={t("description")}
            value={fleetSegmentationData.description}
          />
          <RowDetails
            label={t("alphaCode")}
            value={fleetSegmentationData.alpha_code}
          />
        </div>
      </ViewModal>
    </PermissionHandler>
  );
};

export default ShowFleetSegmentation;
