import React from "react";
import Layout from "@hoc/layouts/Layout";
import SubAreasIndex from "@components/SubArea/SubAreasIndex";

const SubAreasPage = () => {
  return (
    <Layout>
      <SubAreasIndex />
    </Layout>
  );
};

export default SubAreasPage;
