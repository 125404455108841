import React from "react";
import Layout from "@hoc/layouts/Layout";
import VesselTypesFFFAOIndex from "@components/VesselTypeFFFAO/VesselTypesFFFAOIndex";

const VesselTypesFFFAOPage = () => {
  return (
    <Layout>
      <VesselTypesFFFAOIndex />
    </Layout>
  );
};

export default VesselTypesFFFAOPage;
