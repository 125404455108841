import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import CapacityUnitService from "@services/CapacityUnitService";
import ShowCapacityUnit from "@components/CapacityUnit/ShowCapacityUnit";
import EditCapacityUnit from "@components/CapacityUnit/EditCapacityUnit";

const CapacityUnitActions = ({ capacityUnit, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowCapacityUnit capacityUnit={capacityUnit} />
      </div>
      <div className={"mr-2"}>
        <EditCapacityUnit capacityUnit={capacityUnit} onEdited={onEdited} />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={CapacityUnitService}
          id={capacityUnit.id}
          onDeleted={onDeleted}
          permission={"delete_capacity_unit"}
        />
      </div>
    </div>
  );
};

export default CapacityUnitActions;
