import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Plus } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
import Operation from "@components/Vessel/partials/Operation";

const VesselOperations = ({ disabled = false, data, onChange, required = true, isClearable = false }) => {
  const { t } = useTranslation();

  const addOperation = () => {
    const operation = {
      gear_type_id: "",
      begin_month_id: "",
      end_month_id: "",
      trips_per_month: "",
      fishing_zone_id: "",
      target_group_of_species: "",
      remarks: "",
    };
    onChange([...data, operation]);
  };

  const handleDelete = (index) => {
    const newOperation = data.filter((operation) => operation !== data[index]);
    onChange(newOperation);
  };

  const updateOperation = (index, operation) => {
    const newOperation = [...data];
    newOperation[index] = operation;
    onChange(newOperation);
  };

  return (
    <div>
      <div className="text-sm font-bold mb-7 mt-7">{t("operations")}</div>
      <div className="flex flex-col gap-5">
        {data.map((operation) => (
          <Operation
            isClearable={isClearable}
            required={required}
            disabled={disabled}
            data={operation}
            key={data.indexOf(operation)}
            onDelete={handleDelete}
            onChange={updateOperation}
            index={data.indexOf(operation)}
          />
        ))}
      </div>
      {!disabled && (
        <button onClick={addOperation}>
          <div className="flex text-indigo-600 mt-2">
            <Plus />
            <div className="ml-2 font-semibold">{t("addOperation")}</div>
          </div>
        </button>
      )}
    </div>
  );
};

export default VesselOperations;
