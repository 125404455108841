import React from "react";
import Log from "@components/Census/partials/Log";
import { useTranslation } from "react-i18next";

const CensusLogs = ({ logs, setClickedReq }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex items-center mb-2">
        <div className="text-xl font-semibold text-gray-600">
          {t("chooseCensus")}
        </div>
        <hr className={"border border-gray-400 flex-1 ml-4"} />
      </div>
      <div
        className={`${
          logs.length > 8 &&
          "h-120 overflow-y-scroll rounded-xl bg-white shadow-lg"
        }`}
      >
        {logs.map((census) => (
          <Log
            key={census.id}
            census={census}
            name={census.name.toUpperCase()}
            time={census.created_at}
            handleClick={setClickedReq}
          />
        ))}
      </div>
    </div>
  );
};

export default CensusLogs;
