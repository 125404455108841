import React from "react";
import { Switch, BrowserRouter as Router, Redirect } from "react-router-dom";
import CensusesPage from "@pages/CensusesPage";
import LoginPage from "@pages/LoginPage";
import GuestRoute from "@router/GuestRoute";
import UsersPage from "@pages/UsersPage";
import AuthRoute from "@router/AuthRoute";
import RolesPage from "@pages/RolesPage";
import HomePage from "@pages/HomePage";
import FishingZonesPage from "@pages/FishingZonesPage";
import PortTypesPage from "@pages/PortTypesPage";
import StratasPage from "@pages/StratasPage";
import IssuingOfficesPage from "@pages/IssuingOfficesPage";
import AuthorizationTypesPage from "@pages/AuthorizationTypesPage";
import SubStratasPage from "@pages/SubStratasPage";
import VesselsPage from "@pages/Vessel/VesselsPage";
import CreateVesselPage from "@pages/Vessel/CreateVesselPage";
import ShowVesselPage from "@pages/Vessel/ShowVesselPage";
import MinorStratasPage from "@pages/MinorStratasPage";
import InactivityReasonsPage from "@pages/InactivityReasonsPage";
import CountriesPage from "@pages/CountriesPage";
import CaptainciesPages from "@pages/CaptainciesPages";
import EditVesselPage from "@pages/Vessel/EditVesselPage";
import OperationalStatusesPage from "@pages/OperationalStatusesPage";
import SubAreasPage from "@pages/SubAreasPage";
import StatisticalDivisionsPage from "@pages/StatisticalDivisionsPage";
import PortsPage from "@pages/PortsPage";
import HullMaterialsEUPage from "@pages/HullMaterialsEUPage";
import HullMaterialsPage from "@pages/HullMaterialsPage";
import GearClassesPage from "@pages/GearClassesPage";
import GroupOfSpeciesGFCMPage from "@pages/GroupOfSpeciesGFCMPage";
import EnergySourcesPage from "@pages/EnergySourcesPage";
import GroupOfSpeciesPage from "@pages/GroupOfSpeciesPage";
import EquipmentCategoriesPage from "@pages/EquipmentCategoriesPage";
import EngineLocationsPage from "@pages/EnergyLocationsPage";
import VesselTypesFFFAOPage from "@pages/VesselTypesFFFAOPage";
import VesselTypesPage from "@pages/VesselTypes";
import EquipmentPage from "@pages/EquipmentPage";
import WinchTypesPage from "@pages/WinchTypesPage";
import CapacityUnitsPage from "@pages/CapacityUnitsPage";
import DisposalTypesPage from "@pages/DisposalTypesPage";
import GeoSubAreasPage from "@pages/GeoSubAreasPage";
import WinchOperationPage from "@pages/WinchOperationPage";
import GearActivationMethodPage from "@pages/GearActivationMethodPage";
import CompanyTypesPage from "@pages/CompanyTypesPage";
import GearsEUPage from "@pages/GearsEUPage";
import OwnersPage from "@pages/OwnersPage";
import FishingTechniquesEUPage from "@pages/FishingTechniquesEUPage";
import MarineFisherySubSectorsPage from "@pages/MarineFisherySubSectorsPage";
import GearsPage from "@pages/GearsPage";
import FleetSegmentationsPage from "@pages/FleetSegmentationsPage";

const Routes = () => {
  //TODO: Pass the right permission
  return (
    <Router>
      <Switch>
        <GuestRoute exact path="/login" component={LoginPage} />
        <AuthRoute exact path="/" component={HomePage} />
        <AuthRoute
          exact
          path="/configurations/censuses"
          component={CensusesPage}
        />
        <AuthRoute
          exact
          path="/configurations/users"
          component={UsersPage}
          permission={["view_users"]}
        />
        <AuthRoute
          exact
          path="/configurations/roles"
          component={RolesPage}
          permission={["view_roles"]}
        />
        <AuthRoute
          exact
          path="/configurations/fleet-segmentations"
          component={FleetSegmentationsPage}
          permission={["view_fleet_segmentations"]}
        />
        <AuthRoute
          exact
          path="/configurations/ports"
          component={PortsPage}
          permission={["view_ports"]}
        />
        <AuthRoute
          exact
          path="/configurations/port-types"
          component={PortTypesPage}
          permission={["view_port_types"]}
        />
        <AuthRoute
          exact
          path="/configurations/issuing-offices"
          component={IssuingOfficesPage}
          permission={["view_issuing_offices"]}
        />
        <AuthRoute
          exact
          path="/configurations/stratas"
          component={StratasPage}
          permission={["view_strata"]}
        />
        <AuthRoute
          exact
          path="/configurations/sub-stratas"
          component={SubStratasPage}
          permission={["view_sub_strata"]}
        />
        <AuthRoute
          exact
          path="/configurations/minor-stratas"
          component={MinorStratasPage}
          permission={["view_minor_strata"]}
        />
        <AuthRoute
          exact
          path="/configurations/fishing-zones"
          component={FishingZonesPage}
          permission={["view_fishing_zones"]}
        />
        <AuthRoute
          exact
          path="/census/:id"
          component={VesselsPage}
          permission={["view_strata"]}
        />
        <AuthRoute
          exact
          path="/census/:id/show-vessel/:vesselId"
          component={ShowVesselPage}
          permission={["view_strata"]}
        />
        <AuthRoute
          exact
          path="/census/:id/edit-vessel/:vesselId"
          component={EditVesselPage}
          permission={["view_strata"]}
        />
        <AuthRoute
          exact
          path="/census/:id"
          component={VesselsPage}
          permission={["view_vessels"]}
        />
        <AuthRoute
          exact
          path="/census/:id/show-vessel/:vesselId"
          component={ShowVesselPage}
          permission={["view_vessels"]}
        />
        <AuthRoute
          exact
          path="/census/:id/edit-vessel/:vesselId"
          component={EditVesselPage}
          permission={["update_vessel"]}
        />
        <AuthRoute
          exact
          path="/census/:id/create-vessel"
          component={CreateVesselPage}
          permission={["create_vessel"]}
        />
        <AuthRoute
          exact
          path="/configurations/authorization-types"
          component={AuthorizationTypesPage}
          permission={["view_authorization_types"]}
        />
        <AuthRoute
          exact
          path="/configurations/inactivity-reasons"
          component={InactivityReasonsPage}
          permission={["view_inactivity_reasons"]}
        />
        <AuthRoute
          exact
          path="/configurations/countries"
          component={CountriesPage}
          permission={["view_countries"]}
        />
        <AuthRoute
          exact
          path="/configurations/captaincies"
          component={CaptainciesPages}
          permission={["view_captaincies"]}
        />
        <AuthRoute
          exact
          path="/configurations/sub-areas"
          component={SubAreasPage}
          permission={["view_sub_areas"]}
        />
        <AuthRoute
          exact
          path="/configurations/groups-of-species"
          component={GroupOfSpeciesPage}
          permission={["view_groups_of_species"]}
        />
        <AuthRoute
          exact
          path="/configurations/groups-of-species-gfcm"
          component={GroupOfSpeciesGFCMPage}
          permission={["view_group_of_species_gfcm"]}
        />
        <AuthRoute
          exact
          path="/configurations/operational-statuses"
          component={OperationalStatusesPage}
          permission={["view_operational_statuses"]}
        />
        <AuthRoute
          exact
          path="/configurations/statistical-divisions"
          component={StatisticalDivisionsPage}
          permission={["view_statistical_divisions"]}
        />
        <AuthRoute
          exact
          path="/configurations/hull-materials-eu"
          component={HullMaterialsEUPage}
          permission={["view_hull_materials_eu"]}
        />
        <AuthRoute
          exact
          path="/configurations/hull-materials"
          component={HullMaterialsPage}
          permission={["view_hull_materials"]}
        />
        <AuthRoute
          exact
          path="/configurations/gear-classes"
          component={GearClassesPage}
          permission={["view_gear_classes"]}
        />
        <AuthRoute
          exact
          path="/configurations/energy-sources"
          component={EnergySourcesPage}
          permission={["view_energy_sources"]}
        />
        <AuthRoute
          exact
          path="/configurations/engine-locations"
          component={EngineLocationsPage}
          permission={["view_engine_locations"]}
        />
        <AuthRoute
          exact
          path="/configurations/equipment-categories"
          component={EquipmentCategoriesPage}
          permission={["view_equipment_categories"]}
        />
        <AuthRoute
          exact
          path="/configurations/vessel-types-fffao"
          component={VesselTypesFFFAOPage}
          permission={["view_vessel_types_fffao"]}
        />
        <AuthRoute
          exact
          path="/configurations/vessel-types"
          component={VesselTypesPage}
          permission={["view_vessel_types"]}
        />
        <AuthRoute
          exact
          path="/configurations/equipments"
          component={EquipmentPage}
          permission={["view_equipments"]}
        />
        <AuthRoute
          exact
          path="/configurations/capacity-units"
          component={CapacityUnitsPage}
          permission={["view_capacity_units"]}
        />
        <AuthRoute
          exact
          path="/configurations/winch-types"
          component={WinchTypesPage}
          permission={["view_winch_types"]}
        />
        <AuthRoute
          exact
          path="/configurations/winch-operation-types"
          component={WinchOperationPage}
          permission={["view_winch_operation_types"]}
        />
        <AuthRoute
          exact
          path="/configurations/disposal-types"
          component={DisposalTypesPage}
          permission={["view_disposal_types"]}
        />
        <AuthRoute
          exact
          path="/configurations/gear-activation-methods"
          component={GearActivationMethodPage}
          permission={["view_gear_activation_methods"]}
        />
        <AuthRoute
          exact
          path="/configurations/geo-sub-areas"
          component={GeoSubAreasPage}
          permission={["view_geo_sub_areas"]}
        />
        <AuthRoute
          exact
          path="/configurations/company-types"
          component={CompanyTypesPage}
          permission={["view_company_types"]}
        />
        <AuthRoute
          exact
          path="/configurations/owners"
          component={OwnersPage}
          permission={["view_owners"]}
        />
        <AuthRoute
          exact
          path="/configurations/gear-eu"
          component={GearsEUPage}
          permission={["view_gears_eu"]}
        />
        <AuthRoute
          exact
          path="/configurations/fishing-techniques-eu"
          component={FishingTechniquesEUPage}
          permission={["view_fishing_techniques_eu"]}
        />
        <AuthRoute
          exact
          path="/configurations/marine-fishery-sub-sectors"
          component={MarineFisherySubSectorsPage}
          permission={["view_marine_fishery_sub_sectors"]}
        />
        <AuthRoute
          exact
          path="/configurations/gears"
          component={GearsPage}
          permission={["view_gears"]}
        />
        <Redirect from="*" to="/" />
      </Switch>
    </Router>
  );
};

export default Routes;
