import React, { useEffect, useState } from "react";
import ViewModal from "@core/modals/ViewModal";
import ViewButton from "@core/buttons/atoms/ViewButton";
import RowDetails from "@hoc/cruds/RowDetails";
import { useTranslation } from "react-i18next";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import CensusService from "@services/CensusService";

const ShowCensus = ({ census }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [censusData, setCensusData] = useState([]);

  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (showModal)
      CensusService.find(census.id).then((response) => {
        setCensusData(response.data.data);
      });
  }, [census.id, showModal]);

  return (
    <PermissionHandler permission={"view_census"}>
      <ViewButton onClick={() => setShowModal(true)} />
      <ViewModal
        show={showModal}
        title={`${t("overview")}`}
        onClose={closeModal}
      >
        <div className="flex flex-col mt-5">
          <RowDetails label={t("name")} value={censusData.name || "-"} />
          <RowDetails
            label={t("createdAt")}
            value={censusData.created_at || "-"}
          />
          <RowDetails
            label={t("creatingUser")}
            value={censusData.create_user || "-"}
          />
          <RowDetails
            label={t("sealedAt")}
            value={censusData.closed_at || "-"}
          />
          <RowDetails
            label={t("sealingUser")}
            value={censusData.close_user || "-"}
          />
        </div>
      </ViewModal>
    </PermissionHandler>
  );
};

export default ShowCensus;
