import API from "@utils/plugins/API";

const VesselService = {
  all: (id, params) => {
    return API.get(`vessels?census-id=${id}`, { params: params });
  },
  find: (id) => {
    return API.get(`vessels/${id}`);
  },
  create: (payload) => {
    return API.post("vessels", payload);
  },
  update: (id, payload) => {
    return API.put(`vessels/${id}`, payload);
  },
  destroy: (id) => {
    return API.delete(`vessels/${id}`);
  },
  advancedSearch: (id, advancedSearch, params) => {
    return API.post('vessels-advance-search', {"census-id": id, ...advancedSearch}, {params: params});
  },
  simpleSearch: (id, simple_search, params) => {
    return API.post(`vessels-simple-search`, {"census-id": id, simple_search}, {params: params})
  }
};

export default VesselService;
