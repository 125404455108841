import API from "@utils/plugins/API";

const PortTypeService = {
  all: (params) => {
    return API.get("port-types", { params: params });
  },
  create: (name, name_eng, description) => {
    return API.post("port-types", {
      name,
      name_eng,
      description,
    });
  },
  update: (id, name, name_eng, description) => {
    return API.put(`port-types/${id}`, {
      name,
      name_eng,
      description,
    });
  },
  find: (id) => {
    return API.get(`port-types/${id}`);
  },
  destroy: (id) => {
    return API.delete(`port-types/${id}`);
  },
};
export default PortTypeService;
