import API from "@utils/plugins/API";

const GroupOfSpeciesGFCMService = {
  all: (params) => {
    return API.get("groups-of-species-gfcm", { params: params });
  },
  create: (name, name_eng, description) => {
    return API.post("groups-of-species-gfcm", {
      name,
      name_eng,
      description,
    });
  },
  update: (id, name, name_eng, description) => {
    return API.put(`groups-of-species-gfcm/${id}`, {
      name,
      name_eng,
      description,
    });
  },
  find: (id) => {
    return API.get(`groups-of-species-gfcm/${id}`);
  },
  destroy: (id) => {
    return API.delete(`groups-of-species-gfcm/${id}`);
  },
};

export default GroupOfSpeciesGFCMService;
