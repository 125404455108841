import React, {useEffect} from "react";
import VesselsIndex from "@components/Vessel/VesselsIndex";
import LogoHeader from "@hoc/partials/LogoHeader";
import {Link, useHistory, useParams} from "react-router-dom";
import {FaArrowLeft} from "react-icons/fa";
import {useTranslation} from "react-i18next";

const VesselsPage = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const {id} = useParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkID = () => {
    if (!id) {
      return history.push("/");
    }
  };

  useEffect(() => {
    checkID();
  }, [checkID]);

  return (
    <div>
      <div className={"min-h-screen flex flex-row bg-gray-100"}>
        <div className={"flex-1"}>
          <LogoHeader/>
          <div className="m-3">
            <VesselsIndex/>
          </div>
        </div>
      </div>
      <div className="sticky w-full shadow border-t bottom-0 bg-white flex items-center justify-end py-3 px-3">
        <Link to={'/'}>
          <div className="flex cursor-pointer mr-5">
            <FaArrowLeft className="mt-1.5 mr-2"/>
            {t("goBack")}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default VesselsPage;
