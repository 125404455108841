import { ADD_SELECTDATA, REMOVE_SELECTDATA } from "@constants/redux";

export const addSelectData = (payload) => {
  return {
    type: ADD_SELECTDATA,
    payload,
  };
};

export const removeSelectData = () => {
  return {
    type: REMOVE_SELECTDATA,
  };
};
