import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Plus, Search} from "tabler-icons-react";
import Datatable from "@core/table/Datatable";
import OwnerService from "@services/OwnerService";
import BlankModal from "@core/modals/BlankModal";
import {FaPlus} from "react-icons/fa";

const SearchOwner = ({onAdded, owners}) => {
  const {t} = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [metaData, setMetaData] = useState();
  const [ownership, setOwnership] = useState([]);

  const closeModal = () => {
    setShowModal(false)
  }

  const headers = useMemo(
    () => [
      {
        id: "companyName",
        name: t('Company Name'),
        cell: (row) => row?.company_name,
      },
      {
        id: "companyType",
        name: t('Company Type'),
        cell: (row) => row?.company_type,
      },
      {
        id: "country",
        name: t('Country'),
        cell: (row) => row?.country,
      },
      {
        id: "registrationOffice",
        name: t('Registration Office'),
        cell: (row) => row?.registration_office,
      },
      {
        id: "actions",
        name: t('actions'),
        width: '80px',
        cell: (row) => (
          <button
            className="text-green-600 cursor-pointer"
            onClick={() => {
              onAdded({
                owner_id: row.id,
                owner: row
              })
              closeModal();
            }}
          >
            <Plus/>
          </button>
        ),
      },
    ],
    [onAdded, t]
  );

  const getData = useCallback((params) => {
    if (showModal) {
    setLoading(true)
    OwnerService.all(params).then((response) => {
      setMetaData(response.data.metadata)
      setData(response.data.data)
      setLoading(false)
    });
  }}, [showModal])

  useEffect(() => {
    const selectedOwners = owners?.map((owner) => owner.owner_id);
    const filteredOwners = data.filter(
      (owner) => !selectedOwners?.includes(owner.id),
    )
    setOwnership(filteredOwners)
  }, [data, owners])

  return (
    <div>
      <button onClick={() => setShowModal(true)}>
        <div className="flex text-indigo-600 items-center">
          <Search/>
          <div className="ml-2 font-semibold">{t('searchOwner')}</div>
        </div>
      </button>
      <BlankModal
        icon={<FaPlus className={'text-primary-500'}/>}
        show={showModal}
        title={`${t('add')} ${t('Owner')}`}
        onClose={closeModal}
        loading={loading}
      >
        <Datatable
          data={ownership}
          headers={headers}
          totalRows={metaData?.total}
          getData={getData}
          isLoading={loading}
          extraDependencies={[showModal]}
        />
      </BlankModal>
    </div>

  )
}

export default SearchOwner;
