import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import EditFleetSegmentation from "@components/FleetSegmentation/EditFleetSegmentation";
import ShowFleetSegmentation from "@components/FleetSegmentation/ShowFleetSegmentation";
import FleetSegmentationService from "@services/FleetSegmentationService";

const FleetSegmentationActions = ({
                                    fleetSegmentation,
                                    onEdited,
                                    onDeleted,
                                  }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowFleetSegmentation fleetSegmentation={fleetSegmentation}/>
      </div>
      <div className={"mr-2"}>
        <EditFleetSegmentation
          fleetSegmentation={fleetSegmentation}
          onEdited={onEdited}
        />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={FleetSegmentationService}
          id={fleetSegmentation.id}
          onDeleted={onDeleted}
          permission={"delete_fleet_segmentation"}
        />
      </div>
    </div>
  );
};

export default FleetSegmentationActions;
