import React from "react";
import { useTranslation } from "react-i18next";
import Input from "@core/inputs/Input";
import NumericalInput from "@core/inputs/NumericalInput";
import ApiSelect from "@core/dropdowns/ApiSelect";
import HullMaterialService from "@services/HullMaterialService";
import CountryService from "@services/CountryService";

const VesselStructuralCharacteristics = ({
  disabled = false,
  data,
  onChange,
  required = true,
  isClearable = false,
}) => {
  const { t } = useTranslation();

  const handleChange = (value, name) => {
    onChange({ ...data, [name]: value });
  };

  return (
    <>
      <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
        <NumericalInput
          disabled={disabled}
          required={required}
          height="h-10"
          label={t("length")}
          placeholder={t("length")}
          value={data?.length}
          onChange={(val) => handleChange(val, "length")}
        />
        <NumericalInput
          disabled={disabled}
          height="h-10"
          label={t("width")}
          placeholder={t("width")}
          value={data?.width}
          onChange={(val) => handleChange(val, "width")}
        />
        <NumericalInput
          disabled={disabled}
          height="h-10"
          label={t("depth")}
          placeholder={t("depth")}
          value={data?.depth}
          onChange={(val) => handleChange(val, "depth")}
        />
        <div>
          <div className="text-sm font-semibold mb-2">
            {t("constructionCountry")}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={"constructionCountry"}
            disabled={disabled}
            placeholder={t("chooseConstructionCountry")}
            service={CountryService}
            onSelect={(val) => handleChange(val, "construction_country_id")}
            selected={data?.construction_country_id}
            extraParams={{
              "sort-by": "Name",
              "no-pagination": true,
            }}
          />
        </div>
        <Input
          disabled={disabled}
          height="h-10"
          label={t("constructionYear")}
          placeholder={t("constructionYear")}
          value={data?.construction_year === -1 ? "" : data?.construction_year}
          onChange={(val) => handleChange(val, "construction_year")}
        />
        <div>
          <div className="text-sm font-semibold mb-2">{t("hullMaterial")}</div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={"hullMaterial"}
            disabled={disabled}
            service={HullMaterialService}
            onSelect={(val) => handleChange(val, "hull_material_id")}
            selected={data?.hull_material_id}
          />
        </div>
      </div>
      <div className="flex items-center mt-5">
        <input
          disabled={disabled}
          type="checkbox"
          checked={data?.decked}
          value={data?.decked}
          onChange={() => handleChange(!data?.decked, "decked")}
          className="w-4 h-4 text-indigo-700 bg-gray-100 rounded border-gray-300 focus:ring-indigo-500 focus:ring-2"
        />
        <label className="ml-2 text-base text-gray-700">{t("decked")}</label>
      </div>
      <div className="mt-4 mb-2 grid grid-cols-3 gap-5">
        <NumericalInput
          disabled={disabled}
          height="h-10"
          label={t("grt")}
          placeholder={t("grt")}
          value={data?.grt}
          onChange={(val) => handleChange(val, "grt")}
        />
        <NumericalInput
          disabled={disabled}
          required={required}
          height="h-10"
          label={t("gt")}
          placeholder={t("gt")}
          value={data?.gt}
          onChange={(val) => handleChange(val, "gt")}
        />
        <NumericalInput
          disabled={disabled}
          height="h-10"
          label={t("gts")}
          placeholder={t("gts")}
          value={data?.gts}
          onChange={(val) => handleChange(val, "gts")}
        />
      </div>
      <Input
        disabled={disabled}
        height="h-10"
        label={t("remarks")}
        placeholder={t("remarks")}
        value={data?.remarks}
        onChange={(val) => handleChange(val, "remarks")}
      />
    </>
  );
};

export default VesselStructuralCharacteristics;
