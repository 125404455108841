import React from "react";
import Layout from "@hoc/layouts/Layout";
import OperationalStatusesIndex from "@components/OperationalStatus/OperationalStatusesIndex";

const OperationalStatuses = () => {
  return (
    <Layout>
      <OperationalStatusesIndex />
    </Layout>
  );
};

export default OperationalStatuses;
