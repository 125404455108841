import React from "react";
import Layout from "@hoc/layouts/Layout";
import GearActivationMethodsIndex from "@components/GearActivationMethod/GearActivationMethodsIndex";

const GearActivationMethodPage = () => {
  return (
    <Layout>
      <GearActivationMethodsIndex />
    </Layout>
  );
};

export default GearActivationMethodPage;
