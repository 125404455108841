import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import VesselTypeService from "@services/VesselTypeService";
import ShowVesselType from "@components/VesselType/ShowVesselType";
import EditVesselType from "@components/VesselType/EditVesselType";

const VesselTypeActions = ({ vesselType, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowVesselType vesselType={vesselType} />
      </div>
      <div className={"mr-2"}>
        <EditVesselType vesselType={vesselType} onEdited={onEdited} />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={VesselTypeService}
          id={vesselType.id}
          onDeleted={onDeleted}
          permission={"delete_vessel_type"}
        />
      </div>
    </div>
  );
};

export default VesselTypeActions;
