import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {FaFilePdf} from "react-icons/fa";
import DefaultButton from "@core/buttons/electrons/DefaultButton";
import {useParams} from "react-router-dom";
import PDFService from "@services/PDFService";
import PermissionHandler from "@hoc/cruds/PermissionHandler";

const Export = ({title, onClick}) => (
  <div className="p-1.5 hover:cursor-pointer">
    <div
      onClick={onClick}
      className="px-3 py-1.5 text-xs text-gray-800 rounded-lg hover:bg-orange-50 hover:text-gray-900 text-left flex"
    >
      {title}
    </div>
  </div>
);

const ExportPDF = () => {
  const {id} = useParams();
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const exportPdf = (reportType) => {
    PDFService.reports(id, reportType).then((response) => {
      window.open(response.data.data.url);
      setIsOpen(false);
    });
  }

  return (
    <PermissionHandler permission={"generate_reports"}>
      <div className="relative">
        <DefaultButton
          bgColor={"bg-orange-500"}
          bgColorHover={"hover:bg-orange-600"}
          xs
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          label={
            <div className={"flex items-center p-1"}>
              <FaFilePdf className={"mr-1"}/>
              {t("exportPDF")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 font-bold ml-3"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </div>
          }
        ></DefaultButton>
        {isOpen && (
          <div
            className="absolute right-0 z-10 mt-2 w-60 origin-top-right bg-white border border-gray-100 rounded-md shadow-lg">
            <Export
              title={t("distributionOfEquipment")}
              onClick={() => {
                exportPdf("equipment-distribution");
              }}
            />
            <Export
              title={t("distributionByPortVesselType")}
              onClick={() => {
                exportPdf("distribution-by-port-vessel-type");
              }}
            />
            <Export
              title={t("distributionByVesselTypePort")}
              onClick={() => {
                exportPdf("distribution-by-vessel-type-port");
              }}
            />
            <Export
              title={t("distributionOfVesselsByVesselType")}
              onClick={() => {
                exportPdf("distribution-of-vessels-by-vessel-type");
              }}
            />
            <Export
              title={t("distributionOfVesselsByMinorStratum")}
              onClick={() => {
                exportPdf("distribution-vessels-minor-stratum");
              }}
            />
            <Export
              title={t("distributionOfVesselsByTypeLength")}
              onClick={() => {
                exportPdf("distribution-vessel-type-length");
              }}
            />
            <Export
              title={t("distributionOfVesselByPowerVesselType")}
              onClick={() => {
                exportPdf("distribution-of-vessel-power-vessel-type");
              }}
            />
            <Export
              title={t("basicNumericalStatistics")}
              onClick={() => {
                exportPdf("basic-numerical-statistics");
              }}
            />
            <Export
              title={t("distributionOfVesselsByPort")}
              onClick={() => {
                exportPdf("distribution-of-vessels-by-port");
              }}
            />
            <Export
              title={t("allPdfReport")}
              onClick={() => {
                exportPdf("all-pdf-report");
              }}
            />
          </div>
        )}
      </div>
    </PermissionHandler>
  );
};

export default ExportPDF;
