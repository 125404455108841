import { combineReducers } from "redux";
import authenticationReducer from "./authentication/Reducer";
import meReducer from "./me/Reducer";
import spinnerReducer from "./spinner/Reducer";
import permissionsReducer from "@redux/permission/Reducer";
import selectDataReducer from "@redux/select/Reducer";

const Reducers = combineReducers({
  authenticationReducer,
  meReducer,
  spinnerReducer,
  permissionsReducer,
  selectDataReducer,
});

export default Reducers;
