import React, {useState} from "react";
import Input from "@core/inputs/Input";
import {useTranslation} from "react-i18next";
import {FaSearch} from "react-icons/fa";
import DefaultButton from "@core/buttons/electrons/DefaultButton";
import BlankModal from "@core/modals/BlankModal";
import SubmitButton from "@core/buttons/atoms/SubmitButton";
import ApiSelect from "@core/dropdowns/ApiSelect";
import VesselTypeService from "@services/VesselTypeService";
import PortService from "@services/PortService";
import GearClassSelect from "@components/GearClass/partials/GearClassSelect";
import GeoSubAreaSelect from "@components/GeoSubArea/partials/GeoSubAreaSelect";
import FleetSegmentationService from "@services/FleetSegmentationService";
import {Minus, Plus} from "tabler-icons-react";
import Gear from "@components/Vessel/search/Gear";
import OperationalStatusService from "@services/OperationalStatusService";

const SimpleSearch = ({
                        data,
                        onChange,
                        onSubmit,
                        clearAdvancedSearch
                      }) => {
  const {t} = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [filters, setFilters] = useState(data);
  const [gears, setGears] = useState(data.gears ? data.gears : []);

  const closeModal = () => {
    setShowModal(false);
    setFilters({});
    setGears([]);
  };

  const handleChange = (value, name) => {
    setFilters({...filters, [name]: value});
  };

  const addGear = () => {
    const gear = {
      gear_type_id: "",
      gear_class_id: "",
      include: true
    };
    setGears([...gears, gear]);
  };

  const handleDelete = (index) => {
    const newGear = gears.filter((gear) => gear !== gears[index]);
    setGears(newGear);
  };

  const handleUpdate = (index, gear) => {
    const newGear = [...gears];
    newGear[index] = gear;
    setGears(newGear);
  };

  const submit = () => {
    onSubmit();
    onChange({...filters, gears: gears.length === 0 ? null : gears});
    setShowModal(false);
    clearAdvancedSearch({})
  };

  const clearState = () => {
    setGears([]);
    setFilters({});
    onChange({});
    onSubmit();
  }

  return (
    <>
      <DefaultButton
        xs
        onClick={() => {
          setShowModal(true)
        }}
        bgColor={"bg-blue-600"}
        bgColorHover={"hover:bg-blue-700"}
        width={"w-34"}
        textColor={"text-white"}
        label={
          <div className={"flex items-center p-1"}>
            <FaSearch className={"mr-1"}/>
            {t("simpleSearch")}
          </div>
        }/>
      <BlankModal
        icon={<FaSearch className={"text-indigo-700"}/>}
        iconBg="bg-indigo-100"
        show={showModal}
        title={t("simpleSearch")}
        onClose={closeModal}
        label={t("simpleSearch")}
        otherButtons={[
          <SubmitButton onClick={submit} label={t("search")}/>,
        ]}
      >
        <div className="flex justify-end mr-4">
          {<button onClick={clearState}>
            <div className="flex text-blue-600 mt-2">
              <Minus/>
              <div className="ml-2 font-semibold">{t("resetFilters")}</div>
            </div>
          </button>}
        </div>
        <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
          <Input
            height="h-10"
            label={t("createUserName")}
            placeholder={t("createUserName")}
            value={filters?.create_user_name}
            onChange={(val) => handleChange(val, "create_user_name")}
          />
          <Input
            height="h-10"
            label={t("name")}
            placeholder={t("name")}
            value={filters?.name}
            onChange={(val) => handleChange(val, "name")}
          />
          <Input
            height="h-10"
            label={t("uniqueRegistrationNumber")}
            placeholder={t("uniqueRegistrationNumber")}
            value={filters?.unique_registration_number}
            onChange={(val) => handleChange(val, "unique_registration_number")}
          />
          <Input
            height="h-10"
            label={t("registrationNumber")}
            placeholder={t("registrationNumber")}
            value={filters?.registration_number}
            onChange={(val) => handleChange(val, "registration_number")}
          />
          <Input
            height="h-10"
            label={t("fisheryDepartmentRegistrationNumber")}
            placeholder={t("fisheryDepartmentRegistrationNumber")}
            value={filters?.fishery_department_registration_number}
            onChange={(val) => handleChange(val, "fishery_department_registration_number")}
          />
          <div>
            <div className="text-sm font-semibold mb-2">
              {t("vesselType")}
            </div>
            <ApiSelect
              showModal={showModal}
              isClearable
              reduxKey={"vesselType"}
              service={VesselTypeService}
              onSelect={(val) => handleChange(val, "type_id")}
              selected={filters?.type_id}
            />
          </div>
          <div>
            <div className="text-sm font-semibold mb-2">
              {t("homePort")}
            </div>
            <ApiSelect
              showModal={showModal}
              isClearable
              reduxKey={"homePort"}
              service={PortService}
              onSelect={(val) => handleChange(val, "home_port_id")}
              selected={filters?.home_port_id}
            />
          </div>
          <div>
            <div className="text-sm font-semibold mb-2">
              {t("gearClasses")}
            </div>
            <GearClassSelect
              isClearable
              openModal={showModal}
              onSelect={(val) => handleChange(val, "gear_class_id")}
              selected={filters?.gear_class_id}
            />
          </div>
          <div>
            <div className="text-sm font-semibold mb-2">
              {t("geoSubArea")}
            </div>
            <GeoSubAreaSelect
              isClearable
              openModal={showModal}
              onSelect={(val) => handleChange(val, "gsa_id")}
              selected={filters?.gsa_id}
            />
          </div>
          <div>
            <div className="text-sm font-semibold mb-2">
              {t("fleetSegmentations")}
            </div>
            <ApiSelect
              showModal={showModal}
              isClearable
              reduxKey={"fleetSegmentations"}
              service={FleetSegmentationService}
              onSelect={(val) => handleChange(val, "fleet_segmentation_id")}
              selected={filters?.fleet_segmentation_id}
            />
          </div>
          <div>
            <div className="text-sm font-semibold mb-2 flex">
              {t("operationalStatus")}
            </div>
            <ApiSelect
              isClearable
              reduxKey={"operationalStatuses"}
              service={OperationalStatusService}
              onSelect={(val) => handleChange(val, "operational_status_id")}
              selected={data?.operational_status_id}
            />
          </div>
          <Input
            height="h-10"
            label={t("casInclusions")}
            placeholder={t("casInclusions")}
            value={filters?.cas_inclusions}
            onChange={(val) => handleChange(val, "cas_inclusions")}
          />
          <Input
            height="h-10"
            label={t("lengthMin")}
            placeholder={t("lengthMin")}
            value={filters?.length_min}
            onChange={(val) => handleChange(val, "length_min")}
          />
          <Input
            height="h-10"
            label={t("lengthMax")}
            placeholder={t("lengthMax")}
            value={filters?.length_max}
            onChange={(val) => handleChange(val, "length_max")}
          />
          <Input
            height="h-10"
            label={t("gtMin")}
            placeholder={t("gtMin")}
            value={filters?.gt_min}
            onChange={(val) => handleChange(val, "gt_min")}
          />
          <Input
            height="h-10"
            label={t("gtMax")}
            placeholder={t("gtMax")}
            value={filters?.gt_max}
            onChange={(val) => handleChange(val, "gt_max")}
          />
          <Input
            height="h-10"
            label={t("powerMin")}
            placeholder={t("powerMin")}
            value={filters?.power_min}
            onChange={(val) => handleChange(val, "power_min")}
          />
          <Input
            height="h-10"
            label={t("powerMax")}
            placeholder={t("powerMax")}
            value={filters?.power_max}
            onChange={(val) => handleChange(val, "power_max")}
          />
          <Input
            height="h-10"
            label={t("constructionYearMin")}
            placeholder={t("constructionYearMin")}
            value={filters?.construction_year_min}
            onChange={(val) => handleChange(val, "construction_year_min")}
          />
          <Input
            height="h-10"
            label={t("constructionYearMax")}
            placeholder={t("constructionYearMax")}
            value={filters?.construction_year_max}
            onChange={(val) => handleChange(val, "construction_year_max")}
          />
        </div>
        <div className="text-sm font-bold mb-7 mt-7">{t("gears")}</div>
        <div className="flex flex-col gap-5">
          {gears?.map((gear, index) => (
            <Gear
              showModal={showModal}
              data={gear}
              key={index}
              onDelete={handleDelete}
              onChange={handleUpdate}
              index={index}
            />
          ))}
        </div>
        {<button onClick={addGear}>
          <div className="flex text-indigo-600 mt-2">
            <Plus/>
            <div className="ml-2 font-semibold">{t("searchGear")}</div>
          </div>
        </button>}
      </BlankModal>
    </>
  );
};

export default SimpleSearch;