import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import OwnerService from "@services/OwnerService";
import ShowOwner from "@components/Owner/ShowOwner";
import EditOwner from "@components/Owner/EditOwner";

const OwnerActions = ({ owner, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowOwner owner={owner} />
      </div>
      <div className={"mr-2"}>
        <EditOwner owner={owner} onEdited={onEdited} />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={OwnerService}
          id={owner.id}
          onDeleted={onDeleted}
          permission={"delete_owner"}
        />
      </div>
    </div>
  );
};

export default OwnerActions;
