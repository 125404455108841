import React from "react";
import Input from "@core/inputs/Input";
import {Trash} from "tabler-icons-react";
import NumericalInput from "@core/inputs/NumericalInput";
import {useTranslation} from "react-i18next";
import ApiSelect from "@core/dropdowns/ApiSelect";
import GroupOfSpeciesService from "@services/GroupOfSpeciesService";
import GearClassService from "@services/GearClassService";
import GearTypeSelect from "@components/GearType/partials/GearTypeSelect";

const Gear = ({data, index, onDelete, onChange, disabled = false, required, isClearable}) => {
  const {t} = useTranslation();

  const handleChange = (value, name) => {
    onChange(index, {...data, [name]: value});
  };

  return (
    <div key={index}>
      <div className="grid grid-cols-6 gap-5">
        <NumericalInput
          disabled={true}
          required={required}
          height="h-10"
          label={t("rank")}
          placeholder={t("rank")}
          value={index + 1}
          extraClasses={"text-xs"}
        />
        <div>
          <div className="text-xs font-semibold mb-2 flex">
            {t("gearClass")}
            {required && <div className="text-red-600 ml-1">*</div>}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={"gearClass"}
            disabled={disabled}
            service={GearClassService}
            onSelect={(val) => handleChange(val, "gear_class_id")}
            selected={data?.gear_class_id}
          />
        </div>
        <div>
          <div className="text-xs font-semibold mb-2 flex">
            {t("gearType")}
            {required && <div className="text-red-600 ml-1">*</div>}
          </div>
          <GearTypeSelect
            isClearable={isClearable}
            reduxKey={`gearType${index}`}
            disabled={disabled}
            gearClassId={data?.gear_class_id}
            onSelect={(val) => handleChange(val, "gear_type_id")}
            selected={data?.gear_type_id}
          />
        </div>
        <div>
          <div className="text-xs font-semibold mb-2 flex">
            {t("targetGroupOfSpecies")}
            {required && <div className="text-red-600 ml-1">*</div>}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={"targetGroupOfSpecies"}
            disabled={disabled}
            service={GroupOfSpeciesService}
            onSelect={(val) => handleChange(val, "target_group_of_species_id")}
            selected={data?.target_group_of_species_id}
          />
        </div>
        <Input
          disabled={disabled}
          height="h-10"
          label={t("remarks")}
          placeholder={t("remarks")}
          value={data?.remarks}
          onChange={(val) => handleChange(val, "remarks")}
          extraClasses={"text-xs"}
        />
        {!disabled && (
          <div className="flex self-center pt-5 flex-start">
            <button
              className="flex text-red-600 cursor-pointer ml-auto"
              onClick={() => onDelete(index)}
            >
              <Trash/>
            </button>
          </div>
        )}
      </div>
      <hr className="px-5 my-3"/>
    </div>
  );
};

export default Gear;
