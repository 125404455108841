import React, { useState } from "react";
import EditModal from "@core/modals/EditModal";
import EditButton from "@core/buttons/atoms/EditButton";
import Input from "@core/inputs/Input";
import CensusService from "@services/CensusService";
import { showError, showSuccess } from "@utils/helpers";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import { useTranslation } from "react-i18next";

const EditCensus = ({ census, onEdited }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState(census.name);
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setName("");
  };
  const handleClick = () => {
    setName(census.name);
    setShowModal(true);
  };
  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    CensusService.update(census.id, name)
      .then(() => {
        showSuccess(t("censusEditedSuccessfully"));
        onEdited();
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PermissionHandler permission={"update_census"}>
      <EditButton onClick={handleClick} />
      <EditModal
        show={showModal}
        title={`${t("editCensus")}`}
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}
      >
        <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
          <Input
            label={t("censusName")}
            placeholder={t("censusName")}
            value={name}
            onChange={setName}
            height="h-10"
          />
        </div>
      </EditModal>
    </PermissionHandler>
  );
};

export default EditCensus;
