import API from "@utils/plugins/API";

const MonthService = {
  all: (params) => {
    return API.get("months", { params: params });
  },
  create: (name, name_eng, description) => {
    return API.post("months", {
      name,
      description,
    });
  },
  update: (id, name, name_eng, description) => {
    return API.put(`months/${id}`, {
      name,
      description,
    });
  },
  find: (id) => {
    return API.get(`months/${id}`);
  },
};

export default MonthService;
