import React, {useEffect, useMemo, useState} from "react";
import Datatable from "@core/table/Datatable";
import MobileTable from "@core/table/MobileTable";
import {useTranslation} from "react-i18next";
import VesselActions from "@components/Vessel/partials/VesselActions";
import VesselService from "@services/VesselService";
import AddButton from "@core/buttons/atoms/AddButton";
import {useHistory} from "react-router-dom";
import {useParams} from "react-router-dom";
import Exports from "@components/Vessel/partials/Exports";
import {FaShip, FaWeightHanging} from "react-icons/fa";
import {GiFishingBoat, GiGears, GiHarborDock} from "react-icons/gi";
import {MdPeople} from "react-icons/md";
import CensusOverviewService from "@services/CensusOverviewService";
import {SkeletonItem} from "react-skeleton-loader-pulse";
import "react-loading-skeleton/dist/skeleton.css";
import ExportPDF from "@components/Vessel/partials/ExportPDF";
import SimpleSearch from "@components/Vessel/search/SimpleSearch";
import AdvancedSearch from "@components/Vessel/search/AdvancedSearch";

const VesselsIndex = () => {
  const {t} = useTranslation();
  const {id} = useParams();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [censusOverviewData, setCensusOverviewData] = useState({});
  const [metaData, setMetaData] = useState();
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updatedTable, setUpdatedTable] = useState(0);
  const [searchFilters, setSearchFilters] = useState({});
  const [advancedSearchFilters, setAdvancedSearchFilters] = useState({});

  const InfoItem = ({icon, amount, label, showSkeleton}) => (
    <div className="flex-col justify-center items-center flex w-auto text-center">
      <div>
        <div className="text-5xl text-center text-gray-700 mb-2">{icon}</div>
      </div>
      <div className="mt-2">
        {showSkeleton ? (
          <>
            <SkeletonItem
              borderRadius={5}
              pulseTime={1.5}
              marginLeft={45}
              width={50}
              height={20}
            />
            <SkeletonItem
              borderRadius={5}
              pulseTime={1.5}
              width={140}
              height={22}
              marginTop={5}
            />
          </>
        ) : (
          <>
            <p className="text-gray-700 text-lg font-bold text-center">
              {amount}
            </p>
            <p className="text-gray-700 text-lg font-bold text-left">{label}</p>
          </>
        )}
      </div>
    </div>
  );
  const paginationComponentOptions = {
    rowsPerPageText: "Vessels per page",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const headers = useMemo(
    () => [
      {
        id: "vesselcharacteristics.UniqueRegistrationNumber",
        name: t("uniqueRegistrationNumber"),
        cell: (row) => row.unique_registration_number,
        sortable: true,
      },
      {
        id: "vesselcharacteristics.Name",
        name: t("vesselName"),
        cell: (row) => row.name,
        sortable: true,
      },
      {
        id: "vesselcharacteristics.RegistrationNumber",
        name: t("registrationNumber"),
        cell: (row) => row.registration_number,
        maxWidth: "20px",
        sortable: true,
      },
      {
        id: "vesselauthorisation.FisheryDepartmentRegistrationNumber",
        name: t("fisheryDepartmentRegistrationNumber"),
        cell: (row) => row.fishery_department_registration_number,
        maxWidth: "20px",
        sortable: true,
      },
      {
        id: "refvesseltypes.Name",
        name: t("vesselType"),
        cell: (row) => row.type,
        maxWidth: "20px",
        sortable: true,
      },
      {
        id: "refports.Name",
        name: t("homePort"),
        cell: (row) => row.home_port,
        maxWidth: "20px",
        sortable: true,
      },
      {
        id: "refoperationalstatuses.Name",
        name: t("operationalStatus"),
        cell: (row) => row.operational_status,
        sortable: true,
      },
      {
        id: "vesselstructuralcharacteristics.Length",
        name: t("length"),
        cell: (row) => row.length,
        maxWidth: "20px",
        sortable: true,
      },
      {
        id: "vesselstructuralcharacteristics.GT",
        name: t("gt"),
        cell: (row) => row.gt,
        maxWidth: "20px",
        sortable: true,
      },
      {
        id: "actions",
        name: t("actions"),
        cell: (row) => (
          <VesselActions
            expiredAt={!!censusOverviewData?.expired_at}
            vessel={row}
            onDeleted={() => setUpdatedTable((prev) => prev + 1)}
          />
        ),
      },
    ],
    [t, censusOverviewData]
  );

  const getData = (params) => {
    setLoading(true);
    if (Object.keys(searchFilters).length !== 0) {
      VesselService.simpleSearch(id, {...searchFilters}, {...params}).then((response) => {
        setData(response.data.data);
        setMetaData(response.data.metadata);
        setLoading(false);
      });
    } else if (Object.keys(advancedSearchFilters).length !== 0) {
      VesselService.advancedSearch(id, advancedSearchFilters, {...params}).then((response) => {
      setData(response.data.data);
      setMetaData(response.data.metadata);
      setLoading(false);
    });
    } else {
      VesselService.all(id, {...params}).then((response) => {
        setData(response.data.data);
        setMetaData(response.data.metadata);
        setLoading(false);
      })
    }
  };

  useEffect(() => {
    setShowSkeleton(true);
    CensusOverviewService.all(id)
      .then((response) => {
        setCensusOverviewData(response.data.data);
      })
      .finally(() => {
        setShowSkeleton(false);
      });
  }, [id]);

  return (
    <div className="border bg-white rounded justify-between items-center mb-5 py-2">
      <div className="mx-3 my-5">
        <div className="flex items-center my-10">
          <hr className={"flex-1 border-gray-300"}/>
          <h1 className="text-center text-gray-700 text-lg font-bold mx-4">
            {t("overview")}
          </h1>
          <hr className={"flex-1 border-gray-300"}/>
        </div>
        <div className="flex justify-around items-center mb-10">
          <InfoItem
            icon={<FaShip/>}
            amount={censusOverviewData?.total_vessels}
            showSkeleton={showSkeleton}
            label={t("totalVessels")}
          />
          <InfoItem
            icon={<GiFishingBoat/>}
            amount={censusOverviewData?.vessel_types}
            showSkeleton={showSkeleton}
            label={t("vesselTypes")}
          />
          <InfoItem
            icon={<GiGears/>}
            amount={censusOverviewData?.total_power}
            showSkeleton={showSkeleton}
            label={t("totalEnginePower")}
          />
          <InfoItem
            icon={<GiHarborDock/>}
            amount={censusOverviewData?.total_ports}
            showSkeleton={showSkeleton}
            label={t("totalPorts")}
          />
          <InfoItem
            icon={<MdPeople/>}
            amount={censusOverviewData?.crew_members}
            showSkeleton={showSkeleton}
            label={t("crewMembers")}
          />
          <InfoItem
            icon={<FaWeightHanging/>}
            amount={censusOverviewData?.total_gross_tonage}
            showSkeleton={showSkeleton}
            label={t("grossTonageTotal")}
          />
        </div>
        <div className="flex items-center mt-15 mb-5">
          <hr className={"flex-1 border-gray-300"}/>
          <h1 className="text-center text-gray-700 text-lg font-bold mx-4">
            {t("vesselsList")}
          </h1>
          <hr className={"flex-1 border-gray-300"}/>
        </div>
        <div className="flex justify-end mr-5 mb-3">
          <SimpleSearch data={searchFilters} onChange={setSearchFilters} clearAdvancedSearch={setAdvancedSearchFilters}
                        onSubmit={() => setUpdatedTable((prev) => prev + 1)}/>
          <div className="mr-2"/>
          <AdvancedSearch data={advancedSearchFilters} onChange={setAdvancedSearchFilters}
                          clearSimpleSearch={setSearchFilters}
                          onSubmit={() => setUpdatedTable((prev) => prev + 1)}/>
        </div>
        <div className="flex justify-end mr-5">
          <ExportPDF/>
          <div className="mr-2"/>
          <Exports simpleSearch={searchFilters} advancedSearch={advancedSearchFilters}/>
        </div>
        <div className="block lg:hidden">
          <MobileTable
            headers={headers}
            data={data}
            page={metaData?.currentPage}
            totalRows={metaData?.total}
            pageCount={metaData?.lastPage}
          />
        </div>
        <div className="hidden lg:block">
          <Datatable
            title={
              <>
                {!censusOverviewData?.expired_at && (
                  <AddButton
                    label={t("addVessel")}
                    onClick={() => {
                      history.push({
                        pathname: `/census/${id}/create-vessel`,
                      });
                    }}
                  />
                )}
              </>
            }
            data={data}
            initialPageSize={500}
            headers={headers}
            totalRows={metaData?.total}
            paginationRowsPerPageOptions={[100, 200, 500]}
            paginationComponentOptions={paginationComponentOptions}
            getData={getData}
            isLoading={loading}
            extraDependencies={[updatedTable]}
          />
        </div>
      </div>
    </div>
  );
};

export default VesselsIndex;