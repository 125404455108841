import React from "react";
import EditCensus from "@components/Census/EditCensus";

import ShowCensus from "@components/Census/ShowCensus";

const CensusActions = ({ census, onEdited }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowCensus census={census} />
      </div>
      <div className={"mr-2"}>
        <EditCensus onEdited={onEdited} census={census} />
      </div>
    </div>
  );
};

export default CensusActions;
