import React from "react";
import Layout from "@hoc/layouts/Layout";
import CapacityUnitsIndex from "@components/CapacityUnit/CapacityUnitsIndex";

const CapacityUnitsPage = () => {
  return (
    <Layout>
      <CapacityUnitsIndex />
    </Layout>
  );
};

export default CapacityUnitsPage;
