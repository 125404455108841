import React from "react";
import Layout from "@hoc/layouts/Layout";
import HullMaterialsEUIndex from "@components/HullMaterialEU/HullMaterialsEUIndex";

const HullMaterialsEUPage = () => {
  return (
    <Layout>
      <HullMaterialsEUIndex />
    </Layout>
  );
};

export default HullMaterialsEUPage;
