import React from "react";
import Layout from "@hoc/layouts/Layout";
import InactivityReasonsIndex from "@components/InactivityReason/InactivityReasonsIndex";

const InactivityReasonsPage = () => {
  return (
    <Layout>
      <InactivityReasonsIndex />
    </Layout>
  );
};

export default InactivityReasonsPage;
