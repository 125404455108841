import React from "react";
import Layout from "@hoc/layouts/Layout";
import EngineLocationsIndex from "@components/EngineLocation/EngineLocationsIndex";

const EngineLocationsPage = () => {
  return (
    <Layout>
      <EngineLocationsIndex />
    </Layout>
  );
};

export default EngineLocationsPage;
