import React, { useEffect, useState } from "react";
import EditModal from "@core/modals/EditModal";
import EditButton from "@core/buttons/atoms/EditButton";
import Input from "@core/inputs/Input";
import { showError, showSuccess } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import GearClassService from "@services/GearClassService";

const EditGearClass = ({ gearClass, onEdited }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setName("");
    setNameEn("");
    setDescription("");
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    GearClassService.update(gearClass.id, name, nameEn, description)
      .then(() => {
        showSuccess(t("gearClassEditedSuccessfully"));
        onEdited();
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (showModal)
      GearClassService.find(gearClass.id).then((response) => {
        setName(response.data.data.name);
        setNameEn(response.data.data.name_eng);
        setDescription(response.data.data.description);
      });
  }, [gearClass.id, showModal]);

  return (
    <PermissionHandler permission={"update_gear_class"}>
      <EditButton onClick={() => setShowModal(true)} />
      <EditModal
        show={showModal}
        title={t("editGearClass")}
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}
        label={t("edit")}
      >
        <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
          <Input
            label={t("name")}
            placeholder={t("name")}
            value={name}
            onChange={setName}
            height="h-10"
          />
          <Input
            height="h-10"
            label={t("nameEng")}
            placeholder={t("nameEng")}
            value={nameEn}
            onChange={setNameEn}
          />
          <Input
            height="h-10"
            label={t("description")}
            placeholder={t("description")}
            value={description}
            onChange={setDescription}
          />
        </div>
      </EditModal>
    </PermissionHandler>
  );
};

export default EditGearClass;
