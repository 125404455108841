import React, { useEffect, useState } from "react";
import ViewModal from "@core/modals/ViewModal";
import ViewButton from "@core/buttons/atoms/ViewButton";
import RowDetails from "@hoc/cruds/RowDetails";
import { useTranslation } from "react-i18next";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import GroupOfSpeciesGFCMService from "@services/GroupOfSpeciesGFCMService";

const ShowGroupOfSpeciesGFCM = ({ groupOfSpeciesGFCMA }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [groupOfSpeciesGFCMAData, setGroupOfSpeciesGFCMAData] = useState([]);

  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (showModal)
      GroupOfSpeciesGFCMService.find(groupOfSpeciesGFCMA.id).then(
        (response) => {
          setGroupOfSpeciesGFCMAData(response.data.data);
        }
      );
  }, [groupOfSpeciesGFCMA.id, showModal]);

  return (
    <PermissionHandler permission={"view_group_of_species_gfcm"}>
      <ViewButton onClick={() => setShowModal(true)} />
      <ViewModal
        show={showModal}
        title={`${t("overview")}`}
        onClose={closeModal}
      >
        <div className="flex flex-col mt-5">
          <RowDetails label={t("name")} value={groupOfSpeciesGFCMAData.name} />
          <RowDetails
            label={t("nameEng")}
            value={groupOfSpeciesGFCMAData.name_eng}
          />
          <RowDetails
            label={t("description")}
            value={groupOfSpeciesGFCMAData.description}
          />
        </div>
      </ViewModal>
    </PermissionHandler>
  );
};

export default ShowGroupOfSpeciesGFCM;
