import React from "react";
import Layout from "@hoc/layouts/Layout";
import PortTypesIndex from "@components/PortType/PortTypesIndex";

const PortTypesPage = () => {
  return (
    <Layout>
      <PortTypesIndex />
    </Layout>
  );
};

export default PortTypesPage;
