import React, { useEffect, useState } from "react";
import EditModal from "@core/modals/EditModal";
import EditButton from "@core/buttons/atoms/EditButton";
import Input from "@core/inputs/Input";
import { showError, showSuccess } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import EquipmentService from "@services/EquipmentService";
import EquipmentCategorySelect from "@components/EquipmentCategory/partials/EquipmentCategorySelect";

const EditEquipment = ({ equipment, onEdited }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [description, setDescription] = useState("");
  const [selectedEquipmentCategory, setSelectedEquipmentCategory] = useState();
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setName("");
    setNameEn("");
    setDescription("");
    setSelectedEquipmentCategory("");
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    EquipmentService.update(
      equipment.id,
      name,
      nameEn,
      description,
      selectedEquipmentCategory
    )
      .then(() => {
        showSuccess(t("equipmentEditedSuccessfully"));
        onEdited();
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (showModal)
      EquipmentService.find(equipment.id).then((response) => {
        setName(response.data.data.name);
        setNameEn(response.data.data.name_eng);
        setDescription(response.data.data.description);
        setSelectedEquipmentCategory(response.data.data.category?.id);
      });
  }, [equipment.id, showModal]);

  return (
    <PermissionHandler permission={"update_equipment"}>
      <EditButton onClick={() => setShowModal(true)} />
      <EditModal
        show={showModal}
        title={t("editEquipment")}
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}
        label={t("edit")}
      >
        <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
          <Input
            label={t("name")}
            placeholder={t("name")}
            value={name}
            onChange={setName}
            height="h-10"
          />
          <Input
            height="h-10"
            label={t("nameEng")}
            placeholder={t("nameEng")}
            value={nameEn}
            onChange={setNameEn}
          />
          <Input
            height="h-10"
            label={t("description")}
            placeholder={t("description")}
            value={description}
            onChange={setDescription}
          />
          <div>
            <div className="text-sm font-semibold mb-2 flex">
              {t("equipmentCategory")}
            </div>
            <EquipmentCategorySelect
              openModal={showModal}
              onSelect={setSelectedEquipmentCategory}
              selected={selectedEquipmentCategory}
            />
          </div>
        </div>
      </EditModal>
    </PermissionHandler>
  );
};

export default EditEquipment;
