import API from "@utils/plugins/API";

const OperationalStatusService = {
  all: (params) => {
    return API.get("operational-statuses", { params: params });
  },
  find: (id) => {
    return API.get(`operational-statuses/${id}`);
  },
};

export default OperationalStatusService;
