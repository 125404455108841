import React, { useState } from "react";
import AddButton from "@core/buttons/atoms/AddButton";
import AddModal from "@core/modals/AddModal";
import Input from "@core/inputs/Input";
import { showError, showSuccess } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import FishingZoneService from "@services/FishingZoneService";
import PermissionHandler from "@hoc/cruds/PermissionHandler";

const CreateFishingZone = ({ onAdded }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [englishName, setEnglishName] = useState("");
  const [description, setDescription] = useState("");

  const closeModal = () => {
    setShowModal(false);
    setName("");
    setDescription("");
    setEnglishName("");
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    FishingZoneService.create(name, englishName, description)
      .then(() => {
        showSuccess(t("fishingZoneCreatedSuccessfully"));
        onAdded();
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PermissionHandler permission={"create_fishing_zone"}>
      <AddButton
        label={t("addFishingZone")}
        onClick={() => setShowModal(true)}
      />
      <AddModal
        show={showModal}
        title={t("addFishingZone")}
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}
      >
        <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5 sm:gap-10">
          <Input
            height="h-10"
            label={t("name")}
            placeholder={t("name")}
            value={name}
            onChange={setName}
          />
          <Input
            height="h-10"
            label={t("nameEng")}
            placeholder={t("nameEng")}
            value={englishName}
            onChange={setEnglishName}
          />
          <Input
            height="h-10"
            label={t("description")}
            placeholder={t("description")}
            value={description}
            onChange={setDescription}
          />
        </div>
      </AddModal>
    </PermissionHandler>
  );
};

export default CreateFishingZone;
