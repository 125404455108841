import React from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { FaChevronRight, FaFolderOpen, FaFolder } from "react-icons/fa";

const Log = ({ name, time, census, handleClick }) => {
  const history = useHistory();
  return (
    <div
      className="border-b border-gray-100 cursor-pointer flex py-3 px-4 hover:bg-indigo-50 items-center"
      onClick={() => {
        handleClick(census);
        history.push(`/census/${census.id}`);
      }}
    >
      {census.expired_at ? (
        <FaFolder className={"text-gray-400 mr-3"} size={22} />
      ) : (
        <FaFolderOpen className={"text-green-600 mr-3"} size={22} />
      )}
      <div className={"flex-1 flex flex-col"}>
        <div className="font-bold">{name}</div>
        <div className="flex items-center">
          {time && (
            <small className={"text-gray-500 mr-4"}>
              {moment(time).format("DD MMM YYYY H:mm")}
            </small>
          )}
        </div>
      </div>
      <FaChevronRight className={"text-gray-400"} />
    </div>
  );
};

export default Log;
