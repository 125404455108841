import React, { useEffect, useState } from "react";
import ViewModal from "@core/modals/ViewModal";
import ViewButton from "@core/buttons/atoms/ViewButton";
import RowDetails from "@hoc/cruds/RowDetails";
import { useTranslation } from "react-i18next";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import CapacityUnitService from "@services/CapacityUnitService";

const ShowCapacityUnit = ({ capacityUnit }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [capacityUnitData, setCapacityUnitData] = useState("");
  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (showModal)
      CapacityUnitService.find(capacityUnit.id).then((response) => {
        setCapacityUnitData(response.data.data);
      });
  }, [capacityUnit.id, showModal]);

  return (
    <PermissionHandler permission={"view_capacity_units"}>
      <ViewButton onClick={() => setShowModal(true)} />
      <ViewModal
        show={showModal}
        title={`${t("overview")}`}
        onClose={closeModal}
      >
        <div className="flex flex-col mt-5">
          <RowDetails label={t("name")} value={capacityUnitData.name} />
          <RowDetails label={t("nameEng")} value={capacityUnitData.name_eng} />
          <RowDetails
            label={t("description")}
            value={capacityUnitData.description}
          />
        </div>
      </ViewModal>
    </PermissionHandler>
  );
};

export default ShowCapacityUnit;
