import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import ShowGeoSubArea from "@components/GeoSubArea/ShowGeoSubArea";
import GeoSubAreaService from "@services/GeoSubAreaService";
import EditGeoSubArea from "@components/GeoSubArea/EditGeoSubArea";

const GeoSubAreaActions = ({ geoSubArea, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowGeoSubArea geoSubArea={geoSubArea} />
      </div>
      <div className={"mr-2"}>
        <EditGeoSubArea geoSubArea={geoSubArea} onEdited={onEdited} />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={GeoSubAreaService}
          id={geoSubArea.id}
          onDeleted={onDeleted}
          permission={"delete_geo_sub_area"}
        />
      </div>
    </div>
  );
};

export default GeoSubAreaActions;
