import React from "react";
import EditSubArea from "@components/SubArea/EditSubArea";
import ShowSubArea from "@components/SubArea/ShowSubArea";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import SubAreaService from "@services/SubAreaService";

const SubAreaActions = ({ subArea, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowSubArea subArea={subArea} />
      </div>
      <div className={"mr-2"}>
        <EditSubArea subArea={subArea} onEdited={onEdited} />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={SubAreaService}
          id={subArea.id}
          onDeleted={onDeleted}
          permission={"delete_sub_area"}
        />
      </div>
    </div>
  );
};

export default SubAreaActions;
