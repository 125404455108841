import React, { useEffect, useState } from "react";
import ViewModal from "@core/modals/ViewModal";
import ViewButton from "@core/buttons/atoms/ViewButton";
import RowDetails from "@hoc/cruds/RowDetails";
import { useTranslation } from "react-i18next";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import CountryService from "@services/CountryService";
import GreenBadge from "@core/badges/GreenBadge";
import RedBadge from "@core/badges/RedBadge";

const ShowCountry = ({ country }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [countryData, setCountryData] = useState([]);

  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (showModal)
      CountryService.find(country.id).then((response) => {
        setCountryData(response.data.data);
      });
  }, [country.id, showModal]);

  return (
    <PermissionHandler permission={"view_countries"}>
      <ViewButton onClick={() => setShowModal(true)} />
      <ViewModal
        show={showModal}
        title={`${t("overview")}`}
        onClose={closeModal}
      >
        <div className="flex flex-col mt-5">
          <RowDetails label={t("name")} value={countryData.name} />
          <RowDetails label={t("nameEng")} value={countryData.name_eng} />
          <RowDetails label={t("nameFrn")} value={countryData.name_frn} />
          <RowDetails label={t("nameEsp")} value={countryData.name_esp} />
          <RowDetails label={t("unitedNation")} value={countryData.un} />
          <RowDetails label={t("unitedNationDP")} value={countryData.undp} />
          <RowDetails label={t("iso2")} value={countryData.iso_2} />
          <RowDetails label={t("iso3")} value={countryData.iso_3} />
          <RowDetails label={t("gfcm")} value={countryData.gfcm} />
          <RowDetails
            label={t("europeanUnion")}
            value={
              countryData.eu ? (
                <GreenBadge text={"True"} />
              ) : (
                <RedBadge text={"False"} />
              )
            }
          />
          <RowDetails
            label={t("medFisis")}
            value={
              countryData.med_fisis ? (
                <GreenBadge text={"True"} />
              ) : (
                <RedBadge text={"False"} />
              )
            }
          />
          <RowDetails
            label={t("description")}
            value={countryData.description}
          />
        </div>
      </ViewModal>
    </PermissionHandler>
  );
};

export default ShowCountry;
