import React from "react";
import Layout from "@hoc/layouts/Layout";
import CompanyTypesIndex from "@components/CompanyType/CompanyTypesIndex";

const CompanyTypesPage = () => {
  return (
    <Layout>
      <CompanyTypesIndex />
    </Layout>
  );
};

export default CompanyTypesPage;
