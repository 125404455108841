import React from "react";
import Layout from "@hoc/layouts/Layout";
import RolesIndex from "@components/Role/RolesIndex";

const RolesPage = () => {
  return (
    <Layout>
      <RolesIndex />
    </Layout>
  );
};

export default RolesPage;
