import React, {useState, useEffect} from "react";
import Select from "react-select";
import {addSelectData} from "@redux/select/Action";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import i18n from "i18next";
import EquipmentTypeService from "@services/EquipmentTypeService";

const EquipmentTypeSelect = ({
                               reduxKey,
                               onSelect,
                               selected,
                               equipmentCategoryId,
                               disabled = false,
                               isClearable = false
                             }) => {
  const language = i18n.language;
  const dispatch = useDispatch();
  const [selectedObject, setSelectedObject] = useState();

  // eslint-disable-next-line no-undef
  const options = useSelector((state) =>
    _.get(state, `selectDataReducer.${reduxKey}`, [])
  );

  const handleChangeSelected = (item, triggeredAction) => {
    if (triggeredAction.action === 'clear'){
      setSelectedObject();
      onSelect();
    }
    setSelectedObject(item);
    onSelect && onSelect(item.id);
  };

  useEffect(() => {
    EquipmentTypeService.all({equipment_category_id: equipmentCategoryId, "no-pagination": true,}).then((response) => {
      dispatch(addSelectData({[reduxKey]: response.data?.data}));
    });
  }, [dispatch, equipmentCategoryId, reduxKey]);

  useEffect(() => {
    const foundItem = Object.values(options)?.find(
      (item) => item.id === selected
    );
    setSelectedObject(foundItem ? foundItem : null);
  }, [selected, options]);

  return (
    <Select
      isClearable={isClearable}
      isDisabled={disabled}
      getOptionValue={(option) => option.name}
      menuPortalTarget={document.body}
      styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
      isSearchable={true}
      options={options}
      onChange={handleChangeSelected}
      value={selectedObject}
      getOptionLabel={(equipmentType) => (
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <span style={{marginLeft: 5}}>
            {language === "al" ? equipmentType.name : equipmentType.name_eng}
          </span>
        </div>
      )}
    />
  );
};

export default EquipmentTypeSelect;

