import React, {useEffect, useState} from "react";
import LogoHeader from "@hoc/partials/LogoHeader";
import TabSwitch from "@core/tabs/TabSwitch";
import {useTranslation} from "react-i18next";
import {Link, useHistory, useParams} from "react-router-dom";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import VesselCharacteristics from "@components/Vessel/tabs/VesselCharacteristics";
import VesselService from "@services/VesselService";
import {FaArrowLeft} from "react-icons/fa";
import VesselAuthorization from "@components/Vessel/tabs/VesselAuthorization";
import VesselStructuralCharacteristics from "@components/Vessel/tabs/VesselStructuralCharacteristics";
import VesselElectronics from "@components/Vessel/tabs/VesselElectronics";
import VesselDeckMachinery from "@components/Vessel/tabs/VesselDeckMachinery";
import VesselCrew from "@components/Vessel/tabs/VesselCrew";
import VesselPollutionPrevention from "@components/Vessel/tabs/VesselPollutionPrevention";
import VesselEngines from "@components/Vessel/tabs/VesselEngines";
import VesselEquipments from "@components/Vessel/tabs/VesselEquipments";
import VesselPorts from "@components/Vessel/tabs/VesselPorts";
import VesselOperations from "@components/Vessel/tabs/VesselOperations";
import VesselRemarks from "@components/Vessel/tabs/VesselRemarks";
import SubmitButton from "@core/buttons/atoms/SubmitButton";
import {showError, showSuccess} from "@utils/helpers";
import VesselOwnerships from "@components/Vessel/tabs/VesselOwnerships";

const CreateVesselPage = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const {id} = useParams();

  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState("characteristics");
  const [characteristics, setCharacteristics] = useState({});
  const [structuralCharacteristics, setStructuralCharacteristics] = useState(
    {}
  );
  const [authorisation, setAuthorisation] = useState({});
  const [engines, setEngines] = useState([]);
  const [electronic, setElectronic] = useState({});
  const [equipments, setEquipments] = useState([]);
  const [deckMachinery, setDeckMachinery] = useState({});
  const [ownerships, setOwnerships] = useState([]);
  const [crew, setCrew] = useState({});
  const [ports, setPorts] = useState([]);
  const [operations, setOperations] = useState([]);
  const [pollutionPrevention, setPollutionPrevention] = useState({});
  const [remarks, setRemarks] = useState("");
  const [gears, setGears] = useState([]);
  const [winches, setWinches] = useState([]);

  const onSubmit = () => {
    const payload = {
      census_id: id,
      characteristics,
      structural_characteristics: structuralCharacteristics,
      authorisation,
      engines: engines.map((engine, index) => {
        return {rank: index + 1, ...engine}
      }),
      electronic,
      equipments,
      deck_machinery: deckMachinery,
      ownerships,
      crew,
      ports,
      operations,
      pollution_prevention: pollutionPrevention,
      remarks,
      gears: gears.map((gear, index) => {
        return {rank: index + 1, ...gear}
      }),
      winches,
    };
    setLoading(true);
    VesselService.create(payload)
      .then(() => {
        showSuccess(t("vesselCreatedSuccessfully"));
        history.push(`/census/${id}`);
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!id) {
      history.push("/");
    }
  }, [history, id]);

  return (
    <PermissionHandler permission={"create_vessel"}>
      <div className={"min-h-screen flex flex-row bg-gray-100 pb-30"}>
        <div className={"flex-1"}>
          <LogoHeader/>
          <div className="mt-10 px-3">
            <TabSwitch
              containerClassName="mt-10"
              items={[
                "characteristics",
                "structuralCharacteristics",
                "authorization",
                "engines",
                "electronics",
                "equipment",
                "deckMachinery",
                "ownership",
                "crew",
                "ports",
                "operations",
                "pollutionPrevention",
                "remarks",
              ]}
              activeTab={active}
              onChange={setActive}
            />

            <div className="bg-white rounded-b-lg justify-between items-center pt-2 pb-10 px-22 shadow">
              {active === "characteristics" && (
                <VesselCharacteristics
                  data={characteristics}
                  onChange={setCharacteristics}
                />
              )}
              {active === "structuralCharacteristics" && (
                <VesselStructuralCharacteristics
                  data={structuralCharacteristics}
                  onChange={setStructuralCharacteristics}
                />
              )}
              {active === "authorization" && (
                <VesselAuthorization
                  data={authorisation}
                  onChange={setAuthorisation}
                  gears={gears}
                  onGearsChange={setGears}
                />
              )}
              {active === "engines" && (
                <VesselEngines data={engines} onChange={setEngines}/>
              )}
              {active === "electronics" && (
                <VesselElectronics data={electronic} onChange={setElectronic}/>
              )}
              {active === "equipment" && (
                <VesselEquipments data={equipments} onChange={setEquipments}/>
              )}
              {active === "deckMachinery" && (
                <VesselDeckMachinery
                  data={deckMachinery}
                  onChange={setDeckMachinery}
                  winches={winches}
                  onWinchesChange={setWinches}
                />
              )}
              {active === "ownership" && (
                <VesselOwnerships
                  data={ownerships}
                  onChange={(val) => {
                    setOwnerships((prev) => [...prev, val]);
                  }}
                  onDelete={setOwnerships}
                />
              )}
              {active === "crew" && (
                <VesselCrew data={crew} onChange={setCrew}/>
              )}
              {active === "ports" && (
                <VesselPorts data={ports} onChange={setPorts}/>
              )}
              {active === "operations" && (
                <VesselOperations data={operations} onChange={setOperations}/>
              )}
              {active === "pollutionPrevention" && (
                <VesselPollutionPrevention
                  data={pollutionPrevention}
                  onChange={setPollutionPrevention}
                />
              )}
              {active === "remarks" && (
                <VesselRemarks data={remarks} onChange={setRemarks}/>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="sticky w-full shadow border-t bottom-0 bg-white flex items-center justify-end py-3 px-3">
        <Link to={`/census/${id}`}>
          <div className="flex cursor-pointer mr-5">
            <FaArrowLeft className="mt-1.5 mr-2"/>
            {t("goBack")}
          </div>
        </Link>
        <div className="flex w-1/6">
          <SubmitButton
            label={t("submit")}
            onClick={onSubmit}
            loading={loading}
          />
        </div>
      </div>
    </PermissionHandler>
  );
};

export default CreateVesselPage;
