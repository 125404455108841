import React from "react";
import Input from "@core/inputs/Input";
import { Trash } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
import NumericalInput from "@core/inputs/NumericalInput";
import ApiSelect from "@core/dropdowns/ApiSelect";
import GearService from "@services/GearService";
import MonthService from "@services/MonthService";
import GroupOfSpeciesService from "@services/GroupOfSpeciesService";
import FishingZoneSelect from "@components/FishingZone/partials/FishingZoneSelect";

const Operation = ({ data, index, onChange, disabled, onDelete, required, isClearable }) => {
  const { t } = useTranslation();

  const handleChange = (value, name) => {
    onChange(index, { ...data, [name]: value });
  };

  return (
    <div key={index}>
      <div className="grid grid-cols-4 gap-5">
        <div>
          <div className="text-xs font-semibold flex mb-2">
            {t("gearType")}
            {required && <div className="text-red-600 ml-1">*</div>}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={`gearType${index}`}
            disabled={disabled}
            service={GearService}
            onSelect={(val) => handleChange(val, "gear_type_id")}
            selected={data?.gear_type_id}
            extraParams={{
              "no-pagination": true,
            }}
          />
        </div>
        <div>
          <div className="text-xs font-semibold mb-2 flex">
            {t("beginMonth")}
            {required && <div className="text-red-600 ml-1">*</div>}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={"month"}
            disabled={disabled}
            service={MonthService}
            onSelect={(val) => handleChange(val, "begin_month_id")}
            selected={data?.begin_month_id}
          />
        </div>
        <div>
          <div className="text-xs font-semibold mb-2 flex">
            {t("endMonth")}
            {required && <div className="text-red-600 ml-1">*</div>}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={"month"}
            disabled={disabled}
            service={MonthService}
            onSelect={(val) => handleChange(val, "end_month_id")}
            selected={data?.end_month_id}
          />
        </div>
        <NumericalInput
          disabled={disabled}
          height="h-10"
          label={t("tripsPerMonth")}
          placeholder={t("tripsPerMonth")}
          value={data?.trips_per_month === -1 ? "" : data?.trips_per_month}
          onChange={(val) => handleChange(val, "trips_per_month")}
          extraClasses={"text-xs"}
        />
        <Input
          disabled={disabled}
          height="h-10"
          label={t("remarks")}
          placeholder={t("remarks")}
          value={data?.remarks}
          onChange={(val) => handleChange(val, "remarks")}
          extraClasses={"text-xs"}
        />
        <div>
          <div className="text-xs font-semibold mb-2 flex">
            {t("fishingZone")}
            {required && <div className="text-red-600 ml-1">*</div>}
          </div>
          <FishingZoneSelect
            isClearable={isClearable}
            disabled={disabled}
            onSelect={(val) => handleChange(val, "fishing_zone_id")}
            selected={data?.fishing_zone_id}
          />
        </div>
        <div>
          <div className="text-xs font-semibold flex mb-2">
            {t("targetGroupOfSpecies")}
            {required && <div className="text-red-600 ml-1">*</div>}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={"targetGroupOfSpecies"}
            disabled={disabled}
            service={GroupOfSpeciesService}
            onSelect={(val) => handleChange(val, "target_group_of_species_id")}
            selected={data?.target_group_of_species_id}
          />
        </div>
        {!disabled && (
          <div className="flex self-center pt-5">
            <button
              className="flex text-red-600 cursor-pointer ml-auto"
              onClick={() => onDelete(index)}
            >
              <Trash />
            </button>
          </div>
        )}
      </div>
      <hr className="px-5 my-3" />
    </div>
  );
};

export default Operation;
