import React, { useMemo, useState } from "react";
import Datatable from "@core/table/Datatable";
import MobileTable from "@core/table/MobileTable";
import { useTranslation } from "react-i18next";
import CountryActions from "@components/Country/partials/CountryActions";
import CountryService from "@services/CountryService";
import CreateCountry from "@components/Country/CreateCountry";
import GreenBadge from "@core/badges/GreenBadge";
import RedBadge from "@core/badges/RedBadge";

const CountriesIndex = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [metaData, setMetaData] = useState();
  const [loading, setLoading] = useState(true);
  const [updatedTable, setUpdatedTable] = useState(0);

  const headers = useMemo(
    () => [
      {
        id: "name",
        name: t("name"),
        cell: (row) => row.name,
      },
      {
        id: "nameEng",
        name: t("nameEng"),
        cell: (row) => row.name_eng,
      },
      {
        id: "unitedNationDP",
        name: t("unitedNationDp"),
        cell: (row) => row.undp,
        maxWidth: "20px",
      },
      {
        id: "unitedNation",
        name: t("unitedNation"),
        cell: (row) => row.un,
        maxWidth: "20px",
      },
      {
        id: "iso_2",
        name: t("iso2"),
        cell: (row) => row.iso_2,
        maxWidth: "20px",
      },
      {
        id: "iso_3",
        name: t("iso3"),
        cell: (row) => row.iso_3,
        maxWidth: "20px",
      },
      {
        id: "europeanUnion",
        name: t("europeanUnion"),
        cell: (row) =>
          row.eu ? <GreenBadge text={"True"} /> : <RedBadge text={"False"} />,
        maxWidth: "20px",
      },
      {
        id: "gfcm",
        name: t("gfcm"),
        cell: (row) =>
          row.gfcm ? <GreenBadge text={"True"} /> : <RedBadge text={"False"} />,
        maxWidth: "20px",
      },
      {
        id: "actions",
        name: t("actions"),
        cell: (row) => (
          <CountryActions
            country={row}
            onEdited={() => setUpdatedTable((prev) => prev + 1)}
            onDeleted={() => setUpdatedTable((prev) => prev + 1)}
          />
        ),
      },
    ],
    [t]
  );

  const getData = (params) => {
    setLoading(true);
    CountryService.all(params).then((response) => {
      setData(response.data.data);
      setMetaData(response.data.metadata);
      setLoading(false);
    });
  };

  return (
    <div className="border bg-white rounded justify-between items-center mb-5 py-2">
      <div className="mx-3 my-5">
        <label className="text-lg font-bold">{t("countriesList")}</label>
        <div className="flex w-1/2 sm:w-1/4 md:w-1/6 mt-3 mr-2">
          <CreateCountry
            onAdded={() => setUpdatedTable((prev) => prev + 1)}
            label={t("addCountry")}
          />
        </div>
        <div className="block lg:hidden">
          <MobileTable
            headers={headers}
            data={data}
            page={metaData?.currentPage}
            totalRows={metaData?.total}
            pageCount={metaData?.lastPage}
          />
        </div>
        <div className="hidden lg:block">
          <Datatable
            data={data}
            headers={headers}
            totalRows={metaData?.total}
            getData={getData}
            isLoading={loading}
            extraDependencies={[updatedTable]}
          />
        </div>
      </div>
    </div>
  );
};

export default CountriesIndex;
