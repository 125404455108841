import API from "@utils/plugins/API";

const EngineLocationService = {
  all: (params) => {
    return API.get("engine-locations", { params: params });
  },
  create: (name, name_eng, description) => {
    return API.post("engine-locations", {
      name,
      name_eng,
      description,
    });
  },
  update: (id, name, name_eng, description) => {
    return API.put(`engine-locations/${id}`, {
      name,
      name_eng,
      description,
    });
  },
  find: (id) => {
    return API.get(`engine-locations/${id}`);
  },
  destroy: (id) => {
    return API.delete(`engine-locations/${id}`);
  },
};

export default EngineLocationService;
