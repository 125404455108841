import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import EquipmentCategoryService from "@services/EquipmentCategoryService";
import ShowEquipmentCategory from "@components/EquipmentCategory/ShowEquipmentCategory";
import EditEquipmentCategory from "@components/EquipmentCategory/EditEquipmentCategory";

const EquipmentCategoryActions = ({
  equipmentCategory,
  onEdited,
  onDeleted,
}) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowEquipmentCategory equipmentCategory={equipmentCategory} />
      </div>
      <div className={"mr-2"}>
        <EditEquipmentCategory
          equipmentCategory={equipmentCategory}
          onEdited={onEdited}
        />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={EquipmentCategoryService}
          id={equipmentCategory.id}
          onDeleted={onDeleted}
          permission={"delete_equipment_category"}
        />
      </div>
    </div>
  );
};

export default EquipmentCategoryActions;
