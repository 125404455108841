import API from "@utils/plugins/API";

const FishingTechniqueEUService = {
  all: (params) => {
    return API.get("fishing-techniques-eu", { params: params });
  },
  create: (name, name_eng, description) => {
    return API.post("fishing-techniques-eu", {
      name,
      name_eng,
      description,
    });
  },
  update: (id, name, name_eng, description) => {
    return API.put(`fishing-techniques-eu/${id}`, {
      name,
      name_eng,
      description,
    });
  },
  find: (id) => {
    return API.get(`fishing-techniques-eu/${id}`);
  },
  destroy: (id) => {
    return API.delete(`fishing-techniques-eu/${id}`);
  },
};

export default FishingTechniqueEUService;
