import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import ShowEnergySource from "@components/EnergySources/ShowEnergySource";
import EnergySourceService from "@services/EnergySourceService";
import EditEnergySource from "@components/EnergySources/EditEnergySource";

const EnergySourceActions = ({ energySource, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowEnergySource energySource={energySource} />
      </div>
      <div className={"mr-2"}>
        <EditEnergySource energySource={energySource} onEdited={onEdited} />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={EnergySourceService}
          id={energySource.id}
          onDeleted={onDeleted}
          permission={"delete_energy_source"}
        />
      </div>
    </div>
  );
};

export default EnergySourceActions;
