import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import FishingZoneService from "@services/FishingZoneService";
import EditFishingZone from "@components/FishingZone/EditFishingZone";
import ShowFishingZone from "@components/FishingZone/ShowFishingZone";

const FishingZoneActions = ({ fishingZone, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowFishingZone fishingZone={fishingZone} />
      </div>
      <div className={"mr-2"}>
        <EditFishingZone fishingZone={fishingZone} onEdited={onEdited} />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={FishingZoneService}
          id={fishingZone.id}
          onDeleted={onDeleted}
          permission={"delete_fishing_zone"}
        />
      </div>
    </div>
  );
};

export default FishingZoneActions;
