export const monthOptions = [
  { label: "january", value: "1" },
  { label: "february", value: "2" },
  { label: "march", value: "3" },
  { label: "april", value: "4" },
  { label: "may", value: "5" },
  { label: "june", value: "6" },
  { label: "july", value: "7" },
  { label: "august", value: "8" },
  { label: "september", value: "9" },
  { label: "october", value: "10" },
  { label: "november", value: "11" },
  { label: "december", value: "12" },
];
