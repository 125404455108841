import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import InactivityReasonService from "@services/InactivityReasonService";
import ShowInactivityReason from "@components/InactivityReason/ShowInactivityReason";
import EditInactivityReason from "@components/InactivityReason/EditInactivityReason";

const InactivityReasonActions = ({ inactivityReason, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowInactivityReason inactivityReason={inactivityReason} />
      </div>
      <div className={"mr-2"}>
        <EditInactivityReason
          inactivityReason={inactivityReason}
          onEdited={onEdited}
        />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={InactivityReasonService}
          id={inactivityReason.id}
          onDeleted={onDeleted}
          permission={"delete_inactivity_reason"}
        />
      </div>
    </div>
  );
};

export default InactivityReasonActions;
