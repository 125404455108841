import React from "react";
import { useTranslation } from "react-i18next";
import Input from "@core/inputs/Input";

const VesselRemarks = ({ disabled = false, data, onChange }) => {
  const { t } = useTranslation();

  return (
    <div className="mt-4 sm:mt-8 mb-2">
      <Input
        disabled={disabled}
        height="h-10"
        label={t("remarks")}
        placeholder={t("remarks")}
        value={data}
        onChange={onChange}
      />
    </div>
  );
};

export default VesselRemarks;
