import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import DefaultButton from "@core/buttons/electrons/DefaultButton";
import BlankModal from "@core/modals/BlankModal";
import SubmitButton from "@core/buttons/atoms/SubmitButton";
import {Minus} from "tabler-icons-react";
import TabSwitch from "@core/tabs/TabSwitch";
import VesselCharacteristics from "@components/Vessel/tabs/VesselCharacteristics";
import VesselStructuralCharacteristics from "@components/Vessel/tabs/VesselStructuralCharacteristics";
import VesselAuthorization from "@components/Vessel/tabs/VesselAuthorization";
import VesselEngines from "@components/Vessel/tabs/VesselEngines";
import VesselElectronics from "@components/Vessel/tabs/VesselElectronics";
import VesselEquipments from "@components/Vessel/tabs/VesselEquipments";
import VesselDeckMachinery from "@components/Vessel/tabs/VesselDeckMachinery";
import VesselOwnerships from "@components/Vessel/tabs/VesselOwnerships";
import VesselCrew from "@components/Vessel/tabs/VesselCrew";
import VesselPorts from "@components/Vessel/tabs/VesselPorts";
import VesselOperations from "@components/Vessel/tabs/VesselOperations";
import VesselPollutionPrevention from "@components/Vessel/tabs/VesselPollutionPrevention";
import VesselRemarks from "@components/Vessel/tabs/VesselRemarks";
import {FaSearchPlus} from "react-icons/fa";

const AdvancedSearch = ({
                          data,
                          onChange,
                          onSubmit,
                          clearSimpleSearch
                        }) => {
  const {t} = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [filters, setFilters] = useState(data);
  const [active, setActive] = useState("characteristics");
  const [characteristics, setCharacteristics] = useState({});
  const [structuralCharacteristics, setStructuralCharacteristics] = useState({});
  const [authorisation, setAuthorisation] = useState({});
  const [engines, setEngines] = useState([]);
  const [electronic, setElectronic] = useState({});
  const [equipments, setEquipments] = useState([]);
  const [deckMachinery, setDeckMachinery] = useState({});
  const [ownerships, setOwnerships] = useState([]);
  const [crew, setCrew] = useState({});
  const [ports, setPorts] = useState([]);
  const [operations, setOperations] = useState([]);
  const [pollutionPrevention, setPollutionPrevention] = useState({});
  const [remarks, setRemarks] = useState("");
  const [gears, setGears] = useState([]);
  const [winches, setWinches] = useState([]);

  const closeModal = () => {
    setShowModal(false);
    setFilters({});
    setActive("characteristics")
  };

  const submit = () => {
    onSubmit();
    onChange({
      ...filters,
      characteristics: characteristics,
      structural_characteristics: structuralCharacteristics,
      authorisation: authorisation, gears,
      engines: engines,
      electronic: electronic,
      equipments: equipments,
      deck_machinery: deckMachinery, winches,
      ownership: ownerships,
      crew: crew,
      ports: ports,
      operations: operations,
      pollution_prevention: pollutionPrevention,
      remarks: remarks
    });
    setShowModal(false);
    clearSimpleSearch({})
    setActive("characteristics")
  };

  const clearState = () => {
    setFilters({});
    setCharacteristics({});
    setStructuralCharacteristics({});
    setAuthorisation({});
    setGears([]);
    setEngines([]);
    setElectronic({});
    setEquipments([]);
    setDeckMachinery({});
    setWinches([]);
    setOwnerships([]);
    setCrew({});
    setPorts([]);
    setOperations([]);
    setPollutionPrevention({})
    setRemarks('')
    onChange({});
    onSubmit();
  }

  return (
    <>
      <DefaultButton
        xs
        onClick={() => {
          setShowModal(true)
        }}
        bgColor={"bg-indigo-600"}
        bgColorHover={"hover:bg-indigo-700"}
        width={"w-34"}
        textColor={"text-white"}
        label={
          <div className={"flex items-center p-1"}>
            <FaSearchPlus className={"mr-1"}/>
            {t("advancedSearch")}
          </div>
        }/>
      <BlankModal
        width="sm:w-3/4"
        icon={<FaSearchPlus className={"text-indigo-700"}/>}
        iconBg="bg-indigo-100"
        show={showModal}
        title={t("advancedSearch")}
        onClose={closeModal}
        label={t("advancedSearch")}
        otherButtons={[
          <SubmitButton onClick={submit} label={t("search")}/>,
        ]}
      >
        <div className="flex justify-end mr-4">
          {<button onClick={clearState}>
            <div className="flex text-blue-600 mt-2">
              <Minus/>
              <div className="ml-2 font-semibold">{t("resetFilters")}</div>
            </div>
          </button>}
        </div>
        <div className="mt-10 px-3">
          <TabSwitch
            containerClassName="mt-10"
            items={[
              "characteristics",
              "structuralCharacteristics",
              "authorization",
              "engines",
              "electronics",
              "equipment",
              "deckMachinery",
              "ownership",
              "crew",
              "ports",
              "operations",
              "pollutionPrevention",
              "remarks",
            ]}
            activeTab={active}
            onChange={setActive}
          />

          <div className="bg-white rounded-b-lg justify-between items-center pt-2 pb-10 px-22 shadow">
            {active === "characteristics" && (
              <VesselCharacteristics
                isClearable
                required={false}
                data={characteristics}
                onChange={setCharacteristics}
              />
            )}
            {active === "structuralCharacteristics" && (
              <VesselStructuralCharacteristics
                isClearable
                required={false}
                data={structuralCharacteristics}
                onChange={setStructuralCharacteristics}
              />
            )}
            {active === "authorization" && (
              <VesselAuthorization
                isClearable
                required={false}
                data={authorisation}
                onChange={setAuthorisation}
                gears={gears}
                onGearsChange={setGears}
              />
            )}
            {active === "engines" && (
              <VesselEngines data={engines} onChange={setEngines} required={false} isClearable/>
            )}
            {active === "electronics" && (
              <VesselElectronics data={electronic} onChange={setElectronic} />
            )}
            {active === "equipment" && (
              <VesselEquipments data={equipments} onChange={setEquipments} required={false} isClearable/>
            )}
            {active === "deckMachinery" && (
              <VesselDeckMachinery
                isClearable
                required={false}
                data={deckMachinery}
                onChange={setDeckMachinery}
                winches={winches}
                onWinchesChange={setWinches}
              />
            )}
            {active === "ownership" && (
              <VesselOwnerships
                createOwner={false}
                required={false}
                data={ownerships}
                onChange={(val) => {
                  setOwnerships((prev) => [...prev, val]);
                }}
                onDelete={setOwnerships}
              />
            )}
            {active === "crew" && (
              <VesselCrew required={false} data={crew} onChange={setCrew}/>
            )}
            {active === "ports" && (
              <VesselPorts required={false} data={ports} onChange={setPorts} isClearable/>
            )}
            {active === "operations" && (
              <VesselOperations required={false} data={operations} onChange={setOperations}/>
            )}
            {active === "pollutionPrevention" && (
              <VesselPollutionPrevention
                isClearable
                required={false}
                data={pollutionPrevention}
                onChange={setPollutionPrevention}
              />
            )}
            {active === "remarks" && (
              <VesselRemarks data={remarks} onChange={setRemarks}/>
            )}
          </div>
        </div>
      </BlankModal>
    </>
  );
};

export default AdvancedSearch;