import React from "react";
import Layout from "@hoc/layouts/Layout";
import CaptainciesIndex from "@components/Captaincy/CaptainciesIndex";

const CaptainciesPage = () => {
  return (
    <Layout>
      <CaptainciesIndex />
    </Layout>
  );
};

export default CaptainciesPage;
