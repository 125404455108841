import React from "react";
import Layout from "@hoc/layouts/Layout";
import MinorStratasIndex from "@components/MinorStrata/MinorStratasIndex";

const MinorStratasPage = () => {
  return (
    <Layout>
      <MinorStratasIndex />
    </Layout>
  );
};

export default MinorStratasPage;
