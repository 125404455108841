import React, { useEffect, useState } from "react";
import EditModal from "@core/modals/EditModal";
import EditButton from "@core/buttons/atoms/EditButton";
import Input from "@core/inputs/Input";
import { showError, showSuccess } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import PortTypeService from "@services/PortTypeService";

const EditPortType = ({ portType, onEdited }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [nameEng, setNameEng] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setName("");
    setNameEng("");
    setDescription("");
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    PortTypeService.update(portType.id, name, nameEng, description)
      .then(() => {
        showSuccess(t("portTypeEditedSuccessfully"));
        onEdited();
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (showModal)
      PortTypeService.find(portType.id).then((response) => {
        setName(response.data.data.name);
        setNameEng(response.data.data.name_eng);
        setDescription(response.data.data.description);
      });
  }, [portType.id, showModal]);

  return (
    <PermissionHandler permission={"update_port_type"}>
      <EditButton onClick={() => setShowModal(true)} />
      <EditModal
        show={showModal}
        title={t("editPortType")}
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}
        label={t("edit")}
      >
        <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
          <Input
            label={t("name")}
            placeholder={t("name")}
            value={name}
            onChange={setName}
            height="h-10"
          />
          <Input
            height="h-10"
            label={t("nameEng")}
            placeholder={t("nameEng")}
            value={nameEng}
            onChange={setNameEng}
          />
          <Input
            height="h-10"
            label={t("description")}
            placeholder={t("description")}
            value={description}
            onChange={setDescription}
          />
        </div>
      </EditModal>
    </PermissionHandler>
  );
};

export default EditPortType;
