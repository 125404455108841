import React from "react";
import Flags from "@assets/images/flags";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const switchLanguage = (lang) => {
    i18n
      .changeLanguage(lang)
      .then(() => localStorage.setItem("FleetWebLang", lang));
  };

  return (
    <div className="max-h-8">
      <button
        className={`px-2 ${
          i18n.language === "al"
            ? "bg-gray-200 border-indigo-200"
            : "border-r-0"
        } rounded-md focus:outline-none`}
        onClick={() => switchLanguage("al")}
        id="albanian-language"
      >
        <img className="h-8" src={Flags.AL} alt="alb-flag" />
      </button>
      <button
        className={`px-2 ${
          i18n.language === "en"
            ? "bg-gray-200 border-indigo-200"
            : "border-l-0"
        } rounded-md focus:outline-none`}
        onClick={() => switchLanguage("en")}
        id="english-language"
      >
        <img className="h-8" src={Flags.GB} alt="en-flag" />
      </button>
    </div>
  );
};

export default LanguageSelector;
