import React, { useEffect, useState } from "react";
import ViewModal from "@core/modals/ViewModal";
import ViewButton from "@core/buttons/atoms/ViewButton";
import RowDetails from "@hoc/cruds/RowDetails";
import { useTranslation } from "react-i18next";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import GeoSubAreaService from "@services/GeoSubAreaService";

const ShowGeoSubArea = ({ geoSubArea }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [geoSubAreaData, setGeoSubAreaData] = useState([]);

  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (showModal)
      GeoSubAreaService.find(geoSubArea.id).then((response) => {
        setGeoSubAreaData(response.data.data);
      });
  }, [geoSubArea.id, showModal]);

  return (
    <PermissionHandler permission={"view_geo_sub_areas"}>
      <ViewButton onClick={() => setShowModal(true)} />
      <ViewModal
        show={showModal}
        title={`${t("overview")}`}
        onClose={closeModal}
      >
        <div className="flex flex-col mt-5">
          <RowDetails label={t("name")} value={geoSubAreaData.name} />
          <RowDetails label={t("nameEng")} value={geoSubAreaData.name_eng} />
          <RowDetails
            label={t("description")}
            value={geoSubAreaData.description}
          />
          <RowDetails
            label={t("statisticalDivisionName")}
            value={geoSubAreaData?.fao_statistical_division?.name}
          />
          <RowDetails
            label={t("statisticalDivisionNameEng")}
            value={geoSubAreaData?.fao_statistical_division?.name_eng}
          />
          <RowDetails
            label={t("statisticalDivisionDescription")}
            value={geoSubAreaData?.fao_statistical_division?.description}
          />
        </div>
      </ViewModal>
    </PermissionHandler>
  );
};

export default ShowGeoSubArea;
