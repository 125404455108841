import React, { useEffect, useState } from "react";
import ViewModal from "@core/modals/ViewModal";
import ViewButton from "@core/buttons/atoms/ViewButton";
import RowDetails from "@hoc/cruds/RowDetails";
import { useTranslation } from "react-i18next";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import GroupOfSpeciesService from "@services/GroupOfSpeciesService";

const ShowGroupOfSpecies = ({ groupOfSpecies }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [groupOfSpeciesData, setGroupOfSpeciesData] = useState([]);

  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (showModal)
      GroupOfSpeciesService.find(groupOfSpecies.id).then((response) => {
        setGroupOfSpeciesData(response.data.data);
      });
  }, [groupOfSpecies.id, showModal]);

  return (
    <PermissionHandler permission={"view_groups_of_species"}>
      <ViewButton onClick={() => setShowModal(true)} />
      <ViewModal
        show={showModal}
        title={`${t("overview")}`}
        onClose={closeModal}
      >
        <div className="flex flex-col mt-5">
          <RowDetails label={t("name")} value={groupOfSpeciesData.name} />
          <RowDetails
            label={t("nameEng")}
            value={groupOfSpeciesData.name_eng}
          />
          <RowDetails
            label={t("description")}
            value={groupOfSpeciesData.description}
          />
          <RowDetails
            label={t("groupOfSpeciesGFCMName")}
            value={groupOfSpeciesData.group_of_species_gfcm?.name}
          />
          <RowDetails
            label={t("groupOfSpeciesGFCMNameEng")}
            value={groupOfSpeciesData.group_of_species_gfcm?.name_eng}
          />
          <RowDetails
            label={t("groupOfSpeciesGFCMDescription")}
            value={groupOfSpeciesData.group_of_species_gfcm?.description}
          />
        </div>
      </ViewModal>
    </PermissionHandler>
  );
};

export default ShowGroupOfSpecies;
