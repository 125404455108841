import API from "@utils/plugins/API";

const ExportService = {
  reports: (id, export_type) => {
    return API.get(
      `exports/get-old-export-signed-url?census_id=${id}&export_type=${export_type}`
    );
  },
  advancedSearch: (id, export_type, advancedSearchFilters) => {
    return API.post(
      `exports/vessel-search-signed-url?census_id=${id}&export_type=${export_type}`, {"census-id": id, ...advancedSearchFilters}
    );
  },
  simpleSearch: (id, export_type, simple_search) => {
    return API.post(
      `exports/vessel-search-signed-url?census_id=${id}&export_type=${export_type}`, {"census-id": id, simple_search}
    );
  },
};

export default ExportService;