import React from "react";
import Input from "@core/inputs/Input";
import { Trash } from "tabler-icons-react";
import NumericalInput from "@core/inputs/NumericalInput";
import { useTranslation } from "react-i18next";
import ApiSelect from "@core/dropdowns/ApiSelect";
import WinchTypeService from "@services/WinchTypeService";
import WinchOperationService from "@services/WinchOperationService";

const Winch = ({ data, index, onDelete, onChange, disabled, isClearable }) => {
  const { t } = useTranslation();

  const handleChange = (value, name) => {
    onChange(index, { ...data, [name]: value });
  };

  return (
    <div key={index}>
      <div className="grid grid-cols-5 gap-5">
        <div>
          <div className="text-xs font-semibold mb-2">
            {t("winchType")}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={"winchType"}
            disabled={disabled}
            service={WinchTypeService}
            onSelect={(val) => handleChange(val, "winch_type_id")}
            selected={data?.winch_type_id}
          />
        </div>
        <NumericalInput
          disabled={disabled}
          height="h-10"
          label={t("quantity")}
          placeholder={t("quantity")}
          value={data?.quantity}
          onChange={(val) => handleChange(val, "quantity")}
          extraClasses={"text-xs"}
        />
        <div>
          <div className="text-xs font-semibold mb-2">
            {t("operatedBy")}
          </div>
          <ApiSelect
            isClearable={isClearable}
            reduxKey={"operatedBy"}
            disabled={disabled}
            service={WinchOperationService}
            onSelect={(val) => handleChange(val, "operated_by_id")}
            selected={data?.operated_by_id}
          />
        </div>
        <Input
          disabled={disabled}
          height="h-10"
          label={t("remarks")}
          placeholder={t("remarks")}
          value={data?.remarks}
          onChange={(val) => handleChange(val, "remarks")}
          extraClasses={"text-xs"}
        />
        {!disabled && (
          <div className="flex self-center pt-5">
            <button
              className="flex text-red-600 cursor-pointer ml-auto"
              onClick={() => onDelete(index)}
            >
              <Trash />
            </button>
          </div>
        )}
      </div>
      <hr className="px-5 my-3" />
    </div>
  );
};

export default Winch;
