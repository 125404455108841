import React from "react";
import DeleteEntity from "@hoc/cruds/DeleteEntity";
import EditCompanyType from "@components/CompanyType/EditCompanyType";
import ShowCompanyType from "@components/CompanyType/ShowCompanyType";
import CompanyTypeService from "@services/CompanyTypeService";

const CompanyTypeActions = ({ companyType, onEdited, onDeleted }) => {
  return (
    <div className={"flex justify-between items-center"}>
      <div className={"mr-2"}>
        <ShowCompanyType companyType={companyType} />
      </div>
      <div className={"mr-2"}>
        <EditCompanyType companyType={companyType} onEdited={onEdited} />
      </div>
      <div className={"mr-2"}>
        <DeleteEntity
          service={CompanyTypeService}
          id={companyType.id}
          onDeleted={onDeleted}
          permission={"delete_company_type"}
        />
      </div>
    </div>
  );
};

export default CompanyTypeActions;
