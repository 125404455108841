import React, { useEffect, useState } from "react";
import EditModal from "@core/modals/EditModal";
import EditButton from "@core/buttons/atoms/EditButton";
import Input from "@core/inputs/Input";
import { showError, showSuccess } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import PermissionHandler from "@hoc/cruds/PermissionHandler";
import VesselTypeService from "@services/VesselTypeService";
import VesselTypeFFFAOSelect from "@components/VesselTypeFFFAO/partials/VesselTypeFFFAOSelect";

const EditVesselType = ({ vesselType, onEdited }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [englishName, setEnglishName] = useState("");
  const [selectedVesselTypeFFFAO, setSelectedVesselTypeFFFAO] = useState("");
  const [stdAbbreviation, setStdAbbreviation] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setName("");
    setDescription("");
    setEnglishName("");
    setSelectedVesselTypeFFFAO("");
    setStdAbbreviation("");
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    VesselTypeService.update(
      vesselType.id,
      name,
      englishName,
      selectedVesselTypeFFFAO,
      stdAbbreviation,
      description
    )
      .then(() => {
        showSuccess(t("vesselTypeEditedSuccessfully"));
        onEdited();
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (showModal)
      VesselTypeService.find(vesselType.id).then((response) => {
        setName(response.data.data.name);
        setEnglishName(response.data.data.name_eng);
        setSelectedVesselTypeFFFAO(response.data.data.vessel_type_fffao_id);
        setStdAbbreviation(response.data.data.std_abbreviation);
        setDescription(response.data.data.description);
      });
  }, [vesselType.id, showModal]);

  return (
    <PermissionHandler permission={"update_vessel_type"}>
      <EditButton onClick={() => setShowModal(true)} />
      <EditModal
        show={showModal}
        title={t("editVesselType")}
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}
        label={t("edit")}
      >
        <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
          <Input
            height="h-10"
            label={t("name")}
            placeholder={t("name")}
            value={name}
            onChange={setName}
          />
          <Input
            height="h-10"
            label={t("nameEng")}
            placeholder={t("nameEng")}
            value={englishName}
            onChange={setEnglishName}
          />
          <div>
            <div className="text-xs font-semibold mb-2 flex">
              {t("vesselTypesFFFAO")}
            </div>
            <VesselTypeFFFAOSelect
              openModal={showModal}
              onSelect={setSelectedVesselTypeFFFAO}
              selected={selectedVesselTypeFFFAO}
            />
          </div>
          <Input
            height="h-10"
            label={t("stdAbbreviation")}
            placeholder={t("stdAbbreviation")}
            value={stdAbbreviation}
            onChange={setStdAbbreviation}
          />
          <Input
            height="h-10"
            label={t("description")}
            placeholder={t("description")}
            value={description}
            onChange={setDescription}
          />
        </div>
      </EditModal>
    </PermissionHandler>
  );
};

export default EditVesselType;
